import React from "react";
import Button from "../../components/Button";
import IconLoader from "../../components/IconLoader";
import LoadingPage from "../../components/LoadingPage";
import PageHeader from "../../components/PageHeader";
import TextWrapper from "../../components/TextWrapper";
import useGetBribesData from "../../hooks/state/Bribes/useGetBribesData";
import BribesTable from "./components/BribesTable";

const Bribes = () => {
  const bribesData = useGetBribesData();

  const NoResultsComponent = () => {
    return (
      <div className="noResultContainer">
        <div className="internal">
          <TextWrapper
            text={'No pools are live currently.'}
            fontSize={24}
            fontWeight={'bold'}
            className="m-b-8"
            align="center"
          />
          <div style={{width: '130px', margin: 'auto'}} className="m-t-24">
            <Button
              to={'/locker'}
              size={'sm'}
              tracking_id={'go_to_locker'}
              tracking_params={{position: 'bribes_page'}}
              variant={'transparent'}
            >
              <IconLoader iconName={'Add'} className="m-r-4" width={16}/>
              Open a lock
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={'custom-container'}>
      <PageHeader
        title={'Deposit a Pool Incentive'}
        subTitle={'Incentivize NFT holders to vote for your pool with a pool incentive.'}
        className={'m-b-40'}
        showLearnMore
        learnMoreLink="https://docs.mahadao.com/governance/pool-incentives/understanding-bribes"
      />
      {
        bribesData.isLoading
          ? <LoadingPage/>
          : !bribesData.isLoading && bribesData.value.length
            ? <div>
              <BribesTable bribesData={bribesData}/>
              <div className={'m-t-24'}>
                <TextWrapper
                  text={<div>
                    To register your own pool for gauges and pool incentives, create a governance proposal <a
                    href={'https://discuss.mahadao.com/c/governance/gauge-proposals/54'}
                    className={'links'}>here</a>
                  </div>}
                  align={'center'}
                />
              </div>
            </div>
            : <NoResultsComponent/>
      }

    </div>
  )
}

export default Bribes;
