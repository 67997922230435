import React, {useMemo, useState} from "react";

import InputContainer from "../../../components/InputContainer";
import States from "../../../components/States";
import Input from "../../../components/Input";
import CollateralDropDown from "../../../components/CollateralDropDown";
import {IGaugeStaticData} from "../../../utils/interface";
import useTokenBalance from "../../../hooks/useTokenBalance";
import useCore from "../../../hooks/useCore";
import {useGetActiveChainId} from "../../../state/chains/hooks";
import {formatToBN, getDisplayBalance} from "../../../utils/formatBalance";
import {Grid} from "@material-ui/core";
import Button from "../../../components/Button";
import useApprove, {ApprovalState} from "../../../hooks/callbacks/useApprove";
import useDeposit from "../../../hooks/callbacks/Staking/useDeposit";
import TextWrapper from "../../../components/TextWrapper";
import styled from "styled-components";
import theme from "../../../theme";

interface IProps {
  poolDetails: IGaugeStaticData;
}

const Deposit = (props: IProps) => {
  const {poolDetails} = props;
  const core = useCore();
  const chainId = useGetActiveChainId()

  const [amount, setAmount] = useState<string>('');
  const [depositing, setDepositing] = useState<boolean>(false);

  const erc20Token = core._tokens[chainId][poolDetails.depositToken];
  const balance = useTokenBalance(erc20Token);

  const gaugeContract = core._contracts[chainId][poolDetails.gauge];

  const [approveStatus, approve] = useApprove(
    erc20Token,
    gaugeContract.address,
    formatToBN(amount),
  );

  const depositAction = useDeposit(
    gaugeContract,
    formatToBN(amount, 18),
    erc20Token,
  );

  const handleDeposit = () => {
    setDepositing(true)
    depositAction(
      () => {
        setDepositing(false)
        window.location.reload();
      },
      () => {
      },
    ).then(r => {
    });
  };

  const isApproved = approveStatus === ApprovalState.APPROVED;
  const isApproving = approveStatus === ApprovalState.PENDING;

  const isAmountGreaterThanBalance = useMemo(() => {
    const bn = formatToBN(amount, erc20Token.decimal);
    return bn.gt(balance.value);
  }, [amount, erc20Token.decimal, balance.value]);

  return (
    <Card className={'m-b-24'}>
      <div className={'m-b-12'}>
        <TextWrapper
          text={'Deposit'}
          fontFamily={'Syne'}
          fontSize={18}
          fontWeight={600}
        />
        <TextWrapper
          text={'You can deposit LP tokens here and start earning MAHA rewards'}
          fontSize={14}
          Fcolor={theme.color.transparent[100]}
        />
      </div>
      <InputContainer
        label={'Enter amount to stake'}
        dataLabel={'Balance:'}
        dataValue={Number(getDisplayBalance(balance.value, erc20Token.decimal, 6))
          .toLocaleString('en-US', {minimumFractionDigits: 3})}
        dataValueLoading={balance.isLoading}
        className={'m-b-12'}
      >
        <States
          state={isAmountGreaterThanBalance ? 'error' : 'default'}
          msg={'Amount cannot be greater than your balance'}
        >
          <div className={'single-line-center-between'}>
            <Input
              value={amount}
              setValue={setAmount}
              maxTag={true}
              onMaxClick={() => {
                setAmount(Number(getDisplayBalance(balance.value, erc20Token.decimal, 3)).toString())
              }}
            />
            <CollateralDropDown selectedSymbol={poolDetails.depositToken}/>
          </div>
        </States>
      </InputContainer>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {!isApproved ? (
            <Button
              loading={isApproving}
              text={isApproving ? 'Approving' : 'Approve'}
              size={'lg'}
              onClick={approve}
              disabled={isApproved || !Number(amount)}
              tracking_id={'approve_stake_deposit'}
              tracking_params={{
                collateral: poolDetails.depositToken,
              }}
            />
          ) : (
            <Button
              disabled={!Number(amount) || depositing || isAmountGreaterThanBalance}
              text={'Deposit'}
              loading={depositing}
              size={'lg'}
              onClick={handleDeposit}
              tracking_id={'stake_deposit'}
              tracking_params={{
                action: 'confirm',
                collateral: poolDetails.depositToken,
                amount: Number(amount),
              }}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  )
}

export default Deposit;

const Card = styled.div`

`
