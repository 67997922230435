import {useCallback, useEffect, useState} from "react";
import {useWallet} from "use-wallet";
import useCore from "../../useCore";
import {BigNumber} from "ethers";
import {BasicBooleanState} from "../../../utils/interface";
import {
  LOADING_DEFAULT_BOOLEAN_STATE,
  NON_LOADING_DEFAULT_BOOLEAN_STATE,
} from "../../../utils/constants";
import {useGetActiveChainId} from "../../../state/chains/hooks";

const useGetPoolRangeStatus = (gauge: string, nftId: BigNumber) => {
  const [value, setValue] = useState<BasicBooleanState>(
    LOADING_DEFAULT_BOOLEAN_STATE
  );

  const core = useCore();
  const chainId = useGetActiveChainId();
  const {account} = useWallet();

  const fetchData = useCallback(async () => {
    if (!account) {
      setValue(NON_LOADING_DEFAULT_BOOLEAN_STATE);
      return;
    } else {
      setValue(LOADING_DEFAULT_BOOLEAN_STATE);
    }

    const gaugeContract = core._contracts[chainId][gauge];
    const value = await gaugeContract.isIdsWithinRange([nftId]);

    setValue({
      isLoading: false,
      value: value[0],
    });
  }, [account, core, chainId, gauge, nftId]);

  useEffect(() => {
    if (core) {
      fetchData().catch((err) => {
        setValue(NON_LOADING_DEFAULT_BOOLEAN_STATE);
        console.error(`Failed to fetch pool range status: ${err.stack}`);
      });
    }
  }, [setValue, core, account, fetchData]);

  return value;
};

export default useGetPoolRangeStatus;
