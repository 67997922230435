import { Grid } from "@material-ui/core";
import TextWrapper from "../../../components/TextWrapper";
import TextButton from "../../../components/TextButton";
import Loader from "react-spinners/BeatLoader";
import theme from "../../../theme";
import useGetPoolRangeStatus from "../../../hooks/state/V3Staking/useGetPoolRangeStatus";
import useGetPoolRewards from "../../../hooks/state/V3Staking/useGetPoolRewards";
import styled from "styled-components";
import useV3Claim from "../../../hooks/callbacks/V3Staking/useV3Claim";
import useV3Withdraw from "../../../hooks/callbacks/V3Staking/useV3Withdraw";
import { IV3PositionData } from "../../../hooks/state/V3Staking/useGetV3StakePositionData";
import useCore from "../../../hooks/useCore";
import { useGetActiveChainId } from "../../../state/chains/hooks";
import { getDisplayBalance } from "../../../utils/formatBalance";
import { getImageUrl } from "../../../utils/getImageUrl";
import useV3Deposit from "../../../hooks/callbacks/V3Staking/useV3Deposit";
import IconLoader from "../../../components/IconLoader";
import useGetNFTBoost from "../../../hooks/state/V3Staking/useGetNFTBoost";
import { IAPR } from "../../../hooks/state/V3Staking/useGetV3StakingAPR";
import { useMemo } from "react";

interface IProps {
  data: IV3PositionData;
  apr: { minApr: number, maxApr: number };
}

const StakePositionsRow = (props: IProps) => {
  const { data, apr } = props;
  const core = useCore();
  const chainId = useGetActiveChainId();

  const inRange = useGetPoolRangeStatus(data.gauge, data.nftId);
  const rewards = useGetPoolRewards(data.gauge, data.nftId);
  const detailsLink = `https://app.uniswap.org/#/pool/${data.nftId.toString()}`
  const boostedBy = useGetNFTBoost(data.gauge, data.nftId);

  const stakeNFTAction = useV3Deposit(data.nftId, data.gauge);

  const handleStake = () => {
    stakeNFTAction(
      () => {
        window.location.reload();
      },
      () => {
      }
    ).then(() => {
    })
  }

  const withdrawNFTAction = useV3Withdraw(data.nftId, data.gauge);

  const handleWithdraw = () => {
    withdrawNFTAction(
      () => {
        window.location.reload();
      },
      () => {
      }
    ).then(() => {
    })
  }

  const claimNFTRewardsAction = useV3Claim(data.nftId, data.gauge);

  const handleClaim = () => {
    claimNFTRewardsAction(
      () => window.location.reload(),
      () => {
      }
    ).then(() => {
    })
  }

  const calcAPR = useMemo(() => {
    const perc = boostedBy.value / 5
    return apr.minApr + (apr.maxApr - apr.minApr) * perc
  }, [apr.maxApr, apr.minApr, boostedBy.value])

  /*const boostedAPR = () => {
    if (apr.isLoading || boostedBy.isLoading) return {isLoading: true, value: 0}
    else {
      const contractAddress = core._contracts[chainId][data.pool].address;
      const aprValue = apr.data[contractAddress];
      const calc = Number(aprValue) ? Number(aprValue) * boostedBy.value : 0;
      return calc
    }
  }*/

  return (
    <Card>
      <Grid container spacing={1} alignContent={'center'} alignItems={'center'}>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <div className={'single-line-center-center'}>
            <img src={getImageUrl(data.tokenUri)} width={50} />
          </div>
          {/*<div>
            <TextButton onClick={() => {
            }} text={'more details'} align={'center'} fontSize={12}/>
          </div>*/}
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <div className={'single-line-center-start pointer'} onClick={() => window.open(detailsLink)}>
            <TextWrapper
              text={data.nftId.toString()}
              fontSize={14}
              align={"center"}
            />
            <IconLoader iconName={'ArrowLinkColored'} iconType={'arrow'} width={16} height={16} />
          </div>

        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <TextWrapper
            text={`
            ${calcAPR
                ? (Number(calcAPR)).toLocaleString('en-US', { maximumFractionDigits: 2 }) + '% '
                : '-'
              }
            (${boostedBy.value.toLocaleString('en-US', { maximumFractionDigits: 2 })}X)`}
            fontSize={14}
            align={"center"}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          {
            inRange.isLoading
              ? <div className={'single-line-center-center'}>
                <Loader color={'#ffffff'} loading={true} size={2} margin={2} />
              </div>
              : <TextWrapper
                text={inRange.value ? 'Yes' : 'No'}
                fontSize={14}
                align={"center"}
                Fcolor={inRange.value ? theme.color.green[300] : theme.color.red[300]}
              />
          }
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          {
            data.isStaked ?
              <TextButton
                text={'Withdraw'}
                fontWeight={600}
                fontSize={14}
                align={"center"}
                onClick={handleWithdraw}
                tracking_id={'v3_withdraw'}
                tracking_params={{ other: { poolName: `${data.poolName}`, nftId: `${data.nftId.toString()}` } }}
              />
              : data.status === 'Live'
                ? <TextButton
                  text={'Stake'}
                  fontWeight={600}
                  fontSize={14}
                  align={"center"}
                  onClick={handleStake}
                  tracking_id={'v3_stake'}
                  tracking_params={{ other: { poolName: `${data.poolName}`, nftId: `${data.nftId.toString()}` } }}
                />
                : data.status === 'Upcoming'
                  ? <TextWrapper text={'Upcoming'} align={"center"} fontWeight={600}
                    fontSize={14} Fcolor={theme.color.yellow[300]} />
                  : <TextWrapper text={'Paused'} align={"center"} fontWeight={600}
                    fontSize={14} Fcolor={theme.color.red[300]} />
          }
        </Grid>
      </Grid>
      {rewards.isLoading
        ? <div className={'single-line-center-end'}>
          <Loader color={'#ffffff'} loading={true} size={2} margin={2} />
        </div>
        : !rewards.isLoading && rewards.value.eq(0)
          ? <div />
          : <div className={'single-line-center-end m-t-12 m-r-12'}>
            <TextWrapper text={`Rewards Earned:`} Fcolor={theme.color.transparent[100]} className={'m-r-4'}
              fontSize={12} />
            <div className={'single-line-center-end'}>
              <TextWrapper text={` ${getDisplayBalance(rewards.value, 18, 5)} MAHA`} className={'m-r-16'}
                fontSize={12} />
              <TextButton onClick={handleClaim} text={'Claim'} fontSize={12} tracking_id={'v3_claim'}
                tracking_params={{ other: { poolName: `${data.poolName}`, nftId: `${data.nftId.toString()}` } }} />
            </div>
          </div>}
    </Card>

  )
}

export default StakePositionsRow;

const Card = styled.div`
  padding: 12px 0;
  background: ${theme.color.transparent[500]};
  border-radius: 8px;

  @media (max-width: 600px) {
    padding: 12px 4px;
  }
`
