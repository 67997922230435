import {useWallet} from "use-wallet";
import {useCallback, useEffect, useState} from "react";
import {LOADING_DEFAULT_BASIC_STATE, NON_LOADING_DEFAULT_BASIC_STATE,} from "../../../utils/constants";
import {BasicState} from "../../../utils/interface";
import useCore from "../../useCore";
import {BigNumber} from "ethers";
import {useGetActiveChainId} from "../../../state/chains/hooks";

const useHasAttachments = (nftId: BigNumber) => {
  const [value, setValue] = useState<BasicState>(
    LOADING_DEFAULT_BASIC_STATE
  );

  const core = useCore();
  const {account} = useWallet();
  const chainId = useGetActiveChainId();
  const contract = core.getVestingContract(chainId);

  const fetchData = useCallback(async () => {
    if (!account) {
      setValue(NON_LOADING_DEFAULT_BASIC_STATE);
      return;
    }

    const number = await contract.attachments(nftId);

    setValue({
      isLoading: false,
      value: number
    })

  }, [account, contract, nftId]);

  useEffect(() => {
    if (core) {
      fetchData().catch((err) => {
        setValue(NON_LOADING_DEFAULT_BASIC_STATE);
        console.error(`Failed to fetch attachments: ${err.stack}`);
      });
    }
  }, [setValue, core, account, fetchData]);

  return value;
};

export default useHasAttachments;
