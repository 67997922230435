import Modal from "../../../components/Modal";
import {IGaugeDynamicData, IModalProps} from "../../../utils/interface";
import {IVoting} from "../../../hooks/state/GaugeWeightVote/useGetGaugeVotePerc";
import DataField from "../../../components/DataField";
import InfoTip from "../../../components/InfoTip";
import {Grid} from "@material-ui/core";
import Button from "../../../components/Button";
import useGaugeWeightVote from "../../../hooks/callbacks/GaugeWeightVote/useGaugeWeightVote";
import {useMemo, useState} from "react";
import useCore from "../../../hooks/useCore";
import {useGetActiveChainId} from "../../../state/chains/hooks";

interface IProps {
  sliderValue: IVoting[];
  gauges: IGaugeDynamicData[];
}

const ConfirmCastVote = (props: IModalProps & IProps) => {
  const {openModal, onModalClose, sliderValue, gauges} = props;
  const core = useCore();
  const chainId = useGetActiveChainId();

  const [casting, setCasting] = useState<boolean>(false);

  const castVoteAction = useGaugeWeightVote(
    sliderValue.filter(data => data.vote !== 0).map(a => a.address),
    sliderValue.filter(data => data.vote !== 0).map(a => {
      const value: number = Number(a.vote.toFixed(2))
      return Math.floor(value * 100)
    })
  )

  const castVote = () => {
    setCasting(true)
    castVoteAction(
      () => {
        window.location.reload();
      },
      () => {
        setCasting(false)
      }
    ).then(() => {
    })
  }

  const multiplier = useMemo(() => {
    const total = sliderValue.reduce((a, b) => a + Math.abs(b.vote), 0);
    return 100 / total;
  }, [sliderValue])

  return (
    <Modal
      open={openModal}
      closeButton
      handleClose={onModalClose}
      title={'Submit Your Votes'}>
      <div>
        {
          sliderValue.filter(data => data.vote !== 0).map((data, index) => {
            return (
              <DataField
                key={`${data.address}${index}`}
                label={gauges.filter(value => core._contracts[chainId][value.pool].address === data.address)[0]?.displayName}
                value={`${data.vote.toFixed(2)}% -> ${(data.vote * multiplier).toFixed(2)}%`}
                labelFontSize={16}
                valueFontSize={16}
                valueFontWeight={600}
                className={'m-b-8'}
              />
            )
          })
        }
        <div className={'m-t-32'}>
          <InfoTip
            type={'Warning'}
            msg={'Your votes will be rounded off to represent 100% of your voting power.'}
            className={'m-b-12'}
          />
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Button
                text={'Cancel'}
                onClick={onModalClose}
                variant={'transparent'}
                tracking_id={'cast_votes'}
                tracking_params={{
                  action: 'cancel',
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Button
                text={'Confirm'}
                onClick={castVote}
                loading={casting}
                tracking_id={'cast_votes'}
                tracking_params={{
                  action: 'confirm',
                  other: {
                    address: sliderValue.filter(data => data.vote !== 0).map(a => a.address),
                    vote: sliderValue.filter(data => data.vote !== 0).map(a => a.vote * 100),
                  }
                }}
              />
            </Grid>
          </Grid>
        </div>

      </div>
    </Modal>
  )
}

export default ConfirmCastVote;
