import { useCallback } from "react";

import { useAddPopup } from "../../../state/application/hooks";
import { useTransactionAdder } from "../../../state/transactions/hooks";

import useCore from "../../useCore";
import formatErrorMessage from "../../../utils/formatErrorMessage";
import { useGetActiveChainId } from "../../../state/chains/hooks";

const useClaimBribes = () => {
  const core = useCore();
  const addPopup = useAddPopup();
  const addTransaction = useTransactionAdder();
  const chainId = useGetActiveChainId();

  return useCallback(
    async (
      displayName: string,
      bribeAddress: string,
      tokens: string[],
      onSuccess: () => void,
      onFailure: () => void
    ): Promise<void> => {
      try {
        const contract = core._contracts[chainId][bribeAddress];
        const response = await contract.getReward(tokens);

        addTransaction(response, {
          summary: `Claim pool incentives from ${displayName.toString()}.`,
        });

        const tx = await response.wait();

        if (tx?.status === 1) onSuccess();
        if (tx?.status !== 1) onFailure();
      } catch (e: any) {
        onFailure();
        addPopup({
          error: {
            message: formatErrorMessage(e?.data?.message || e?.message),
            stack: e?.stack,
          },
        });
      }
    },
    [core, addPopup, chainId, addTransaction]
  );
};

export default useClaimBribes;
