import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {ethers} from "ethers";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ReferralUpdate = () => {
  const queries = useQuery();
  const referredBy = queries.get('referredBy');

  useEffect(() => {
    if (referredBy) {
      const isValid = ethers.utils.isAddress(referredBy);
      localStorage.setItem('referredBy', isValid ? referredBy : '');
    }
  }, [referredBy])

  return (
    <div/>
  )
}

export default ReferralUpdate;
