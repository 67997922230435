import {Grid} from "@material-ui/core";
import TextWrapper from "../../../components/TextWrapper";
import theme from "../../../theme";
import useGetV3PositionData from "../../../hooks/state/V3Staking/useGetV3PositionsData";
import styled from "styled-components";
import {ArthLogo, ElementConatiner, LoadingIndicator} from "../../../components/LoadingPage";
import React from "react";
import arthLogo from '../../../assets/icons/tokenSymbol/MAHA.svg';
import useGetV3StakePositionData from "../../../hooks/state/V3Staking/useGetV3StakePositionData";
import StakePositionsRow from "./StakePositionsRow";
import {IAPR} from "../../../hooks/state/V3Staking/useGetV3StakingAPR";
import {IApr} from "../../../hooks/state/V3Staking/useGetv3UniApr";

interface IProps {
  pool: string;
  apr: { minApr: number, maxApr: number };
}

const PositionsTable = ({pool, apr}: IProps) => {
  const positionData = useGetV3PositionData(pool);
  const stakedPosition = useGetV3StakePositionData(pool);

  /*Reference Code*/

  /*
    # tickLower and tickupper from positions

    const below = pool && typeof tickLower === 'number' ? pool.tickCurrent < tickLower : undefined
    const above = pool && typeof tickUpper === 'number' ? pool.tickCurrent >= tickUpper : undefined
    const inRange: boolean = typeof below === 'boolean' && typeof above === 'boolean' ? !below && !above : false
  */

  return (
    <div>
      <div className={'material-secondary m-b-24'}>
        <div className={'p-b-16 bottom-divider p-l-8 p-r-8'}>
          <Grid container spacing={1}>
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <TextWrapper
                text={'Token Id'}
                fontWeight={600}
                fontSize={14}
                Fcolor={theme.color.transparent[100]}
                align={"center"}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3}>
              <TextWrapper
                text={'APR'}
                fontWeight={600}
                fontSize={14}
                Fcolor={theme.color.transparent[100]}
                align={"center"}
              />
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2}>
              <TextWrapper
                text={'In Range'}
                fontWeight={600}
                fontSize={14}
                Fcolor={theme.color.transparent[100]}
                align={"center"}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3}>
              <TextWrapper
                text={'Actions'}
                fontWeight={600}
                fontSize={14}
                Fcolor={theme.color.transparent[100]}
                align={"center"}
              />
            </Grid>
          </Grid>
        </div>
        <PositionTableBody>
          {
            positionData.isLoading || stakedPosition.isLoading
              ? <CLoader>
                <ElementConatiner>
                  <LoadingIndicator/>
                  <ArthLogo src={arthLogo} alt="ARTH"/>
                </ElementConatiner>
              </CLoader>
              : positionData.data.length === 0 && !positionData.isLoading && stakedPosition.data.length === 0 && !stakedPosition.isLoading
                ? <TextWrapper text={'No Position Found'} align={'center'} className={'m-t-32'}/>
                : <div>
                  {
                    positionData.data.length === 0 && !positionData.isLoading
                      ? <div/>
                      : positionData.data.map((data, index) => {
                        return (
                          <div className={`p-t-20 ${index + 1 === positionData.data.length ? '' : 'p-b-20 bottom-divider'}`}
                               key={index}>
                            <StakePositionsRow data={data} apr={apr}/>
                          </div>
                        )
                      })
                  }
                  {
                    stakedPosition.data.length === 0 && !stakedPosition.isLoading
                      ? <div/>
                      : stakedPosition.data.map((data, index) => {
                        return (
                          <div
                            className={`p-t-20 ${index + 1 === stakedPosition.data.length ? '' : 'p-b-20 bottom-divider'}`}
                            key={index}>
                            <StakePositionsRow data={data} apr={apr}/>
                          </div>
                        )
                      })
                  }
                </div>
          }
        </PositionTableBody>
      </div>
    </div>

  )
}

export default PositionsTable;

const PositionTableBody = styled.div`
  position: relative;
`

const CLoader = styled.div`
  min-height: 200px;
`

const Card = styled.div`
  min-height: 300px;
  position: relative;
  border-radius: 8px;
`
