import React from "react";
import IconLoader from "../../../components/IconLoader";
import {makeStyles,} from "@material-ui/core/styles";
import Button from "../../../components/Button";
import TextWrapper from "../../../components/TextWrapper";
import {DataGrid, GridColDef} from "@material-ui/data-grid";
import '../../../customCss/Custom-Mahadao-Data-Table.css';
import TextButton from "../../../components/TextButton";
import {IGaugeStaticData} from "../../../utils/interface";
import useGetV3StakingAPR from "../../../hooks/state/V3Staking/useGetV3StakingAPR";
import Loader from "react-spinners/BeatLoader";
import theme from "../../../theme";

export function SortedDescendingIcon() {
  return <IconLoader iconName={'ArrowTailUp'} iconType="arrow"/>;
}

export function SortedAscendingIcon() {
  return <IconLoader iconName={'ArrowTailDown'} iconType="arrow"/>;
}

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      cursor: 'pointer',
    },
    '& .super-app.negative': {
      color: '#FA4C69',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      color: '#20C974',
      fontWeight: '600',
    },
    '& .super-app.loss': {
      color: '#FA4C69',
      fontWeight: '600',
    },
    '& .super-app.profit': {
      color: '#20C974',
      fontWeight: '600',
    },
  },
  gridStyle: {
    color: 'white',
  },
});

interface IProps {
  data: IRows[];
  aprLoading: boolean
}

interface IRows extends IGaugeStaticData {
  minApr: string;
  maxApr: string;
}

const DataTable = (props: IProps) => {
  const classes = useStyles();

  const columns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Pool Name',
      flex: 0.5,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div className={'start-center'}>
            <span>{params.value}</span>
            <div className={'single-line-center-start'}>
              <TextButton
                onClick={() => window.open(`${params.row.liquidityLink}`)}
                text={`Add Liquidity`}
                fontSize={12}
              />
              <IconLoader iconName={'ArrowLinkColored'} iconType={'arrow'} width={16} height={16}/>
            </div>

          </div>
        );
      },
    },
    /*{
      field: 'poolFee',
      headerName: 'Pool Fee',
      flex: 0.2,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div className={'start-center'}>{params.value}%</div>
        )
      },
    },*/
    {
      field: 'minApr',
      headerName: 'APR',
      flex: 0.3,
      sortable: false,
      renderCell: (params: any) => {
        const minApr = params.value;
        const maxApr = params.row.maxApr;
        if (props.aprLoading) {
          return (
            <div className={'start-center'}>
              <Loader color={'#ffffff'} loading={true} size={2} margin={2}/>
            </div>
          )
        }
        if (Number(minApr) && Number(maxApr)) {
          return (
            <div className={'start-center'}>
              {
                Number(minApr).toLocaleString('en-Us', {maximumFractionDigits: 2}) +
                '%' +
                ' - ' +
                (Number(maxApr)).toLocaleString('en-Us', {maximumFractionDigits: 2}) +
                '%'
              }
            </div>
          );
        } else if (Number(minApr)) {
          return (
            <div className={'start-center'}>
              {
                Number(minApr).toLocaleString('en-Us', {maximumFractionDigits: 2}) + '%'
              }
            </div>
          );
        } else if (Number(maxApr)) {
          return (
            <div className={'start-center'}>
              {
                Number(maxApr).toLocaleString('en-Us', {maximumFractionDigits: 2}) + '%'
              }
            </div>
          );
        } else {
          return (
            <div className={'start-center'}>
              -
            </div>
          )
        }

      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.2,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div className={'start-center'}>
            <TextWrapper
              text={params.value}
              Fcolor={
                params.value === 'Closed'
                  ? theme.color.red[300]
                  : params.value === 'Upcoming'
                    ? theme.color.yellow[300]
                    : params.value === 'Live'
                      ? theme.color.green[300]
                      : 'white'
              }

            />
          </div>
        )
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.2,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div style={{marginTop: 'auto', marginBottom: 'auto', width: '150px'}}>
            <Button
              text={'Visit pool'}
              disabled={params.row.status !== "Live"}
              to={`${params.row.poolGoToLink}`}
              tracking_id={'visit_v3Staking_pool'}
              tracking_params={{
                collateral: `${params.row.poolName}`
              }}
            />
          </div>
        )
      },
    },
  ];

  const noResultsComponent = () => {
    return (
      <div className="noResultContainer">
        <div className="internal">
          <TextWrapper
            text={'No gauges found'}
            fontSize={24}
            fontWeight={'bold'}
            className="m-b-8"
            align="center"
          />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root} style={{position: 'relative'}}>
      <DataGrid
        className={classes.gridStyle}
        getRowId={(rows) => rows.displayName}
        rows={props.data}
        columns={columns}
        pageSize={10}
        hideFooterPagination={false}
        rowHeight={80}
        autoHeight={true}
        disableColumnMenu={true}
        loading={false}
        components={{
          NoRowsOverlay: noResultsComponent,
          ColumnSortedDescendingIcon: SortedDescendingIcon,
          ColumnSortedAscendingIcon: SortedAscendingIcon,
        }}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
      />
    </div>
  )
}

export default DataTable;
