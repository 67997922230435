import { BigNumber } from "ethers";
import { useCallback } from "react";

import { useAddPopup } from "../../../state/application/hooks";
import { useTransactionAdder } from "../../../state/transactions/hooks";
import formatErrorMessage from "../../../utils/formatErrorMessage";
import useCore from "../../useCore";
import { useGetActiveChainId } from "../../../state/chains/hooks";
import { useWallet } from "use-wallet";

const useV3Deposit = (nftId: BigNumber, gauge: string) => {
  const core = useCore();
  const addPopup = useAddPopup();
  const addTransaction = useTransactionAdder();
  const chainId = useGetActiveChainId();
  const { account } = useWallet();

  return useCallback(
    async (onSuccess: () => void, onFailure: () => void): Promise<void> => {
      const managerContract = core.getUniswapNFTManager(chainId);

      try {
        const response = await managerContract[
          "safeTransferFrom(address,address,uint256)"
        ](account, core._contracts[chainId][gauge].address, nftId);

        addTransaction(response, {
          summary: `Stake ${nftId.toString()}`,
        });

        const tx = await response.wait();

        if (tx?.status === 1) onSuccess();
        if (tx?.status !== 1) onFailure();
      } catch (e: any) {
        console.log("useV3Deposit error", e);
        onFailure();
        addPopup({
          error: {
            message: formatErrorMessage(e?.data?.message || e?.message),
            stack: e.stack,
          },
        });
      }
    },
    [core, chainId, account, gauge, nftId, addTransaction, addPopup]
  );
};

export default useV3Deposit;
