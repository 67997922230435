import {BigNumber} from 'ethers';
import {useCallback, useEffect, useState} from 'react';

import useCore from '../useCore';
import {BasicState} from '../../utils/interface';
import {useBlockNumber} from '../../state/application/hooks';
import {LOADING_DEFAULT_BASIC_STATE, NON_LOADING_DEFAULT_BASIC_STATE} from '../../utils/constants';
import {useGetActiveChainId} from "../../state/chains/hooks";

const useGetTotalVpInGov = () => {
  const [state, setState] = useState<BasicState>(LOADING_DEFAULT_BASIC_STATE);

  const core = useCore();
  const chainId = useGetActiveChainId();
  const blockNumber = useBlockNumber();
  const contract = core.getVestingContract(chainId);

  const fetchValue = useCallback(async () => {
    const totalSupply: BigNumber = await contract.totalSupply();
    setState({
      isLoading: false,
      value: totalSupply
    });
  }, [contract]);

  useEffect(() => {
    if (core) {
      fetchValue().catch((err) => {
        setState(NON_LOADING_DEFAULT_BASIC_STATE);
        console.error(`Failed to fetch total voting power: ${err.stack}`)
      });
    } else {
      setState(NON_LOADING_DEFAULT_BASIC_STATE);
    }
  }, [blockNumber, core, fetchValue]);

  return state;
};

export default useGetTotalVpInGov;
