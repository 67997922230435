import React from 'react';
import styled from 'styled-components';
import { useWallet } from 'use-wallet';
import Grid from '@material-ui/core/Grid';

import Button from '../../components/Button';
import PageHeader from "../../components/PageHeader";

import useFaucetClaim from "../../hooks/callbacks/useFaucetClaim";
import useCore from '../../hooks/useCore';
import { useGetActiveChainId } from "../../state/chains/hooks";

const Faucet = () => {
  const core = useCore();
  const { account, connect } = useWallet();

  const claimTokens = useFaucetClaim();
  const isWalletConnected = !!account;
  const chainId = useGetActiveChainId();

  return (
    <div className="custom-container">
      <PageHeader
        title={'MAHA faucet'}
        subTitle={'Get tokens to test the portal (testnet only)'}
        className="m-b-40"
      />
      <Grid container spacing={2}>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <CustomInfoCard className={'material-primary'}>
            <PrimaryText>Claim MAHA({core._tokens[chainId]['MAHA'].address}) Test Tokens here</PrimaryText>
            <Grid container lg={12} style={{ marginTop: '32px' }}>
              <Grid item lg={4} md={4} sm={6} xs={6}>
                {!isWalletConnected ? (
                  <Button
                    text={'Connect Wallet'}
                    size={'lg'}
                    onClick={() => connect('injected').then(() => {
                      localStorage.removeItem('disconnectWallet')
                    })}
                  />
                ) : (
                  <Button
                    variant={'default'}
                    text="Submit"
                    size={'sm'}
                    onClick={() => claimTokens(core._tokens[chainId]['MAHA'])}
                  />
                )}
              </Grid>
            </Grid>
          </CustomInfoCard>
        </Grid>
        {/* <Grid item lg={6} md={12} sm={12} xs={12}>
          <CustomInfoCard className={'material-primary'}>
            <PrimaryText>Claim ARTH({core._tokens[chainId]['ARTH'].address}) Test Tokens here</PrimaryText>
            <Grid container lg={12} style={{ marginTop: '32px' }}>
              <Grid item lg={4} md={4} sm={6} xs={6}>
                {!isWalletConnected ? (
                  <Button
                    text={'Connect Wallet'}
                    size={'lg'}
                    onClick={() => connect('injected').then(() => {
                      localStorage.removeItem('disconnectWallet')
                    })}
                  />
                ) : (
                  <Button
                    variant={'default'}
                    text="Submit"
                    size={'sm'}
                    onClick={() => claimTokens(core._tokens[chainId]['ARTH'])}
                  />
                )}
              </Grid>
            </Grid>
          </CustomInfoCard>
        </Grid> */}
      </Grid>
    </div>
  );
};

const CustomInfoCard = styled.div`
`;

const PrimaryText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.88);
  margin-bottom: 12px;
`;

export default Faucet;
