import {useWallet} from "use-wallet";
import {useCallback, useEffect, useState} from "react";
import useCore from "../useCore";
import useGetTotalVpInGov from "./useGetTotalVPInGov";
import {getDisplayBalance} from "../../utils/formatBalance";

export interface State {
  isLoading: boolean;
  maha: number;
  sclp: number;
}

const LOADING: State = {
  isLoading: true,
  maha: 0,
  sclp: 0,
}

const NON_LOADING: State = {
  isLoading: false,
  maha: 0,
  sclp: 0,
}

const useGetMaxMAHAXStakingAPR = () => {
  const [value, setValue] = useState<State>(LOADING);

  const core = useCore();
  const {account} = useWallet();
  const totalMAHAX = useGetTotalVpInGov();

  const fetchWeeklySupply = async () => {
    return await fetch('https://api.arthcoin.com/apr/governance')
      .then(res => res.json())
      .then(res => {
        setValue({
          isLoading: false,
          "maha": res["feedistributor-maha"].current.max,
          "sclp": res["feedistributor-sclp"].current.max,
        })
        // return res;
      })
      .catch(err => {
        console.error(`Failed to fetch staking rewards: ${err.stack}`)
        setValue({
          isLoading: false,
          "maha": 0,
          "sclp": 0,
        })
      })
  }

  /*const fetchData = useCallback(async () => {
    const data = await fetchWeeklySupply();
    if (totalMAHAX.isLoading) setValue(LOADING);
    else {
      if (!totalMAHAX.value.eq(0)) {
        const totalMAHAXInNumber = Number(getDisplayBalance(totalMAHAX.value));
        const maxMAHAAPR = ((data["maha"] * 52) / totalMAHAXInNumber) * 100;
        const maxSCLPAPR = ((data["sclp"] * 52) / totalMAHAXInNumber) * 100;
        setValue({
          isLoading: false,
          "maha": maxMAHAAPR,
          "sclp": maxSCLPAPR,
        })
      } else {
        setValue(NON_LOADING);
      }
    }
  }, [totalMAHAX.isLoading, totalMAHAX.value]);*/

  useEffect(() => {
    if (core) {
      fetchWeeklySupply().catch((err) => {
        setValue(NON_LOADING);
        console.error(`Failed to get max mahax staking apr: ${err.stack}`);
      });
    }
  }, [setValue, core, account]);

  return value;
};

export default useGetMaxMAHAXStakingAPR;
