import {BigNumber} from 'ethers'
import {Grid} from '@material-ui/core'
import {useEffect, useMemo, useState} from 'react'

import Input from '../../../components/Input'
import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import InfoTip from '../../../components/InfoTip'
import DataField from '../../../components/DataField'
import ConfirmLockMoreMAHA from './ConfirmLockMoreMAHA'
import InputContainer from '../../../components/InputContainer'
import CollateralDropDown from '../../../components/CollateralDropDown'

import useCore from '../../../hooks/useCore'
import useTokenBalance from '../../../hooks/useTokenBalance'
import {YEAR_IN_MS} from '../../../utils/constants'
import {formatToBN, getDisplayBalance} from '../../../utils/formatBalance'
import useApprove, {ApprovalState} from '../../../hooks/callbacks/useApprove'
import ActionButton from "../../../components/ActionButton";
import {useGetActiveChainId} from "../../../state/chains/hooks";

interface Iprops {
  nftId: BigNumber;
  alreadyLockedMAHA: BigNumber
  currentVotingPower: BigNumber
  dateSelected: number;
  modalOpen: boolean
  onModalClose: () => void
}

const LockMoreMAHA = (props: Iprops) => {
  const {
    alreadyLockedMAHA,
    dateSelected,
    currentVotingPower,
    modalOpen,
    onModalClose,
    nftId,
  } = props

  const [text, setText] = useState<string>('')
  const [lockerModal, setLockerModal] = useState<boolean>(false)

  const core = useCore()
  const chainId = useGetActiveChainId();
  const mahaBalance = useTokenBalance(core._tokens[chainId]['MAHA']);

  const [approveStatus, approve] = useApprove(
    core._tokens[chainId]['MAHA'],
    core.getVestingContract(chainId).address,
    formatToBN(text),
  )

  useEffect(() => {
    setText('');
  }, [nftId])

  const isInputGTBalance = useMemo(() => {
    if (mahaBalance.isLoading) return false
    const bnText: BigNumber = formatToBN(text, 18)
    return mahaBalance.value.lt(bnText)
  }, [text, mahaBalance])

  const votingShare: string = useMemo(() => {
    if (text === '' || dateSelected === null || !text)
      return BigNumber.from(0).toString()

    const bn: BigNumber = formatToBN(text, 18).add(alreadyLockedMAHA)
    if (bn.lte(0)) return BigNumber.from(0).toString()

    const timestamp: number = Number(dateSelected.toString())
    const timeDiff = timestamp - Date.now()
    if (timeDiff <= 0 || !timeDiff) return BigNumber.from(0).toString()

    const value = bn.mul(timeDiff).div(YEAR_IN_MS).div(4)

    return Number(getDisplayBalance(value, 18, 3)).toLocaleString('en-US', {
      minimumFractionDigits: 3,
    })
  }, [text, dateSelected, alreadyLockedMAHA])

  const isApproved = useMemo(() => approveStatus === ApprovalState.APPROVED, [
    approveStatus,
  ])
  const isApproving = useMemo(() => approveStatus === ApprovalState.PENDING, [
    approveStatus,
  ])

  return (
    <>
      <ConfirmLockMoreMAHA
        nftId={nftId}
        text={text}
        votingShare={votingShare}
        dateSelected={dateSelected}
        modalOpen={lockerModal}
        onModalClose={() => setLockerModal(false)}
      />
      <Modal
        title={`Lock More MAHA for #${nftId}`}
        open={modalOpen}
        handleClose={onModalClose}
        closeButton
      >
        <div>
          {isInputGTBalance && (
            <InfoTip
              className={'m-b-24'}
              type={'Error'}
              msg={'Amount cannot be greater than wallet balance.'}
            />
          )}
          <InputContainer
            label={'Enter Amount '}
            dataLabel={'Balance:'}
            dataValueLoading={mahaBalance.isLoading}
            dataValue={Number(
              getDisplayBalance(mahaBalance.value, 18, 6),
            ).toLocaleString('en-US', {minimumFractionDigits: 3})}
            className={'m-b-24'}
          >
            <div className="single-line-center-between">
              <Input
                value={text}
                setValue={(data) => setText(data)}
                maxTag={true}
                onMaxClick={() => {
                  setText(
                    Number(
                      getDisplayBalance(mahaBalance.value, 18, 18),
                    ).toString(),
                  )
                }}
              />
              <CollateralDropDown selectedSymbol={'MAHA'}/>
            </div>
          </InputContainer>
          <DataField
            label={'Current MAHAX balance'}
            labelToolTip={'The amount of MAHAX that you have currently.'}
            value={Number(
              getDisplayBalance(currentVotingPower, 18, 3),
            ).toLocaleString('en-US', {minimumFractionDigits: 3})}
            valueTag={'MAHAX'}
            className={'m-t-24 m-b-12'}
          />
          <DataField
            label={'New MAHAX balance will be'}
            labelToolTip={
              'The amount of MAHAX you will recieve for locking MAHA.'
            }
            value={`${votingShare}`}
            valueTag={'MAHAX'}
            className={'m-b-20'}
          />

          <div className="m-t-32">
            <InfoTip
              type={'Warning'}
              msg={
                'Your votes for the pools will get reset when you lock more MAHA'
              }
              className={'m-b-8'}
            />
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Button
                  variant={'transparent'}
                  onClick={onModalClose}
                  tracking_id={'lock_more_MAHA_modal'}
                  tracking_params={{
                    action: 'cancel',
                    collateral: 'MAHA',
                    amount: Number(text),
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                {isApproved ? (
                  <ActionButton
                    onClick={() => {
                      setLockerModal(true)
                      onModalClose()
                    }}
                    disabled={isInputGTBalance || !Number(text)}
                    loading={isApproving}
                    tracking_id={'lock_more_MAHA'}
                    tracking_params={{
                      action: 'initial',
                      collateral: 'MAHA',
                      amount: Number(text),
                    }}
                    text={'Lock MAHA'}
                  />
                ) : (
                  <ActionButton
                    onClick={approve}
                    loading={isApproving}
                    disabled={isInputGTBalance || !Number(text)}
                    tracking_id={"approve_lock_more"}
                    tracking_params={{
                      collateral: 'MAHA'
                    }}
                    text={'Approve MAHA'}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default LockMoreMAHA
