import styled from "styled-components";
import React, {useState} from "react";
import {Grid} from "@material-ui/core";
import useGetNFTUri from "../../../hooks/state/Vesting/useGetNFTUri";
import theme from "../../../theme";
import DataField from "../../../components/DataField";
import {getDisplayBalance} from "../../../utils/formatBalance";
import Button from "../../../components/Button";
import moment from "moment";
import {IState} from "../../../hooks/state/Vesting/useGetAllNFTs";
import TextWrapper from "../../../components/TextWrapper";
import {useMediaQuery} from "react-responsive";
import IconLoader from "../../../components/IconLoader";
import {BigNumber} from "ethers";
import TextButton from "../../../components/TextButton";
import useStakeNFT from "../../../hooks/callbacks/Vesting/useStakeNFT";
import useUnStakeNFT from "../../../hooks/callbacks/Vesting/useUnStakeNFT";
import useGetNFTStaked from "../../../hooks/state/Vesting/useGetNFTStaked";
import Loader from "react-spinners/BeatLoader";
import revealNFT from './revealNFT.png'
import {useGetActiveChainId} from "../../../state/chains/hooks";

interface IProps {
  key: string;
  selectedId: BigNumber | undefined;
  onLockMoreMAHA: () => void;
  onExtendLock: () => void;
  tranferNFT: () => void;
  onWithdraw: () => void;
  setSelectedNFT: () => void;
}

const LockingDetailsCard = (props: IState & IProps) => {
  const {
    id,
    lockEnds,
    lockValue,
    lockAmount,
    selectedId,
    url,
    onLockMoreMAHA,
    onExtendLock,
    tranferNFT,
    onWithdraw,
    setSelectedNFT
  } = props;

  const isMobile = useMediaQuery({maxWidth: '600px'});
  const chainId = useGetActiveChainId();

  const [toggleMenu, setToggleMenu] = useState<boolean>(false);

  const listItemClick = (fn: () => void) => {
    setToggleMenu(false)
    fn();
  }

  const hasStaked = useGetNFTStaked(id);

  const WithdrawalAllowed = new Date(Number(`${lockEnds.toString()}000`)).getTime() < new Date().getTime() && lockAmount.gt(0);

  //Stake
  const stakeNFT = useStakeNFT(id);
  const handleStakeNFT = () => {
    stakeNFT(
      () => {
        window.location.reload();
      },
      () => {
      }
    ).then(() => {
    })
  }

  //Unstake
  const unStakeNFT = useUnStakeNFT(id);
  const handleUnStakeNFT = () => {
    unStakeNFT(
      () => {
        window.location.reload();
      },
      () => {
      }
    ).then(() => {
    })
  }

  if (id.toString() === '0') return <div/>;

  return (
    <Card className={'material-primary'}>
      <Menu onClick={() => {
        setSelectedNFT()
        setToggleMenu(!toggleMenu)
      }}>
        <IconLoader iconName={'DotMenu'} width={24}/>
      </Menu>
      {toggleMenu && selectedId && selectedId.eq(id) &&
        <OpenableMenu>
          <Item onClick={() => listItemClick(onLockMoreMAHA)}>
            <TextButton
              text={'Lock More MAHA'}
              fontWeight={300}
              tracking_id={'lock_more_MAHA_modal'}
              tracking_params={{position: 'menu'}}
              onClick={() => {
              }}
            />
          </Item>
          <Item onClick={() => listItemClick(onExtendLock)}>
            <TextButton
              text={'Extend Lock Period'}
              fontWeight={300}
              tracking_id={'extend_lock_period_modal'}
              tracking_params={{position: 'menu'}}
              onClick={() => {
              }}/>
          </Item>
          <Item onClick={() =>
            hasStaked.value.eq(0)
              ? listItemClick(tranferNFT)
              : {}
          } style={!hasStaked.value.eq(0) ? {cursor: 'not-allowed'} : {}}>
            {hasStaked.value.eq(0)
              ? <TextButton
                text={'Transfer'}
                tracking_id={'transfer_modal'}
                tracking_params={{position: 'menu'}}
                fontWeight={300}
                onClick={() => {
                }}
              />
              : <TextWrapper text={'Transfer'} fontWeight={300} Fcolor={theme.color.transparent[100]}/>}
          </Item>
          <Item onClick={() =>
            WithdrawalAllowed && hasStaked.value.eq(0)
              ? listItemClick(onWithdraw)
              : {}
          } style={!WithdrawalAllowed && !hasStaked.value.eq(0) ? {cursor: 'not-allowed'} : {}}>
            {WithdrawalAllowed && hasStaked.value.eq(0)
              ? <TextButton
                text={'Withdraw'}
                fontWeight={300}
                tracking_params={{position: 'menu'}}
                tracking_id={'withdraw_modal'}
                onClick={() => {
                }}
              />
              : <TextWrapper text={'Withdraw'} fontWeight={300} Fcolor={theme.color.transparent[100]}/>
            }
          </Item>
          {hasStaked.value.gt(0) && <Item onClick={() => listItemClick(handleUnStakeNFT)}>
            <TextButton
              text={'Unstake'}
              fontWeight={300}
              onClick={() => {
              }}
              tracking_params={{position: 'menu'}}
            />
          </Item>}
        </OpenableMenu>}
      <Grid container spacing={isMobile ? 2 : 5}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <NFT
            src={url}
            width={'100%'}
          />
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <ContentPart>
            <InnerContentPart>
              <div>
                <div className={'m-b-8'}>
                  <DataField
                    label={'NFT ID'}
                    value={`#${id.toString()}`}
                    labelFontSize={16}
                    labelFontColor={theme.color.white}
                    valueFontSize={16}
                    valueFontColor={theme.color.white}
                    valueFontWeight={600}
                  />
                  <div className={'single-line-center-between'}>
                    <DataField
                      label={'ID can be used to search your NFT on blockchain'}
                      labelFontColor={theme.color.transparent[100]}
                      labelFontSize={12}
                    />
                    <div className={'single-line-center-start'}>
                      <TextButton
                        onClick={() => window.open(`https://opensea.io/assets/ethereum/0xbdd8f4daf71c2cb16cce7e54bb81ef3cfcf5aacb/${id.toString()}`)}
                        text={`View on opensea`}
                        fontSize={12}
                        tracking_id={'view_on_opensea'}
                      />
                      <IconLoader iconName={'ArrowLinkColored'} iconType={'arrow'} width={16} height={16}/>
                    </div>
                  </div>

                </div>
                <div className={'m-b-8'}>
                  <DataField
                    label={'Lock Amount'}
                    value={`${Number(getDisplayBalance(lockAmount, 18, 3)).toLocaleString()} MAHA`}
                    labelFontSize={16}
                    labelFontColor={theme.color.white}
                    valueFontSize={16}
                    valueFontColor={theme.color.white}
                    valueFontWeight={600}
                  />
                  <DataField
                    label={'Amount that you locked'}
                    labelFontColor={theme.color.transparent[100]}
                    labelFontSize={12}
                  />
                </div>
                <div className={'m-b-8'}>
                  <DataField
                    label={'Lock Value'}
                    value={`${Number(getDisplayBalance(lockValue, 18, 3),).toLocaleString()} MAHAX`}
                    labelFontSize={16}
                    labelFontColor={theme.color.white}
                    valueFontSize={16}
                    valueFontColor={theme.color.white}
                    valueFontWeight={600}
                  />
                  <DataField
                    label={'Your voting power'}
                    labelFontColor={theme.color.transparent[100]}
                    labelFontSize={12}
                  />
                </div>

                <div className={'m-b-8'}>
                  <DataField
                    label={'Lock Expires On'}
                    value={moment(new Date(Number(`${lockEnds.toString()}000`))).format('DD-MM-YYYY')}
                    labelFontSize={16}
                    labelFontColor={theme.color.white}
                    valueFontSize={16}
                    valueFontColor={theme.color.white}
                    valueFontWeight={600}
                  />
                  <DataField
                    label={''}
                    value={
                      new Date(Number(`${lockEnds.toString()}000`)).getTime() > new Date().getTime()
                        ? `Expires in ${moment(new Date(Number(`${lockEnds.toString()}000`))).fromNow(true)}`
                        : `Expired ${moment(new Date(Number(`${lockEnds.toString()}000`))).fromNow(true)} ago`
                    }
                    valueFontColor={theme.color.transparent[100]}
                    valueFontSize={12}
                  />
                </div>
              </div>
              <div className={'m-t-32'}>
                {
                  (
                    <Grid container spacing={isMobile ? 2 : 4}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Button
                          text={'Lock More MAHA'}
                          tracking_id={'lock_more_MAHA_modal'}
                          tracking_params={{position: 'card'}}
                          onClick={() => {
                            setSelectedNFT()
                            onLockMoreMAHA()
                          }}
                          variant={'transparent'}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Button
                          text={'Extend Lock Period'}
                          tracking_id={'extend_lock_period_modal'}
                          tracking_params={{position: 'card'}}
                          onClick={() => {
                            setSelectedNFT()
                            onExtendLock()
                          }}
                          variant={'transparent'}
                        />
                      </Grid>
                    </Grid>
                  )
                }
              </div>
              {
                (hasStaked.isLoading
                  ? <div className={'m-t-12'}>
                    <Loader color={'white'} size={6}/>
                  </div>
                  : hasStaked.value.eq(0)
                    ? <div className={'m-t-12'}>
                      <Button
                        onClick={handleStakeNFT}
                        text={'Stake NFT'}
                        tracking_id={'stake_NFT'}
                      />
                    </div>
                    : <div/>)
              }
            </InnerContentPart>
          </ContentPart>
        </Grid>
      </Grid>
      <ImagePart>

      </ImagePart>
    </Card>
  )
}

export default LockingDetailsCard;

const Card = styled.div`
  border-radius: 8px;
  padding: 32px;
  margin-bottom: 24px;
  position: relative;
  @media (max-width: 600px) {
    padding: 32px 24px;
  }
`

const ImagePart = styled.div`

`

const NFT = styled.img`
  border-radius: 8px;
  max-width: 312px;
  max-height: 312px;
`

const ContentPart = styled.div`
  padding: 24px;
  border-radius: 8px;
  height: 100%;
  background: ${theme.color.transparent[500]};
`

const InnerContentPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const Menu = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  padding: 8px 8px 6px;
  background: ${theme.color.transparent[500]};
  border-radius: 6px;
`

const OpenableMenu = styled.div`
  background: ${theme.color.gradients['brown_gradient']};
  padding: 8px;
  border-radius: 6px;
  position: absolute;
  right: 36px;
  top: 36px;
  z-index: 999;
`

const Item = styled.div`
  padding: 12px;
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 4px;

  &:hover {
    background: ${theme.color.transparent[500]};
  }

`
