import { BigNumber } from "ethers";
import { useCallback } from "react";
import { useTransactionAdder } from "../../../state/transactions/hooks";

import useCore from "../../useCore";
import { useAddPopup } from "../../../state/application/hooks";
import formatErrorMessage from "../../../utils/formatErrorMessage";
import { useGetActiveChainId } from "../../../state/chains/hooks";

const useGaugeWeightVote = (
  pool: string[],
  weights: number[],
) => {
  const core = useCore();
  const addPopup = useAddPopup();
  const addTransaction = useTransactionAdder();
  const chainId = useGetActiveChainId();

  return useCallback(
    async (onSuccess: () => void, onFailure: () => void): Promise<void> => {
      try {
        console.log(pool, weights)
        const contract = core.getVotingContract(chainId);
        const response = await contract.vote(
          pool,
          weights,
        );

        addTransaction(response, {
          summary: `Pool Votes Submitted`,
        });

        const tx = await response.wait();

        if (tx?.status === 1) onSuccess();
        if (tx?.status !== 1) onFailure();
      } catch (e: any) {
        console.log("useGaugeWeightVote error", e);
        addPopup({
          error: {
            message: formatErrorMessage(e?.data?.message || e?.message),
            stack: e?.stack,
          },
        });
        onFailure()
      }
    },
    [core, chainId, pool, weights, addTransaction, addPopup]
  );
};

export default useGaugeWeightVote;
