import Countdown from "react-countdown";
import TextWrapper from "../../../components/TextWrapper";
import theme from "../../../theme";

interface IProps {
  date: number;
}

const Timer = (props: IProps) => {
  const {date} = props;
  return (
    <div>
      <Countdown
        date={date}
        renderer={({days, hours, minutes, seconds}) => {
          return (
            <div className={'single-line-center-start'}>
              <div className={'tags'}>
                <TextWrapper text={String(days).length === 1 ? `0${String(days)}` : String(days)}/>
              </div>
              &nbsp;
              <TextWrapper
                text={"Days"}
                fontSize={12}
                Fcolor={theme.color.transparent[100]}
                align={"center"}
              /> &nbsp;
              <div className={'tags'}>
                <TextWrapper text={String(hours).length === 1 ? `0${String(hours)}` : String(hours)}/>
              </div>
              &nbsp;
              <TextWrapper
                text={"hrs"}
                fontSize={12}
                Fcolor={theme.color.transparent[100]}
                align={"center"}
              /> &nbsp;
              <div className={'tags'}>
                <TextWrapper text={String(minutes).length === 1 ? `0${String(minutes)}` : String(minutes)}/>
              </div>
              &nbsp;
              <TextWrapper
                text={"mins"}
                fontSize={12}
                Fcolor={theme.color.transparent[100]}
                align={"center"}
              /> &nbsp;
              <div className={'tags'}>
                <TextWrapper text={String(seconds).length === 1 ? `0${String(seconds)}` : String(seconds)}/>
              </div>
              &nbsp;
              <TextWrapper
                text={"sec"}
                fontSize={12}
                Fcolor={theme.color.transparent[100]}
                align={"center"}
              />
            </div>
          )
        }}
      />
    </div>
  )
}

export default Timer;
