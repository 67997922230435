import React from "react";
import PageHeader from "../../components/PageHeader";
import RewardsTable from "./components/RewardsTable";
import TextWrapper from "../../components/TextWrapper";
import styled from "styled-components";
import Button from "../../components/Button";
import useGetRewardsData from "../../hooks/state/rewards/useGetRewardsData";
import LoadingPage from "../../components/LoadingPage";
import {Grid} from "@material-ui/core";
import FeesAccumlated from "../Fees/component/FeesAccumlated";
import VoteIncentives from "../Fees/component/VoteIncentives";
import CountDownDiv from "./components/CountDownDiv";
import {useMediaQuery} from "react-responsive";
import theme from "../../theme";

export const RewardsStartTime = new Date("September 1, 2022 00:00:00 UTC").getTime();

const Rewards = () => {

  const rewardsData = useGetRewardsData();
  const isMobile = useMediaQuery({maxWidth: '600px'});

  const NoResultsComponent = () => {
    return (
      <div>
        <div className="internal">
          <TextWrapper
            text={'You have no pool incentive rewards.'}
            fontSize={24}
            fontWeight={'bold'}
            className="m-b-4"
            align="center"
            fontFamily={'Syne'}
          />
          <TextWrapper
            text={'Vote for a pool with an active pool incentive to start accumulating rewards.'}
            fontSize={18}
            className="m-b-24"
            Fcolor={'#FFFFFF64'}
            align={'center'}
          />
          <div style={{width: '130px', margin: 'auto'}} className="m-t-24">
            <Button
              text={'Vote for a Pool'}
              to={'/pools-vote'}
              tracking_id={'go_to_pools_vote'}
              tracking_params={{position: 'rewards_page'}}
            >
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="custom-container">
      <PageHeader
        title={"Earn Rewards & Fees"}
        subTitle={<div>
          You earn rewards from protocol fees or from <a href={'/#/staking'} className={'links'}>farming</a> or
          from&nbsp;
          <a href={'/#/pools-vote'} className={'links'}>pool incentives</a> by voting.
        </div>}
        learnMoreLink="https://docs.mahadao.com/governance/earning-fees-and-rewards"
        showLearnMore
        className="m-b-40"
      />
      <section>
        {/*<div className={'m-b-24'}>
          <CountDownDiv/>
        </div>*/}
        <div className={'p-b-40 m-b-40 mo-custom-container bottom-divider'}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FeesAccumlated/>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <VoteIncentives/>
            </Grid>
          </Grid>
        </div>
        <BribesRewardsSection>
          <div className={'m-b-40'}>
            <TextWrapper text={'Pools Vote Rewards'} fontSize={18}/>
            <TextWrapper
              text={'Rewards earned from voting a pool with be accumlated here'}
              Fcolor={theme.color.transparent[100]}
            />
          </div>
          <div>
            {
              rewardsData.isLoading
                ? <LoadingPage/>
                : !rewardsData.isLoading && rewardsData.value.length > 0
                  ? <RewardsTable rewardsData={rewardsData}/>
                  : <NoResultsComponent/>
            }
          </div>
        </BribesRewardsSection>
      </section>
      {/*}*/}


    </div>
  )
}

export default Rewards;

const BribesRewardsSection = styled.div`
  min-height: 200px;

`
