import {IGaugeStaticData, IModalProps} from "../../../utils/interface";
import Modal from "../../../components/Modal";
import InputContainer from "../../../components/InputContainer";
import Input from "../../../components/Input";
import {useMemo, useState} from "react";
import Button from "../../../components/Button";
import {Grid} from "@material-ui/core";
import CollateralDropDown from "../../../components/CollateralDropDown";
import {ethers} from "ethers";
import useGetTokenDetails from "../../../hooks/state/useGetTokenDetails";
import {formatToBN, getDisplayBalance} from "../../../utils/formatBalance";
import useCore from "../../../hooks/useCore";
import useApprove, {ApprovalState} from '../../../hooks/callbacks/useApprove'
import ERC20 from "../../../protocol/ERC20";
import useDepositBribe from "../../../hooks/callbacks/Bribes/useDepositBribe";
import States from "../../../components/States";
import {useGetActiveChainId} from "../../../state/chains/hooks";

type Props = {
  gauge: IGaugeStaticData;
}

const DepositModal = (props: IModalProps & Props) => {
  const {onModalClose, openModal, gauge} = props;

  const core = useCore();
  const chainId = useGetActiveChainId();

  const [address, setAddress] = useState<string>("");
  const [amount, setAmount] = useState<string>('');
  const [depositing, setDepositing] = useState<boolean>(false);

  const {fetchingDetails, tokenDetails} = useGetTokenDetails(address);

  const token = useMemo(() => {
    const isValid = ethers.utils.isAddress(address);
    return isValid
      ? new ERC20(
        address,
        core._signer || core.provider,
        tokenDetails.value.symbol,
        tokenDetails.value.decimals
      )
      : core._tokens[chainId]['MAHA'];
  }, [address, core._signer, core.provider, core._tokens, tokenDetails.value.symbol, tokenDetails.value.decimals, chainId]);

  const [approveStatus, approve] = useApprove(
    token,
    core._contracts[chainId][gauge.bribe].address,
    formatToBN(amount),
  );

  const isApproved = useMemo(() => approveStatus === ApprovalState.APPROVED, [approveStatus]);
  const isApproving = useMemo(() => approveStatus === ApprovalState.PENDING, [approveStatus]);

  const depositBribesAction = useDepositBribe(
    gauge.displayName,
    address,
    core._contracts[chainId][gauge.bribe].address,
    formatToBN(amount, tokenDetails.value.decimals),
  );

  const isAddressValid = useMemo(() => {
    return ethers.utils.isAddress(address);
  }, [address]);

  const isAmountGreaterThanBalance = useMemo(() => {
    const bn = formatToBN(amount, tokenDetails.value.decimals);
    return bn.gt(tokenDetails.value.balance);
  }, [tokenDetails, amount]);

  const disableButton = useMemo(() => {
    return !isAddressValid ||
      fetchingDetails ||
      isApproving ||
      isAmountGreaterThanBalance;
  }, [isAddressValid, fetchingDetails, isApproving, isAmountGreaterThanBalance]);

  const depositBribeHandler = () => {
    setDepositing(true)
    depositBribesAction(
      () => {
        setDepositing(false)
        onModalClose();
        window.location.reload();
      },
      () => {
        setDepositing(false)
        onModalClose();
      }
    );
  }

  console.log('tokenDetails', tokenDetails.value);

  return (
    <Modal
      title={`Create a pool incentive for ${gauge.displayName}`}
      open={openModal}
      handleClose={() => onModalClose()}
      closeButton
    >
      <div>
        <InputContainer
          label={'Enter Token Address'}
          className={'m-b-32'}
          dataValueLoading={tokenDetails.isLoading || fetchingDetails}
        >
          <States
            state={!isAddressValid && address !== '' ? 'error' : 'default'}
            msg={'Address not valid'}
          >
            <Input
              inputMode={'text'}
              value={address}
              useEnforcer={false}
              placeholder={"0x...."}
              setValue={setAddress}
              border={'6px'}
            />
          </States>
        </InputContainer>
      </div>
      <div>
        <InputContainer
          label={'Enter Amount'}
          dataLabel={'Balance:'}
          dataValueLoading={tokenDetails.isLoading || fetchingDetails}
          dataValue={Number(
            getDisplayBalance(tokenDetails.value.balance, tokenDetails.value.decimals, 6),
          ).toLocaleString('en-US', {minimumFractionDigits: 3})}
          className={'m-b-32'}
        >
          <States
            state={isAmountGreaterThanBalance ? 'error' : 'default'}
            msg={'Amount cannot be greater than your balance'}
          >
            <div className={'single-line-center-between'}>
              <Input
                disabled={fetchingDetails || tokenDetails.isLoading}
                value={amount}
                setValue={setAmount}
                maxTag={true}
                useEnforcer={true}
                onMaxClick={() => {
                  setAmount(Number(getDisplayBalance(tokenDetails.value.balance, tokenDetails.value.decimals, tokenDetails.value.decimals)).toString())
                }}
              />
              <CollateralDropDown loading={fetchingDetails} selectedSymbol={tokenDetails.value.symbol}/>
            </div>
          </States>
        </InputContainer>
      </div>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Button
            text={'Cancel'}
            onClick={onModalClose}
            variant={'transparent'}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          {
            !isApproved
              ? (
                <Button
                  text={
                    isApproved
                      ? 'Approved'
                      : 'Approve'
                  }
                  loading={isApproving}
                  disabled={disableButton || isApproved}
                  onClick={approve}
                  tracking_id={'approve_deposit_bribe'}
                  tracking_params={{
                    action: 'cancel',
                    collateral: tokenDetails.value.symbol,
                  }}
                />
              )
              : (
                <Button
                  text={'Deposit'}
                  loading={depositing}
                  disabled={disableButton || !isApproved || !isAddressValid || isAmountGreaterThanBalance || !Number(amount)}
                  onClick={depositBribeHandler}
                  tracking_id={'deposit_bribe'}
                  tracking_params={{
                    action: 'confirm',
                  }}
                />
              )
          }
        </Grid>
      </Grid>
    </Modal>
  )
}

export default DepositModal;
