import {BigNumber, Contract} from "ethers";
import {useCallback} from "react";

import {useAddPopup} from "../../../state/application/hooks";
import {useTransactionAdder} from "../../../state/transactions/hooks";
import formatErrorMessage from "../../../utils/formatErrorMessage";
import useCore from "../../useCore";
import {useGetActiveChainId} from "../../../state/chains/hooks";
import ABIS from "../../../protocol/deployments/abi";
import {useWallet} from "use-wallet";

export default function (contract: Contract, tokens: string[]) {
  const addPopup = useAddPopup();
  const addTransaction = useTransactionAdder();
  const {account} = useWallet();

  return useCallback(
    async (onSuccess: () => void, onFailure: () => void): Promise<void> => {
      if (tokens.length === 0) {
        addPopup({error: {message: 'Opps! Some went wrong please try again later', stack: ''}});
        onFailure();
      }
      try {
        const response = await contract.getReward(account, tokens);

        addTransaction(response, {
          summary: `Claim rewards from staking`,
        });

        const tx = await response.wait();

        if (tx?.status === 1) onSuccess();
        if (tx?.status !== 1) onFailure();

      } catch (e: any) {
        console.log('useClaim error', e);
        onFailure();
        addPopup({
          error: {
            message: formatErrorMessage(e?.data?.message || e?.message),
            stack: e.stack,
          },
        });
      }
    },
    [tokens, addPopup, contract, account, addTransaction],
  );
}
