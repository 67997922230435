import {IABIS} from "../../utils/interface";
import BaseV2Bribes from "./abi/BaseV2Bribes.json";
import BaseV2Voter from "./abi/BaseV2Voter.json";
import EmissionController from "./abi/EmissionController.json";
import FeeDistributor from "./abi/FeeDistributor.json";
import FeesSplitter from "./abi/FeesSplitter.json";
import GaugeLP from "./abi/GaugeLP.json";
import GaugeUniswapV3 from "./abi/GaugeUniswapV3.json";
import IERC20 from "./abi/IERC20.json";
import IUniswapNFTManager from "./abi/IUniswapNFTManager.json";
import IUniswapV3Pool from "./abi/IUniswapV3Pool.json";
import IWETH from "./abi/IWETH.json";
import MAHAReferralV1 from "./abi/MAHAReferralV1.json";
import MAHATimelockController from "./abi/MAHATimelockController.json";
import MAHAXGovernor from "./abi/MAHAXGovernor.json";
import MAHAXGovernorMaster from "./abi/MAHAXGovernorMaster.json";
import MAHAXLocker from "./abi/MAHAXLocker.json";
import MAHAXStaker from "./abi/MAHAXStaker.json";
import MAHAXVetoGovernor from "./abi/MAHAXVetoGovernor.json";
import MultiFeeDistributor from "./abi/MultiFeeDistributor.json";
import PendingFeeDistributor from "./abi/PendingFeeDistributor.json";
import Registry from "./abi/Registry.json";
import RenderingContract from "./abi/RenderingContract.json";
import StakingRewardsKeeper from "./abi/StakingRewardsKeeper.json";
import LockMigrator from "./abi/LockMigrator.json";
import MockERC20 from "./abi/MockERC20.json";
import IUniswapV3Factory from "./abi/IUniswapV3Factory.json";

const abis: IABIS = {
  BaseV2Bribes,
  BaseV2Voter,
  EmissionController,
  FeeDistributor,
  FeesSplitter,
  GaugeLP,
  GaugeUniswapV3,
  IERC20,
  IUniswapNFTManager,
  IUniswapV3Pool,
  IWETH,
  MAHAReferralV1,
  MAHATimelockController,
  MAHAXGovernor,
  MAHAXGovernorMaster,
  MAHAXLocker,
  MAHAXStaker,
  MAHAXVetoGovernor,
  MultiFeeDistributor,
  PendingFeeDistributor,
  Registry,
  RenderingContract,
  StakingRewardsKeeper,
  LockMigrator,
  MockERC20,
  IUniswapV3Factory
};

export default abis;
