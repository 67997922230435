import {useWallet} from "use-wallet";
import {useCallback, useEffect, useState} from "react";
import useCore from "../../useCore";
import {BigNumber} from "ethers";
import {IGaugeStaticData} from "../../../utils/interface";
import {getDisplayBalance} from "../../../utils/formatBalance";
import useGetVotingPowerOfNft from "./useGetVotingPowerOfNft";
import {BNZERO, DECIMALS_18} from "../../../utils/constants";
import {useGetActiveChainId} from "../../../state/chains/hooks";
import useGetTotalMAHAX from "../Vesting/useGetTotalMAHAX";

export interface IVoting {
  vote: number;
  address: string;
}

export interface IPassingState {
  isLoading: boolean;
  value: IVoting[];
}

export const Initial_Loading = {
  isLoading: true,
  value: [],
};

export const Initial_Non_Loading = {
  isLoading: false,
  value: [],
};

const useGetGaugeVotePerc = () => {
  const [value, setValue] = useState<IPassingState>(Initial_Loading);

  const core = useCore();
  const {account} = useWallet();
  const chainId = useGetActiveChainId();

  const voter = core.getVotingContract(chainId);
  const gauges: IGaugeStaticData[] = core._config[chainId].gauges;
  const votingPower = useGetTotalMAHAX();

  const fetchData = useCallback(async () => {
    if (!account) {
      setValue(Initial_Non_Loading);
      return;
    }

    if (votingPower.isLoading) return setValue(Initial_Loading);

    const allVotes = await Promise.all(
      gauges.map(async (gauge) =>
        voter.votes(account, core._contracts[chainId][gauge.pool].address)
      )
    );
    const yourTotalVotes = allVotes.reduce((curr, acc) => {
      const num = acc.gt(0) ? acc : acc.mul(-1);
      const currNum = curr.gt(0) ? curr : curr.mul(-1);
      return currNum.add(num);
    });

    const gaugesPromise = gauges.map(async (gauge, i) => {
      const yourNFTWeightForPool: BigNumber = await voter.votes(
        account,
        core._contracts[chainId][gauge.pool].address
      );

      const yourNFTWeightForPoolPercent: BigNumber =
        votingPower.value.eq(0) || yourTotalVotes.eq(0)
          ? BNZERO
          : yourNFTWeightForPool.mul(DECIMALS_18).mul(100).div(yourTotalVotes);
      const data: IVoting = {
        address: core._contracts[chainId][gauge.pool].address,
        vote: Number(getDisplayBalance(yourNFTWeightForPoolPercent, 18, 3)),
      };

      return data;
    });

    const gaugesWithMyVotes: IVoting[] = await Promise.all(gaugesPromise);

    setValue({
      isLoading: false,
      value: gaugesWithMyVotes,
    });
  }, [
    account,
    votingPower.isLoading,
    chainId,
    core,
    votingPower.value,
    gauges,
    voter,
  ]);

  useEffect(() => {
    if (core) {
      setValue(Initial_Loading);
      fetchData().catch((err) => {
        setValue(Initial_Non_Loading);
        console.error(`Failed to fetch gauge weight percentage: ${err.stack}`);
      });
    }
  }, [setValue, core, account, fetchData]);

  return value;
};

export default useGetGaugeVotePerc;
