import React from "react";
import TextWrapper from "../../components/TextWrapper";
import theme from "../../theme";
import heroImg from "../../assets/images/ReferAndEarn.png";
import Button from "../../components/Button";
import useCore from "../../hooks/useCore";
import useTokenBalance from "../../hooks/useTokenBalance";
import {useGetActiveChainId} from "../../state/chains/hooks";
import {getDisplayBalance} from "../../utils/formatBalance";
import {BigNumber} from "ethers";
import TextButton from "../../components/TextButton";

const JoiningDAO = () => {
  const core = useCore();
  const chainId = useGetActiveChainId();
  const mahaBalance = useTokenBalance(core._tokens[chainId]['MAHA']);

  return (
    <div className={'custom-container'}>
      <div>
        <div className={'single-line-center-center m-b-40'}>
          <img src={heroImg} alt={'Hero Image'}/>
        </div>
        <TextWrapper
          text={<div>
            You
            have {mahaBalance.isLoading ? '-' : `${Number(getDisplayBalance(mahaBalance.value, 18, 3)).toLocaleString()}`} MAHA
            in your connected wallet
          </div>}
          fontFamily={'Syne'}
          fontSize={32}
          align={'center'}
        />
        <TextWrapper
          text={'In order for you to join MahaDAO, you need atleast 100 MAHA'}
          Fcolor={theme.color.transparent[100]}
          fontSize={18}
          align={'center'}
          className={'m-b-24'}
        />
        <div className={'single-line-center-center m-b-32'}>
          {(!mahaBalance.isLoading && mahaBalance.value.gte(BigNumber.from(100)))
            ? (
              <div className={'m-r-12'}>
                <Button
                  text={'Lock & Stake MAHA'}
                  onClick={() => {
                    window.location.href = '/#/locker';
                  }}
                  tracking_id={'referral_lock_and_stake_maha'}
                />
              </div>
            ) : (
              <div className={'m-r-12'}>
                <Button
                  text={'Get MAHA from Uniswap'}
                  onClick={() => {
                    window.location.href = 'https://app.uniswap.org/#/swap?outputCurrency=0x745407c86df8db893011912d3ab28e68b62e49b0';
                  }}
                  tracking_id={'get_maha_from_uniswap'}
                />
              </div>
            )}
          <div className={'m-l-12'}>
            <Button
              text={'Get more MAHA'}
              onClick={() => {
                window.location.href = 'https://app.uniswap.org/#/swap?outputCurrency=0x745407c86df8db893011912d3ab28e68b62e49b0';
              }}
              tracking_id={'referral_get_more_maha'}
            />
          </div>
        </div>
        {
          <div>
            <TextButton
              text={'Guide to get MAHA'}
              onClick={() => {
                window.location.href = 'https://app.uniswap.org/#/swap?outputCurrency=0x745407c86df8db893011912d3ab28e68b62e49b0';
              }}
              align={'center'}
              tracking_id={'referral_guide_to_get_maha'}
            />
          </div>
        }
      </div>
    </div>
  )
}

export default JoiningDAO;
