import Modal from "../../../components/Modal";
import InputContainer from "../../../components/InputContainer";
import TextInput from "../../../components/TextInput";
import {useWallet} from "use-wallet";
import {useMemo, useState} from "react";
import {Grid} from "@material-ui/core";
import Button from "../../../components/Button";
import {BigNumber, ethers} from "ethers";
import {IModalProps} from "../../../utils/interface";
import useTransferNFT from "../../../hooks/callbacks/Vesting/useTransferNFT";
import useHasVoted from "../../../hooks/state/Vesting/hasVoted";
import useClearAllVotes from "../../../hooks/callbacks/Vesting/useClearAllVotes";
import useHasAttachments from "../../../hooks/state/Vesting/useHasAttachments";
import InfoTip from "../../../components/InfoTip";

interface IProps {
  nftId: BigNumber;
}

const TransferNFT = (props: IProps & IModalProps) => {
  const {account} = useWallet();
  const {onModalClose, openModal, nftId} = props;

  const [to, setTo] = useState<string>('');
  const [transfering, setTransfering] = useState<boolean>(false);
  const [clearingVotes, setClearingVotes] = useState<boolean>(false);
  const hasVoted = useHasVoted(nftId);
  const attachments = useHasAttachments(nftId);

  const transfer = useTransferNFT(
    account || '',
    to,
    nftId,
  )

  const clearVotes = useClearAllVotes(nftId);

  const handleClearVotes = () => {
    setClearingVotes(true)
    clearVotes(
      () => {
        setClearingVotes(false)
      },
      () => {
        setClearingVotes(false);
      }
    ).then(() => {
    })
  }

  const handleTranfer = () => {
    setTransfering(true)
    transfer(
      () => {
        onModalClose()
        window.location.reload();
      },
      () => {
        onModalClose()
        setTransfering(false);
      }
    ).then(() => {
    })
  }

  const isWalletAddressValid = useMemo(() => {
    if (to === '') return true;
    if (ethers.utils.isAddress(to)) return true
    else return false;
  }, [to])

  return (
    <Modal
      title={`Transfer #${nftId.toString()} NFT`}
      closeButton
      open={openModal}
      handleClose={onModalClose}
    >
      <div>
        <InputContainer label={'From'} className={'m-b-24'}>
          <TextInput value={account || ''} setValue={() => {
          }} disabled={true} border={'6px'}/>
        </InputContainer>
        <InputContainer label={'To'}>
          <TextInput value={to} setValue={setTo} placeholder={'Enter Transfer address'} border={'6px'}/>
        </InputContainer>
        <div className={'m-t-32'}>
          {!isWalletAddressValid && <InfoTip
            type={'Warning'}
            msg={`"To" should be a valid wallet address`}
            className={'m-b-12'}
          />}
          {!attachments.value.eq(0) && <InfoTip
            type={'Warning'}
            msg={`You have stake in ${attachments.value.toString()} farming pools using this NFT withdraw tokens to transfer NFT`}
            className={'m-b-12'}
          />}
          {
            <InfoTip
              type={'Warning'}
              msg={'You will be loosing the voting power, maha, mahax and rewards while transferring NFT'}
              className={'m-b-12'}
            />
          }
          {to === account &&
            <InfoTip
              type={'Error'}
              msg={`From and to cannot be same`}
              className={'m-b-12'}
            />
          }
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Button
                text={'Cancel'}
                onClick={onModalClose}
                variant={'transparent'}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              {
                !hasVoted.value.eq(0)
                  ? <Button
                    text={'Clear Votes'}
                    loading={clearingVotes || hasVoted.isLoading}
                    onClick={handleClearVotes}
                  />
                  : <Button
                    text={'Transfer'}
                    loading={transfering}
                    onClick={handleTranfer}
                    disabled={!attachments.value.eq(0) || attachments.isLoading || to === '' || to === account}
                  />
              }
            </Grid>
          </Grid>
        </div>
      </div>
    </Modal>
  )
}

export default TransferNFT;
