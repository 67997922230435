import { useWallet } from "use-wallet";
import { useCallback, useEffect, useState } from "react";
import useCore from "../../useCore";
import { Contract } from "ethers";
import { IBribeDynamicData, IGaugeStaticData } from "../../../utils/interface";
import ABIS from "../../../protocol/deployments/abi";
import { useGetActiveChainId } from "../../../state/chains/hooks";

export interface IPassingState {
  isLoading: boolean;
  value: IBribeDynamicData[];
}

export const Initial_Loading = {
  isLoading: true,
  value: [],
};

export const Initial_Non_Loading = {
  isLoading: false,
  value: [],
};

const useGetGaugeData = () => {
  const [value, setValue] = useState<IPassingState>(Initial_Loading);

  const core = useCore();
  const { account } = useWallet();
  const chainId = useGetActiveChainId();

  const gauges: IGaugeStaticData[] = core._config[chainId].gauges;
  const fetchData = useCallback(async () => {
    if (!account) {
      setValue(Initial_Non_Loading);
      return;
    }

    const gaugesPromise = gauges.map(async (gauge, i) => {
      const bribeContract = new Contract(
        core._contracts[chainId][gauge.bribe].address,
        ABIS.BaseV2Bribes,
        core.provider
      );
      const bribesRewardLength = await bribeContract.rewardsListLength();
      const bribesRewardIndexArr = Array.from(
        { length: parseInt(bribesRewardLength.toString()) },
        (v, i) => i
      );
      const bribeRewards = await Promise.all(
        bribesRewardIndexArr.map(async (index: any) => {
          const address = await bribeContract.rewards(index);
          const rewardERC20 = new Contract(address, ABIS.IERC20, core.provider);
          const balance = await rewardERC20.balanceOf(bribeContract.address);
          const symbol = await rewardERC20.symbol();
          const decimals = await rewardERC20.decimals();

          return {
            address,
            symbol,
            decimals,
            earned: balance,
          };
        })
      );

      const modifiedGauge: IBribeDynamicData = {
        id: i,
        ...gauge,
        bribeRewards,
      };

      return modifiedGauge;
    });

    const gaugesWithDynamicData: IBribeDynamicData[] = await Promise.all(
      gaugesPromise
    );

    setValue({
      isLoading: false,
      value: gaugesWithDynamicData,
    });
  }, [account, gauges, chainId, core]);

  useEffect(() => {
    if (core) {
      setValue(Initial_Loading);
      fetchData().catch((err) => {
        setValue(Initial_Non_Loading);
        console.error(`Failed to fetch gauge data: ${err.stack}`);
      });
    }
  }, [setValue, core, account, fetchData]);

  return value;
};

export default useGetGaugeData;
