import {useCallback, useEffect, useState} from "react";
import useCore from "../../useCore";

interface IAprInternal {
  min: number;
  max: number;
  boostEffectiveness: number;
}

interface IApr {
  tvlUSD: number;
  current: IAprInternal;
  upcoming: IAprInternal;
}

export interface IFarmingAPR {
  isLoading: boolean;
  data: { [key: string]: IApr };
}

const useGetv3UniApr = () => {
  const [data, setData] = useState<IFarmingAPR>({isLoading: true, data: {}});
  const core = useCore();

  const fetchV3Apr = useCallback(async () => {
    const url = `https://api.arthcoin.com/apr/gauges-lp-tokens`;
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,POST,OPTIONS',
      'Access-Control-Allow-Headers':
        'access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,content-type',
    };

    fetch(url, {headers})
      .then((res) => res.json())
      .then((res: { [data: string]: IApr }) => {
        setData({isLoading: false, data: res});
      })
      .catch((err) => {
        setData({isLoading: false, data: {}});
      });
  }, []);

  useEffect(() => {
    if (core) {
      setData({isLoading: true, data: {}});
      fetchV3Apr().catch((err) => {
        setData({isLoading: false, data: {}});
        console.error(`Failed to fetch univ3Apr Values: ${err.stack}`);
      });
    }
  }, [core, fetchV3Apr]);

  return data;
}

export default useGetv3UniApr;
