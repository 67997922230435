import {BigNumber, ethers} from "ethers";
import {useCallback} from "react";
import {useTransactionAdder} from "../../../state/transactions/hooks";

import useCore from "../../useCore";
import {useAddPopup} from "../../../state/application/hooks";
import {getDisplayBalance} from "../../../utils/formatBalance";
import formatErrorMessage from "../../../utils/formatErrorMessage";
import {useGetActiveChainId} from "../../../state/chains/hooks";

const useLockMAHAWithReferral = (amount: BigNumber, lockDuration: number, stake: boolean, referralId: string) => {
  const core = useCore();
  const addPopup = useAddPopup();
  const addTransaction = useTransactionAdder();
  const chainId = useGetActiveChainId();

  return useCallback(
    async (onSuccess: () => void, onFailure: () => void): Promise<void> => {
      try {
        const contract = core.getReferralVestingContract(chainId);
        const response = await contract.createLockWithReferral(
          amount,
          lockDuration,
          stake,
          referralId === '' ? ethers.constants.AddressZero : referralId,
        );

        // 5 * 60

        addTransaction(response, {
          summary: `Lock ${Number(
            getDisplayBalance(amount, 18, 3)
          ).toLocaleString("en-US", {minimumFractionDigits: 3})} MAHA.`,
        });

        const tx = await response.wait();

        if (tx?.status === 1) onSuccess();
        if (tx?.status !== 1) onFailure();
      } catch (e: any) {
        addPopup({
          error: {
            message: formatErrorMessage(e?.data?.message || e?.message),
            stack: e?.stack,
          },
        });
        onFailure()
      }
    },
    [core, chainId, amount, lockDuration, stake, referralId, addTransaction, addPopup]
  );
};

export default useLockMAHAWithReferral;
