import {BigNumber, Contract} from "ethers";
import {useCallback} from "react";

import ERC20 from "../../../protocol/ERC20";
import {useAddPopup} from "../../../state/application/hooks";
import {useTransactionAdder} from "../../../state/transactions/hooks";
import {getDisplayBalance} from "../../../utils/formatBalance";
import formatErrorMessage from "../../../utils/formatErrorMessage";

export default function (contract: Contract, amount: BigNumber, token: ERC20) {
  const addPopup = useAddPopup();
  const addTransaction = useTransactionAdder();

  return useCallback(
    async (onSuccess: () => void, onFailure: () => void): Promise<void> => {

      try {
        const response = await contract.deposit(amount)

        addTransaction(response, {
          summary: `Stake ${getDisplayBalance(amount, token.decimal, 3)} ${token.symbol.toUpperCase()}`,
        });

        const tx = await response.wait();

        if (tx?.status === 1) onSuccess();
        if (tx?.status !== 1) onFailure();

      } catch (e: any) {
        console.log('useDeposit error', e);
        onFailure();
        addPopup({
          error: {
            message: formatErrorMessage(e?.data?.message || e?.message),
            stack: e.stack,
          },
        });
      }
    },
    [contract, amount, addTransaction, token.decimal, token.symbol, addPopup],
  );
}
