import { BigNumber, ethers } from "ethers";
import { Grid, InputBase } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from 'react';

import LockerModal from "./LockerModal";
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import Button from '../../../components/Button';
import InfoTip from '../../../components/InfoTip';
import DataField from '../../../components/DataField';
import DatePicker from "../../../components/DatePicker";
import InputContainer from "../../../components/InputContainer";
import { BackgroundAbsolute } from '../../../components/Selector';
import CollateralDropDown from "../../../components/CollateralDropDown";

import useCore from '../../../hooks/useCore';
import useTokenBalance from '../../../hooks/useTokenBalance';
import { formatToBN, getDisplayBalance } from '../../../utils/formatBalance';
import useApprove, { ApprovalState } from '../../../hooks/callbacks/useApprove';
import {
  addMonths,
  addWeeks,
  addYears,
  DAY_IN_MS,
  DEFAULT_SINGLE_DATEINPUT_STATE, LOADING_DEFAULT_NUMBER_BASIC_STATE,
  MAXTIME_IN_MS, NON_LOADING_DEFAULT_NUMBER_BASIC_STATE, ONEYEAR_IN_MS,
  WEEK_IN_MS,
  YEAR_IN_MS
} from "../../../utils/constants";
import PredefinedDates from "../component/PredefinedDates";
import ActionButton from "../../../components/ActionButton";
import styled from "styled-components";
import theme from "../../../theme";
import { useGetActiveChainId } from "../../../state/chains/hooks";
import Checkbox from "../../../components/Checkbox";
import useGetMaxMAHAXStakingAPR from "../../../hooks/state/useGetMaxMAHAXStakingAPR";
import { truncateMiddle } from "../../../utils";
import { useWallet } from "use-wallet";

interface Iprops {
  modalOpen: boolean;
  onModalClose: () => void;
}

const VotingPowerModal = (props: Iprops) => {
  const {
    modalOpen,
    onModalClose,
  } = props;

  const [text, setText] = useState<string>('');
  const chainId = useGetActiveChainId();
  const { account } = useWallet();
  const [lockerModal, setLockerModal] = useState<boolean>(false);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [referralId, setReferralId] = useState<string>('');
  const [dateSelected, setDateSelected] = useState<any>(addYears(new Date(), 4));
  const [predefinedDate, setPredefinedDate] = useState<string>("4Y");
  const [predefinedDateValues,] = useState<string[]>(["1W", "1M", "1Y", "2Y", "3Y", "4Y", "Custom"]);
  const [stake, setStake] = useState<boolean>(true);
  const maxAPR = useGetMaxMAHAXStakingAPR();

  const handleDate = (date: any) => {
    return new Date(date.setHours(0, 0, 0, 0));
  }

  useEffect(() => {
    if (predefinedDate === "1W") {
      setDateSelected(addWeeks(new Date(), 1));
    } else if (predefinedDate === "1M") {
      setDateSelected(addMonths(new Date(), 1));
    } else if (predefinedDate === "1Y") {
      setDateSelected(addYears(new Date(), 1));
    } else if (predefinedDate === "2Y") {
      setDateSelected(addYears(new Date(), 2));
    } else if (predefinedDate === "3Y") {
      setDateSelected(addYears(new Date(), 3));
    } else if (predefinedDate === "4Y") {
      setDateSelected(addYears(new Date(), 4));
    }
  }, [predefinedDate])

  useEffect(() => {
    if (dateSelected.getTime() === addWeeks(new Date(), 1).getTime()) {
      setPredefinedDate('1W');
    } else if (dateSelected.getTime() === addMonths(new Date(), 1).getTime()) {
      setPredefinedDate('1M');
    } else if (dateSelected.getTime() === addYears(new Date(), 1).getTime()) {
      setPredefinedDate('1Y');
    } else if (dateSelected.getTime() === addYears(new Date(), 2).getTime()) {
      setPredefinedDate('2Y');
    } else if (dateSelected.getTime() === addYears(new Date(), 3).getTime()) {
      setPredefinedDate('3Y');
    } else if (dateSelected.getTime() === addYears(new Date(), 4).getTime()) {
      setPredefinedDate('4Y');
    } else {
      setPredefinedDate('Custom');
    }
  }, [dateSelected])

  const core = useCore();
  const mahaBalance = useTokenBalance(core._tokens[chainId]['MAHA']);

  const [approveStatus, approve] = useApprove(
    core._tokens[chainId]['MAHA'],
    core.getVestingContract(chainId)?.address || '',
    formatToBN(text)
  );

  const isApproved = useMemo(
    () => approveStatus === ApprovalState.APPROVED,
    [approveStatus]
  );
  const isApproving = useMemo(
    () => approveStatus === ApprovalState.PENDING,
    [approveStatus]
  );

  console.log("maha", isApproved);

  const isInputGTBalance = useMemo(() => {
    if (mahaBalance.isLoading) return false;
    const bnText: BigNumber = formatToBN(text, 18);
    return mahaBalance.value.lt(bnText);
  }, [text, mahaBalance]);

  const votingShare: string = useMemo(() => {
    if (
      text === '' ||
      dateSelected === null ||
      !text
    ) return BigNumber.from(0).toString();

    const bn: BigNumber = formatToBN(text, 18);
    if (bn.lte(0)) return BigNumber.from(0).toString();

    const timestamp = Date.parse(dateSelected);
    const timeDiff = timestamp - Date.now();
    if (timeDiff <= 0 || !timeDiff) return BigNumber.from(0).toString();

    const value = bn.mul(timeDiff).div(YEAR_IN_MS).div(4);
    return Math.ceil(Number(getDisplayBalance(value, 18, 3)))
      .toLocaleString('en-US', { minimumFractionDigits: 3 });
  }, [text, dateSelected]);

  const handleClose = () => {
    setText('');
    setDateSelected(DEFAULT_SINGLE_DATEINPUT_STATE);
    onModalClose();
  }

  const lockMAHAHandler = () => {
    setLockerModal(true);
    onModalClose();
  }

  const isLessThanWeekInMS = useMemo(() => {
    const timestamp = Date.parse(dateSelected);
    const roundedDAYTimestamp = Math.floor(Date.now() / DAY_IN_MS) * DAY_IN_MS;
    if (predefinedDate === "1W" || predefinedDate === "4Y") return false
    else return timestamp - roundedDAYTimestamp < WEEK_IN_MS;
  }, [dateSelected, predefinedDate]);

  const isGreaterThanMAXTIME = useMemo(() => {
    const timestamp = Date.parse(dateSelected);
    const roundedDAYTimestamp = Math.floor(Date.now() / DAY_IN_MS) * DAY_IN_MS;
    if (predefinedDate === "1W" || predefinedDate === "4Y") return false
    else return timestamp - roundedDAYTimestamp >= MAXTIME_IN_MS
  }, [dateSelected, predefinedDate]);

  const disableButtons = useMemo(() => {
    return isInputGTBalance ||
      mahaBalance.isLoading ||
      !Number(text) ||
      !dateSelected ||
      isLessThanWeekInMS ||
      isGreaterThanMAXTIME ||
      isApproving ||
      Number(votingShare) < 99.9
  }, [isInputGTBalance, mahaBalance.isLoading, text, dateSelected, isLessThanWeekInMS, isGreaterThanMAXTIME, isApproving, votingShare]);

  const formatDate = (data: Date) => {
    return `${data.getDate()} ${data.toDateString().split(' ')[1]}, ${data.getFullYear()}`
  }

  const apr = useMemo(() => {
    if (maxAPR.isLoading) return LOADING_DEFAULT_NUMBER_BASIC_STATE;
    else {
      const mahaAPR = maxAPR['maha'];
      const sclpAPR = maxAPR['sclp'];
      if (mahaAPR && sclpAPR && text !== '' && votingShare !== '') {
        const ratio = Number(votingShare.replace(',', '')) / Number(text);
        if (ratio) {
          return {
            isLoading: false,
            value: (ratio * mahaAPR) + (sclpAPR * ratio)
          }
        } else {
          return NON_LOADING_DEFAULT_NUMBER_BASIC_STATE
        }
      } else {
        return NON_LOADING_DEFAULT_NUMBER_BASIC_STATE;
      }
    }
  }, [maxAPR, text, votingShare]);

  useEffect(() => {
    const temp = localStorage.getItem('referredBy');
    if (temp) {
      const isValid = ethers.utils.isAddress(temp || '');
      setReferralId(isValid ? temp || '' : '');
    }
  }, []);

  const isGreaterThanOneYear = useMemo(() => {
    const timestamp = Date.parse(dateSelected);
    const roundedDAYTimestamp = Math.floor(Date.now() / DAY_IN_MS) * DAY_IN_MS;
    return timestamp - roundedDAYTimestamp >= (ONEYEAR_IN_MS - DAY_IN_MS)
  }, [dateSelected]);

  const bonusMAHA = useMemo(() => {
    if (Number(votingShare)) {
      if (isGreaterThanOneYear) {
        return (Number(votingShare) * 0.1).toString();
      } else {
        return '-'
      }
    } else {
      return '-'
    }
  }, [isGreaterThanOneYear, votingShare])

  return (
    <>
      <LockerModal
        text={text}
        votingShare={votingShare}
        dateSelected={dateSelected}
        modalOpen={lockerModal}
        onModalClose={() => setLockerModal(false)}
        stake={stake}
        referralId={referralId}
        bonusMAHA={bonusMAHA}
      />
      <Modal
        title={'Create Lock'}
        open={modalOpen}
        handleClose={() => handleClose()}
        closeButton
      >
        <div>
          {
            isInputGTBalance && (
              <InfoTip
                className={'m-b-12'}
                type={'Error'}
                msg={'Amount cannot be greater than wallet balance.'}
              />
            )
          }
          {
            (isLessThanWeekInMS || isGreaterThanMAXTIME) && (
              <InfoTip
                className={'m-b-12'}
                type={'Error'}
                msg={'Locking period has to be between 1 WEEK and 4 YEARS.'}
              />
            )
          }
          {/* {
            text !== '' && Number(text) < 100 && (
              <InfoTip
                className={'m-b-12'}
                type={'Error'}
                msg={'Locking amount should be minimum 100 MAHA.'}
              />
            )
          }*/}
          {
            text !== '' && Number(votingShare) < 99.9 && (
              <InfoTip
                className={'m-b-12'}
                type={'Error'}
                msg={'Your receiving voting power should be minimum 100 MAHAX.'}
              />
            )
          }
          <InputContainer
            label={'Enter Amount '}
            dataLabel={'Balance:'}
            dataValueLoading={mahaBalance.isLoading}
            dataValue={
              Number(getDisplayBalance(mahaBalance.value, 18, 6))
                .toLocaleString('en-US', { minimumFractionDigits: 3 })
            }
            className={'m-b-24'}
          >
            <div className="single-line-center-between">
              <Input
                value={text}
                setValue={(data) => setText(data)}
                maxTag={true}
                onMaxClick={() => {
                  setText(Number(getDisplayBalance(mahaBalance.value, 18, 18)).toString())
                }}
              />
              <CollateralDropDown
                selectedSymbol={'MAHA'}
              />
            </div>
          </InputContainer>

          <InputContainer
            label={'Choose Lock Time'}
            className={'m-b-24'}
            dataValue={
              dateSelected !== null
                ? `${formatDate(new Date())} - ${formatDate(new Date(dateSelected))}`
                : `Pick Date Range`
            }
          >
            <PredefinedDates
              selectedDate={predefinedDate}
              dateValue={predefinedDateValues}
              onDateChange={(data) => {
                setPredefinedDate(data)
              }}
              onCustomPressCallback={() => {
                setShowCalendar(true)
              }}
            />
            <div>
              {
                showCalendar &&
                <BackgroundAbsolute style={{ background: 'rgba(0, 0, 0, 0.2)' }} onClick={() => setShowCalendar(false)} />
              }
              <DatePicker
                selectedDates={dateSelected}
                openCalendar={showCalendar}
                onApplyPressCallback={(data) => {
                  setDateSelected(handleDate(data))
                  setShowCalendar(false)
                }}
                onClearPressCallback={(data) => {
                  setDateSelected(addYears(new Date(), 4))
                  setShowCalendar(false)
                }}
              />
            </div>
          </InputContainer>
          {/* <InputContainer
            label={'Referred by'}
            className={'m-b-12'}
          >
            <IFieldConatiner>
              <InputBase
                inputMode={'text'}
                placeholder={'0x0'}
                value={''}
                style={{
                  padding: '0',
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '14px',
                  color: theme.color.transparent["100"],
                  flex: 1,
                }}
                disabled={false}
                type={'string'}
                onChange={(event) => setReferralId(event.target.value)}
              />
            </IFieldConatiner>
          </InputContainer>*/}
          <div className={'m-t-24'}>
            {(referralId === '' || referralId === account)
              ? <div />
              : <DataField
                label={'Referred by'}
                labelToolTip={''}
                value={`${truncateMiddle(referralId, 12)}`}
                className={'m-b-12'}
              />}
            <DataField
              label={'Your starting voting power will be'}
              labelToolTip={'The amount of voting power you will recieve for locking MAHA.'}
              value={`~${votingShare}`}
              valueTag={'MAHAX'}
              className={'m-b-12'}
            />
            <DataField
              label={'APR'}
              labelToolTip={'This APR includes MAHA rewards and SCLP rewards'}
              isValueLoading={apr.isLoading}
              value={`${Number(apr.value) ? Number(apr.value).toLocaleString('en-US', { maximumFractionDigits: 2 }) : '-'}%`}
              valueFontWeight={600}
              valueFontColor={theme.color.primary[300]}
            />
          </div>

          <div className={'m-t-32'}>
            <div className={'p-l-4 m-b-12'}>
              <Checkbox
                checked={stake}
                handleCheck={() => {
                  setStake(!stake)
                }}
                label="Stake your MAHAX NFT"
              />
            </div>
            <InfoTip type={'Info'}
              msg={"Staking your NFT's let's you earn fees, vote on goverance and receive community benefits"}
              className={'m-b-12'} />
            {/*  {(referralId === '' || referralId === account) &&
              <InfoTip type={'Info'}
                       msg={"Locking for more than 1year would give your 10% of mahax value in maha as reward"}
                       className={'m-b-12'}/>
            }*/}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Button
                  variant={'transparent'}
                  onClick={handleClose}
                  tracking_id={"lock_MAHA_modal"}
                  tracking_params={{
                    collateral: 'MAHA',
                    action: 'cancel',
                  }}>
                  Cancel</Button>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                {
                  isApproved
                    ? stake
                      ? (
                        <ActionButton
                          onClick={lockMAHAHandler}
                          disabled={disableButtons}
                          loading={isApproving}
                          tracking_id={"lock_MAHA_&_stakeNFT"}
                          tracking_params={{
                            collateral: 'MAHA',
                            action: 'initial',
                            amount: Number(text),
                          }}
                          text={'Lock MAHA & Stake NFT'}
                        />
                      )
                      : (
                        <ActionButton
                          onClick={lockMAHAHandler}
                          disabled={disableButtons}
                          loading={isApproving}
                          tracking_id={"lock_MAHA"}
                          tracking_params={{
                            collateral: 'MAHA',
                            action: 'initial',
                            amount: Number(text),
                          }}
                          text={'Lock MAHA'}
                        />
                      )
                    : (
                      <ActionButton
                        onClick={approve}
                        loading={isApproving}
                        disabled={disableButtons}
                        tracking_id={"approve_lock_MAHA"}
                        tracking_params={{
                          collateral: 'MAHA'
                        }}
                        text={'Approve MAHA'}
                      >
                      </ActionButton>
                    )
                }
              </Grid>
            </Grid>
          </div>
          {/*<div className="m-t-24">
            <VotingPowerInfo>
              <li>
                <TextWrapper text={'1 MAHA locked for 4 years = ~1.00 MAHAX'} fontStyle={'italic'}
                             Fcolor={theme.color.transparent[100]} className={'m-b-2'}/>
              </li>
              <li>
                <TextWrapper text={'1 MAHA locked for 3 years = ~0.75 MAHAX'} fontStyle={'italic'}
                             Fcolor={theme.color.transparent[100]} className={'m-b-2'}/>
              </li>
              <li>
                <TextWrapper text={'1 MAHA locked for 2 years = ~0.50 MAHAX'} fontStyle={'italic'}
                             Fcolor={theme.color.transparent[100]} className={'m-b-2'}/>
              </li>
              <li>
                <TextWrapper text={'1 MAHA locked for 1 year = ~0.25 MAHAX'} fontStyle={'italic'}
                             Fcolor={theme.color.transparent[100]} className={'m-b-2'}/>
              </li>
            </VotingPowerInfo>
            <InfoTip
              type={'Info'}
              msg={'Lock minimum 100 MAHA and also get minimum 100 MAHAX to get access to a MAHAX NFT.'}
              className={'m-b-4'}
            />
            <InfoTip
              type={'Info'}
              msg={'Your lock time will be rounded down to week.'}
              className={'m-b-4'}
            />
            <InfoTip
              type={'Warning'}
              msg={'You need at least 2500 MAHAX to be able to create a DAO proposal.'}
              className={'m-b-12'}
            />

          </div>*/}
        </div>
      </Modal>
    </>
  )
}

export default VotingPowerModal;

const VotingPowerInfo = styled.ul`
  font-style: italic;
  list-style: none;
  border-top: 1px solid ${theme.color.transparent[300]};
  padding-top: 24px;
  margin-bottom: 24px;
`

const IFieldConatiner = styled.div`
  display: flex;
  flex-direction: row;
  background: ${theme.color.dark[500]};
  border-radius: 6px;
  max-height: 44px;
  padding: 8px 12px;
`;
