import React, {useEffect, useState} from "react";
import TextWrapper from "../../components/TextWrapper";
import theme from "../../theme";
import heroImg from "../../assets/images/ReferAndEarn.png";
import Button from "../../components/Button";
import {ethers} from "ethers";

const ReferralOnboarding = () => {
  const [referralId, setReferralId] = useState<string>('');

  useEffect(() => {
    const temp = localStorage.getItem('referredBy');
    if (temp) {
      const isValid = ethers.utils.isAddress(temp || '');
      setReferralId(isValid ? temp || '' : '');
    }
  }, []);

  return (
    <div className={'custom-container'}>
      <div>
        <TextWrapper text={'Welcome to MahaDAO'} fontSize={44} align={'center'} fontFamily={'Syne'}
                     className={'m-b-8'}/>
        <div className={'single-line-center-center m-b-40'}>
          <img src={heroImg} alt={'HeroImage'}/>
        </div>
        <TextWrapper text={`You've been invited to MahaDAO by`} fontFamily={'Syne'} fontSize={32} align={'center'}/>
        <TextWrapper text={referralId} align={'center'}
                     Fcolor={theme.color.transparent[100]} className={'m-b-40'}/>
        <TextWrapper
          text={'Stake 100+ MAHA for 4 years to join the DAO and get a chance to earn rewards'}
          Fcolor={theme.color.transparent[100]}
          fontSize={18}
          align={'center'}
          className={'m-b-24'}
        />
        <div className={'single-line-center-center'}>
          <div className={'m-r-12'}>
            <Button
              text={'Join DAO'}
              onClick={() => {
                window.location.href = '/#/referral/onboarding/join-dao';
              }}
              tracking_id={'referral_join_dao'}
            />
          </div>
          <div className={'m-l-12'}>
            <Button
              text={'Invite Other'}
              onClick={() => {
                window.location.href = '/#/referral';
              }}
              tracking_id={'referral_invite_other'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReferralOnboarding;
