import {useWallet} from "use-wallet";
import {useCallback, useEffect, useState} from "react";
import {fetch_headers} from "../../../utils/headers";
import useCore from "../../useCore";
import {BigNumber} from "ethers";
import useGetNumberOfNFTs from "./useGetNumberOfNFTs";
import {useGetActiveChainId} from "../../../state/chains/hooks";

export interface IURIData {
  name: string;
  description: string;
  image: string
}

export interface IState {
  id: BigNumber;
  lockEnds: BigNumber;
  lockAmount: BigNumber;
  lockStarts: BigNumber;
  lockValue: BigNumber;
  url: string;
}

export interface IPassingState {
  isLoading: boolean;
  value: IState[];
}

export const Initial_Loading = {
  isLoading: true,
  value: [],
}

export const Initial_Non_Loading = {
  isLoading: false,
  value: [],
}

const useGetAllNFTs = () => {
  const [value, setValue] = useState<IPassingState>(
    Initial_Loading
  );

  const core = useCore();
  const {account} = useWallet();
  const chainId = useGetActiveChainId();
  const contract = core.getVestingContract(chainId);
  const noOfNFTs = useGetNumberOfNFTs();

  const fetchData = useCallback(async () => {
    if (!account) {
      setValue(Initial_Non_Loading);
      return;
    }

    if (noOfNFTs.isLoading) {
      setValue(Initial_Loading)
    }

    const arr = Array.from({length: parseInt(noOfNFTs.value.toString())}, (v, i) => i)

    const nfts = await Promise.all(
      arr.map(async (idx) => {
        const nftId = await contract.tokenOfOwnerByIndex(account, idx)
        const locked = await contract.locked(nftId)
        const lockValue = await contract.balanceOfNFT(nftId)
        const uri = await contract.tokenURI(nftId)
        let urlImage = 'https://cdn.peopleofeden.com/generation/loading-animated.gif';
        try {
          const urlJson = await fetch(uri, {headers: fetch_headers, method: 'get'});
          const url: IURIData = await urlJson.json();
          urlImage = url.image
        } catch (e) {
          console.log('error in getting data from json', e);
        }

        return {
          id: nftId,
          lockEnds: locked[1],
          lockAmount: locked[0],
          lockStarts: locked[2],
          lockValue: lockValue,
          url: urlImage,
        }
      })
    )

    setValue({
      isLoading: false,
      value: nfts,
    })
  }, [account, contract, noOfNFTs.isLoading, noOfNFTs.value]);

  useEffect(() => {
    if (core) {
      setValue(Initial_Loading);
      fetchData().catch((err) => {
        setValue(Initial_Non_Loading);
        console.error(`Failed to fetch all NFTs: ${err.stack}`);
      });
    }
  }, [setValue, core, account, fetchData]);

  return value;
};

export default useGetAllNFTs;
