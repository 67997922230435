import React, {useState} from "react";
import IconLoader from "../../../components/IconLoader";
import {makeStyles,} from "@material-ui/core/styles";
import {getDisplayBalance} from "../../../utils/formatBalance";
import Button from "../../../components/Button";
import TextWrapper from "../../../components/TextWrapper";
import Loader from "react-spinners/BeatLoader";
import {DataGrid, GridColDef} from "@material-ui/data-grid";
import '../../../customCss/Custom-Mahadao-Data-Table.css';
import DepositModal from "../modals/DepositModal";
import useCore from "../../../hooks/useCore";
import {IGaugeStaticData, RewardTokenAndValue} from "../../../utils/interface";
import {IPassingState} from "../../../hooks/state/Bribes/useGetBribesData";
import {useGetActiveChainId} from "../../../state/chains/hooks";

export function SortedDescendingIcon() {
  return <IconLoader iconName={'ArrowTailUp'} iconType="arrow"/>;
}

export function SortedAscendingIcon() {
  return <IconLoader iconName={'ArrowTailDown'} iconType="arrow"/>;
}

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      cursor: 'pointer',
    },
    '& .super-app.negative': {
      color: '#FA4C69',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      color: '#20C974',
      fontWeight: '600',
    },
    '& .super-app.loss': {
      color: '#FA4C69',
      fontWeight: '600',
    },
    '& .super-app.profit': {
      color: '#20C974',
      fontWeight: '600',
    },
  },
  gridStyle: {
    color: 'white',
  },
});

interface IProps {
  bribesData: IPassingState;
}

const BribesTable = (props: IProps) => {
  const classes = useStyles();
  const core = useCore();
  const chainId = useGetActiveChainId();

  const [gauge, setGauge] = useState<IGaugeStaticData>({
    ...core._config[chainId].gauges[0]
  });
  const [openDepositModal, setOpenDepositModal] = useState<boolean>(false);

  const columns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Pool',
      flex: 0.4,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div className="single-line-center-start">
            <span>{params.value}</span>
          </div>
        );
      },
    },
    {
      field: 'totalBribes',
      headerName: 'Total Incentives',
      flex: 0.2,
      sortable: true,
      renderCell: (params: any) => {
        return (
          <div className={`p-t-12 p-b-12 ${params.row.bribeRewards.length <= 3 ? 'start-center' : ''}`}>
            {params.row.bribeRewards.length === 0
              ? <TextWrapper text={'-'}/>
              : params.row.bribeRewards.map((info: RewardTokenAndValue, i: number) => {
                return (
                  <div key={`${params.row.id}${i}`} className={'single-line-center-start m-b-2'}>
                    <TextWrapper
                      text={
                        Number(getDisplayBalance(info.earned, parseInt(info.decimals.toString()), 3)).toLocaleString('en-US', {minimumFractionDigits: 2})
                      }
                    />
                    <span className={'tags m-l-4'}>{info.symbol}</span>
                  </div>
                )
              })
            }
          </div>
        )
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.1,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div className={'single-line-center-start'}>
            <Button
              text={'Deposit'}
              onClick={() => {
                setGauge({
                  ...params.row
                })
                setOpenDepositModal(true)
              }}
              tracking_id={'deposit_bribe'}
              tracking_params={{
                action: 'initial',
                collateral: `${params.row.displayName}`
              }}
            />
            {/* <TextButton
              text={'View all Pool Incentives'}
              onClick={() => {}}
              className={'m-t-8'}
            /> */}
          </div>
        )
      },
    },
  ];

  const noResultsComponent = () => {
    return (
      <div className="noResultContainer">
        <div className="internal">
          <TextWrapper
            text={'No pools are live currently.'}
            fontSize={24}
            fontWeight={'bold'}
            className="m-b-8"
            align="center"
          />
          <div style={{width: '130px', margin: 'auto'}} className="m-t-24">
            <Button
              to={'/locker'}
              size={'sm'}
              tracking_id={'open_loan'}
              tracking_params={{position: 'your_loans_table'}}
              variant={'transparent'}
            >
              <IconLoader iconName={'Add'} className="m-r-4" width={16}/>
              Open a lock
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const LoadingOverlayComponent = () => {
    return (
      <div className="noResultContainer">
        <div className="internal">
          <Loader color={'#ffffff'} loading={true} size={10} margin={2}/>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root} style={{position: 'relative'}}>
      <DepositModal
        gauge={gauge}
        openModal={openDepositModal}
        onModalClose={() => setOpenDepositModal(!openDepositModal)}
      />
      <DataGrid
        className={classes.gridStyle}
        getRowId={(rows) => {
          return rows.displayName;
        }}
        rows={props.bribesData.value}
        columns={columns}
        pageSize={10}
        hideFooterPagination={false}
        // disableMultipleSelection={true}
        rowHeight={100}
        autoHeight={true}
        disableColumnMenu={true}
        onRowClick={(newSelection) => {
          // window.open(`/#/loan/details/${account}/${newSelection.row.token}`, '_self');
        }}
        loading={false}
        components={{
          LoadingOverlay: LoadingOverlayComponent,
          NoRowsOverlay: noResultsComponent,
          ColumnSortedDescendingIcon: SortedDescendingIcon,
          ColumnSortedAscendingIcon: SortedAscendingIcon,
        }}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
      />
    </div>
  )
}

export default BribesTable;
