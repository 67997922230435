import React from "react";
import TextWrapper from "../../../components/TextWrapper";
import Button from "../../../components/Button";
import theme from "../../../theme";
import DataField from "../../../components/DataField";
import TextButton from "../../../components/TextButton";

const VoteIncentives = () => {
  return (
    <div className={'material-primary'}>

      <div className={'m-b-32'}>
        <TextWrapper
          text={'Governance Voting Rewards'}
          className={'m-b-16'}
          fontSize={18}
        />
        <TextWrapper
          className={'m-b-16'}
          text={'You earn MAHA rewards whenever you or your delegate participates in the governance. All accumulated\
            rewards will be claimable here. (This feature will be coming soon)'} Fcolor={theme.color.transparent[100]}/>

        <TextWrapper
          className={'m-b-16'}
          text={<div>
            To learn more about this, read about&nbsp;
            <a href={'https://docs.mahadao.com/governance/delegation-and-voting-rewards#voting-rewards'} target="_blank"
               className={'links'} rel="noreferrer">delegation and
              governance voting rewards</a> in the documentation.
          </div>} Fcolor={theme.color.transparent[100]}/>
        <div>
          <DataField
            label={'Your Governance Voting Rewards'}
            value={'0.00 MAHA'}
            labelFontSize={16}
            labelFontColor={'white'}
            valueFontSize={16}
            className={'m-b-4'}
          />
          <div className={'single-line-center-end'}>
            <TextButton onClick={() => {
            }} text={'Claim'}
                        disabled={true}
            />
          </div>
        </div>


      </div>
      <div>
        <Button
          href="https://www.tally.xyz/governance/eip155:1:0x0fBd92eA11e23D959E1489A9Abb84ae2E4778D31"
          text={'Participate In Governance'}
          variant="transparent"
          tracking_id={'participate_in_governance'}
          onClick={() => {
          }}
        />
      </div>
    </div>
  )
}

export default VoteIncentives;
