import {useWallet} from "use-wallet";
import {useCallback, useEffect, useState} from "react";
import {
  LOADING_DEFAULT_BASIC_STATE_STRING, NON_LOADING_DEFAULT_BASIC_STATE_STRING,
} from "../../../utils/constants";
import {BasicStateString} from "../../../utils/interface";
import useCore from "../../useCore";
import {useGetActiveChainId} from "../../../state/chains/hooks";

const useGetEpochTime = () => {
  const [value, setValue] = useState<BasicStateString>(
    LOADING_DEFAULT_BASIC_STATE_STRING
  );

  const core = useCore();
  const {account} = useWallet();
  const chainId = useGetActiveChainId();

  const fetchData = useCallback(async () => {
    if (!account) {
      setValue(NON_LOADING_DEFAULT_BASIC_STATE_STRING);
      return;
    } else {
      setValue(LOADING_DEFAULT_BASIC_STATE_STRING);
      const contract = await core._contracts[chainId]['EmissionController']
      const value: number = await contract.nextEpochPoint();

      setValue({
        isLoading: false,
        value: value.toString(),
      })
    }
  }, [account, chainId, core._contracts]);

  useEffect(() => {
    if (core) {
      fetchData().catch((err) => {
        setValue(NON_LOADING_DEFAULT_BASIC_STATE_STRING);
        console.error(`Failed to fetch voting status: ${err.stack}`);
      });
    }
  }, [setValue, core, account, fetchData]);

  return value;
};

export default useGetEpochTime;
