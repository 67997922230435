import { BigNumber } from "ethers";
import { useCallback } from "react";
import { useTransactionAdder } from "../../../state/transactions/hooks";

import useCore from "../../useCore";
import { useAddPopup } from "../../../state/application/hooks";
import { getDisplayBalance } from "../../../utils/formatBalance";
import formatErrorMessage from "../../../utils/formatErrorMessage";
import { useGetActiveChainId } from "../../../state/chains/hooks";

const useLockMAHA = (
  amount: BigNumber,
  lockDuration: number,
  stake: boolean
) => {
  const core = useCore();
  const addPopup = useAddPopup();
  const addTransaction = useTransactionAdder();
  const chainId = useGetActiveChainId();

  return useCallback(
    async (onSuccess: () => void, onFailure: () => void): Promise<void> => {
      try {
        const contract = core.getVestingContract(chainId);
        const response = await contract.createLock(amount, lockDuration, stake);

        // 5 * 60

        addTransaction(response, {
          summary: `Lock ${Number(
            getDisplayBalance(amount, 18, 3)
          ).toLocaleString("en-US", { minimumFractionDigits: 3 })} MAHA.`,
        });

        const tx = await response.wait();

        if (tx?.status === 1) onSuccess();
        if (tx?.status !== 1) onFailure();
      } catch (e: any) {
        console.log(e);
        addPopup({
          error: {
            message: formatErrorMessage(e?.data?.message || e?.message),
            stack: e?.stack,
          },
        });
        onFailure();
      }
    },
    [core, amount, addPopup, lockDuration, addTransaction]
  );
};

export default useLockMAHA;
