import TextWrapper from "../../../components/TextWrapper";
import theme from "../../../theme";
import Countdown from "react-countdown";
import { GaugeRewardsStartTime } from "../index";

const CountDownDiv = () => {
  return (
    <div className={'material-primary'}>
      <TextWrapper text={'Farming Rewards Start In'} className={'m-b-20'} align={'center'} fontSize={16} />
      <Countdown
        date={GaugeRewardsStartTime}
        renderer={({ days, hours, minutes, seconds }) => {
          return (
            <div className={'single-line-baseline-center'}>
              <div className={'tags'}>
                <TextWrapper text={String(days).length === 1 ? `0${String(days)}` : String(days)} fontSize={24} />
              </div>
              &nbsp;
              <TextWrapper
                text={"Days"}
                fontSize={12}
                Fcolor={theme.color.transparent[100]}
                align={"center"}
              /> &nbsp;
              <div className={'tags'}>
                <TextWrapper text={String(hours).length === 1 ? `0${String(hours)}` : String(hours)} fontSize={24} />
              </div>
              &nbsp;
              <TextWrapper
                text={"hrs"}
                fontSize={12}
                Fcolor={theme.color.transparent[100]}
                align={"center"}
              /> &nbsp;
              <div className={'tags'}>
                <TextWrapper text={String(minutes).length === 1 ? `0${String(minutes)}` : String(minutes)}
                  fontSize={24} />
              </div>
              &nbsp;
              <TextWrapper
                text={"mins"}
                fontSize={12}
                Fcolor={theme.color.transparent[100]}
                align={"center"}
              /> &nbsp;
              <div className={'tags'}>
                <TextWrapper text={String(seconds).length === 1 ? `0${String(seconds)}` : String(seconds)}
                  fontSize={24} />
              </div>
              &nbsp;
              <TextWrapper
                text={"sec"}
                fontSize={12}
                Fcolor={theme.color.transparent[100]}
                align={"center"}
              />
            </div>
          )
        }}
      />
      <TextWrapper
        text={'In the meantime you can deposit liquidity and stake your V3 NFTs here. Your MAHA rewards will automatically accumulate when the farming starts'}
        className={'m-t-20'} align={'center'}
        Fcolor={theme.color.transparent[100]} />
    </div>
  )
}

export default CountDownDiv;
