import Modal from "../../../components/Modal";
import {IModalProps} from "../../../utils/interface";
import useGetAllNFTs from "../../../hooks/state/GaugeWeightVote/useGetAllNFTIds";
import React, {useMemo, useState} from "react";
import {BNZERO} from "../../../utils/constants";
import {BigNumber} from "ethers";
import TextWrapper from "../../../components/TextWrapper";
import Selector from "../../../components/Selector";
import {Grid} from "@material-ui/core";
import Button from "../../../components/Button";
import useMerge from "../../../hooks/callbacks/Vesting/useMerge";
import InfoTip from "../../../components/InfoTip";
import useGetNFTStaked from "../../../hooks/state/Vesting/useGetNFTStaked";

const MergeNFT = (props: IModalProps) => {
  const {openModal, onModalClose} = props;

  const [NFTSelectedTokenFrom, setNFTSelectedTokenFrom] = useState<string>('');
  const [NFTSelectedTokenTo, setNFTSelectedTokenTo] = useState<string>('');

  const [NFTDropdownModalOpenFrom, setNFTDropdownModalOpenFrom] = useState<boolean>(false);
  const [NFTDropdownModalOpenTo, setNFTDropdownModalOpenTo] = useState<boolean>(false);


  const [merging, setMerging] = useState<boolean>(false);

  const allNFTIds = useGetAllNFTs();

  const nftIdsInString = useMemo(() => {
    return Array.from(allNFTIds.value, (v, i) => `#${v.toString()}`);
  }, [allNFTIds.value]);

  const selectedNFTBNFrom = useMemo(() => {
    if (NFTSelectedTokenFrom === '') return BNZERO;
    else return BigNumber.from(NFTSelectedTokenFrom.slice(1, NFTSelectedTokenFrom.length));
  }, [NFTSelectedTokenFrom]);

  const selectedNFTBNTo = useMemo(() => {
    if (NFTSelectedTokenTo === '') return BNZERO;
    else return BigNumber.from(NFTSelectedTokenTo.slice(1, NFTSelectedTokenTo.length));
  }, [NFTSelectedTokenTo]);

  const hasStakedFrom = useGetNFTStaked(selectedNFTBNFrom);
  const hasStakedTo = useGetNFTStaked(selectedNFTBNTo);

  const merge = useMerge(
    selectedNFTBNFrom,
    selectedNFTBNTo,
  )

  const handleMerge = () => {
    setMerging(true)
    merge(
      () => {
        onModalClose()
        window.location.reload();
      },
      () => {
        onModalClose()
        setMerging(false);
      }
    ).then(() => {
    })
  }

  const isAddressSame = useMemo(() => {
    return selectedNFTBNFrom.eq(selectedNFTBNTo) && !selectedNFTBNTo.eq(BNZERO) && !selectedNFTBNFrom.eq(BNZERO)
  }, [selectedNFTBNFrom, selectedNFTBNTo])

  return (
    <Modal
      title={`Merge NFT`}
      closeButton
      open={openModal}
      handleClose={onModalClose}
    >
      <div className={'m-b-24'}>
        <TextWrapper text={'Select your first NFT'} className={'m-b-12'}/>
        <Selector
          openSelector={NFTDropdownModalOpenFrom}
          toggleSelector={() => setNFTDropdownModalOpenFrom(!NFTDropdownModalOpenFrom)}
          dropdownValue={nftIdsInString}
          selectedData={NFTSelectedTokenFrom}
          setSelectedData={(data) => setNFTSelectedTokenFrom(data)}
          width={'100%'}
          dropDownWidth={'100%'}
        />
      </div>
      <div>
        <TextWrapper text={'Select your second NFT'} className={'m-b-12'}/>
        <Selector
          openSelector={NFTDropdownModalOpenTo}
          toggleSelector={() => setNFTDropdownModalOpenTo(!NFTDropdownModalOpenTo)}
          dropdownValue={nftIdsInString}
          selectedData={NFTSelectedTokenTo}
          setSelectedData={(data) => setNFTSelectedTokenTo(data)}
          width={'100%'}
          dropDownWidth={'100%'}
        />
      </div>
      <div className={'m-t-32'}>
        {isAddressSame && <InfoTip
          type={'Error'}
          msg={'From and To cannot be same'}
          className={'m-b-12'}
        />}
        {(hasStakedFrom.value.gt(0) || hasStakedTo.value.gt(0)) && <InfoTip
          type={'Error'}
          msg={'Staked NFT cannot be merged'}
          className={'m-b-12'}
        />}
        {
          <InfoTip
            type={'Warning'}
            msg={`Claim your rewards if you have any before merging NFT's!`}
            className={'m-b-12'}
          />
        }
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Button
              text={'Cancel'}
              onClick={onModalClose}
              variant={'transparent'}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Button
              text={'Merge'}
              loading={merging}
              disabled={merging ||
                selectedNFTBNFrom.eq(selectedNFTBNTo) ||
                selectedNFTBNTo.eq(BNZERO) ||
                selectedNFTBNFrom.eq(BNZERO) ||
                hasStakedFrom.value.gt(0) ||
                hasStakedTo.value.gt(0)
              }
              onClick={handleMerge}
            />
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}

export default MergeNFT;
