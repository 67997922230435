import InfoTip from "../../../components/InfoTip";
import TextWrapper from "../../../components/TextWrapper";
import {useGetActiveChainId} from "../../../state/chains/hooks";
import config from "../../../config";
import TokenRewards from "./TokenRewards";
import theme from "../../../theme";
import React, {useMemo, useState} from "react";
import {
  BNZERO,
  LOADING_DEFAULT_NUMBER_BASIC_STATE,
  NON_LOADING_DEFAULT_NUMBER_BASIC_STATE
} from "../../../utils/constants";
import {BigNumber} from "ethers";
import Selector from "../../../components/Selector";
import styled from "styled-components";
import useGetAllStakedNFTs from "../../../hooks/state/Fees/useGetAllStakedNFTIds";
import useClaimAllFeesRewards from "../../../hooks/callbacks/Fees/useClaimAllFeesRewards";
import TextButton from "../../../components/TextButton";
import Checkbox from "../../../components/Checkbox";
import useGetMaxMAHAXStakingAPR from "../../../hooks/state/useGetMaxMAHAXStakingAPR";
import Timer from "../../GaugeWeightVote/components/Timer";
import useGetEpochTime from "../../../hooks/state/GaugeWeightVote/useGetEpochTime";

const FeesAccumlated = () => {
  const [NFTDropdownModalOpen, setNFTDropdownModalOpen] = useState<boolean>(false);
  const [NFTSelectedToken, setNFTSelectedToken] = useState<string>('');
  const [claiming, setClaiming] = useState<boolean>(false);
  const [claimAllNFT, setClaimAllNFT] = useState<boolean>(false);

  const allNFTIds = useGetAllStakedNFTs();
  const chainId = useGetActiveChainId();
  const maxAPR = useGetMaxMAHAXStakingAPR();
  const time = useGetEpochTime();

  const nftIdsInString = useMemo(() => {
    if (allNFTIds.value[0]) setNFTSelectedToken(`#${allNFTIds.value[0]}`);
    else setNFTSelectedToken('');
    return Array.from(allNFTIds.value, v => `#${v.toString()}`);
  }, [allNFTIds]);

  const selectedNFTBN = useMemo(() => {
    if (NFTSelectedToken === '') return BNZERO;
    else return BigNumber.from(NFTSelectedToken.slice(1, NFTSelectedToken.length));
  }, [NFTSelectedToken]);

  const claimAll = useClaimAllFeesRewards(claimAllNFT ? allNFTIds.value : [selectedNFTBN]);

  const handleClaimAll = () => {
    setClaiming(true);
    claimAll(
      () => {
        setClaiming(false);
        window.location.reload();
      },
      () => {
        setClaiming(false);
      }
    )
  }

  return (
    <div className={'material-primary'}>
      <div className={'m-b-24'}>
        <TextWrapper
          text={'Protocol Fees'}
          fontSize={18}
          className={'m-b-8'}
        />
        <div className={'m-b-24 single-line-center-between'}>
          <TextWrapper
            text={'Time for Next Distribution'}
            fontSize={14}
            align={'left'}
          />
          <div>
            {
              time.isLoading
                ? <TextWrapper text={'loading..'}/>
                :
                <Timer
                  date={(Math.floor(Date.now() / (86400 * 7 * 1000)) + 1) * 86400 * 7 * 1000}
                />
            }
          </div>
        </div>
        <TextWrapper
          className={'m-b-16'}
          text={'Revenue earned from the protocol is distributed to all staked NFTs here. Rewards are \
          given out in MAHA, ARTH & ETH.'}
          Fcolor={theme.color.transparent[100]}/>
        <TextWrapper
          className={'m-b-16'}
          text={<div>
            To understand sources of revenue, read about&nbsp;
            <a href={'https://docs.mahadao.com/governance/earning-fees-and-rewards'} target="_blank"
               className={'links'} rel="noreferrer">protocol fees</a> in the documentation.
          </div>} Fcolor={theme.color.transparent[100]}/>
      </div>
      <div className={'single-line-center-between m-b-24'}>
        <TextWrapper
          text={'Selected NFT'}
          fontSize={16}
        />
        <NFTSelect>
          {allNFTIds.isLoading
            ? <TextWrapper text={`Fetching your NFT's`}/>
            : allNFTIds.value.length === 0 && !allNFTIds.isLoading
              ? <TextWrapper
                text={<div>
                  No NFT's found. <a href={'/#/locker'} className={'links pointer'}> create a lock</a> to get one.
                </div>}
                align={'center'}
              />
              : <Selector
                openSelector={NFTDropdownModalOpen}
                toggleSelector={() => setNFTDropdownModalOpen(!NFTDropdownModalOpen)}
                dropdownValue={nftIdsInString}
                selectedData={NFTSelectedToken}
                setSelectedData={(data) => setNFTSelectedToken(data)}
                width={'100%'}
              />
          }
        </NFTSelect>
      </div>
      <div className={'m-b-32'}>
        {
          config[chainId].feesRewardsTokens.map((data, index) => {
            return (
              <TokenRewards token={data} key={index} nftId={selectedNFTBN} nftLoading={allNFTIds.isLoading}
                            maxAPR={maxAPR}/>
            )
          })
        }
      </div>
      <InfoTip type={'Warning'} msg={'SCLP rewards will go on max till April 13th 2023'}/>
      {/* hide this section for now */}
      {/* <ClaimAllSection>
        <div className={'single-line-center-between'}>
          <TextWrapper
            text={`Claim all rewards for ${claimAllNFT ? 'all staked NFTs' : '#' + selectedNFTBN.toString()}`}/>
          <TextButton
            text={`Claim All`}
            onClick={handleClaimAll}
            disabled={claiming}
            tracking_id={claimAllNFT ? 'claim_all_nft_fees' : 'claim_fees'}
            tracking_params={claimAllNFT ? {} : {other: {nft: selectedNFTBN.toString()}}}
          />
        </div>
        <div className={'single-line-center-between'}>
          <Checkbox
            checked={claimAllNFT}
            handleCheck={() => {
              setClaimAllNFT(!claimAllNFT)
            }}
            label="Claim rewards from all the nft's"
            size={'md'}
          />
        </div>
      </ClaimAllSection> */}
    </div>
  )
}

export default FeesAccumlated;

const NFTSelect = styled.div`
  flex: 0.4
`

const ClaimAllSection = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: ${theme.color.transparent[500]};

`
