import { useCallback, useEffect, useState } from "react";
import { useWallet } from "use-wallet";
import useCore from "../../useCore";
import { BigNumber } from "ethers";
import { BasicState } from "../../../utils/interface";
import {
  LOADING_DEFAULT_BASIC_STATE,
  NON_LOADING_DEFAULT_BASIC_STATE,
} from "../../../utils/constants";
import { useGetActiveChainId } from "../../../state/chains/hooks";

const useGetPoolRewards = (gauge: string, nftId: BigNumber) => {
  const [value, setValue] = useState<BasicState>(LOADING_DEFAULT_BASIC_STATE);

  const core = useCore();
  const chainId = useGetActiveChainId();
  const { account } = useWallet();

  const fetchData = useCallback(async () => {
    if (!account) {
      setValue(NON_LOADING_DEFAULT_BASIC_STATE);
      return;
    }

    const gaugeContract = core._contracts[chainId][gauge];
    const value = await gaugeContract.earned(nftId);

    setValue({
      isLoading: false,
      value: value,
    });
  }, [account, core, gauge, chainId, nftId]);

  useEffect(() => {
    if (core) {
      fetchData().catch((err) => {
        setValue(NON_LOADING_DEFAULT_BASIC_STATE);
        console.error(`Failed to fetch pool range rewards: ${err.stack}`);
      });
    }
  }, [setValue, core, account, fetchData]);

  return value;
};

export default useGetPoolRewards;
