import React, {useEffect, useMemo, useState} from "react";
import IconLoader from "../../../components/IconLoader";
import {createStyles, makeStyles, Theme, withStyles} from "@material-ui/core/styles";
import {formatToBN, getDisplayBalance} from "../../../utils/formatBalance";
import Button from "../../../components/Button";
import TextWrapper from "../../../components/TextWrapper";
import Loader from "react-spinners/BeatLoader";
import {DataGrid} from "@material-ui/data-grid";
import '../../../customCss/Custom-Mahadao-Data-Table.css';
import '../../../customCss/Custom-Slider.css';
import theme from "../../../theme";
import {Slider} from "@material-ui/core";
import {IGaugeDynamicData, IGaugeStaticData, RewardTokenAndValue} from "../../../utils/interface";
import {DECIMALS_18} from "../../../utils/constants";
import {BigNumber} from "ethers";
import styled from "styled-components";
import {IVoting} from "../../../hooks/state/GaugeWeightVote/useGetGaugeVotePerc";
import ConfirmCastVote from "../modal/ConfirmCastVote";
import useGetTotalMAHAX from "../../../hooks/state/Vesting/useGetTotalMAHAX";
import {useGetActiveChainId} from "../../../state/chains/hooks";
import useCore from "../../../hooks/useCore";
import {useMediaQuery} from "react-responsive";
import useGetPoolVotesAPR from "../../../hooks/state/GaugeWeightVote/useGetPoolVotesAPR";

export function SortedDescendingIcon() {
  return <IconLoader iconName={'ArrowTailUp'} iconType="arrow"/>;
}

export function SortedAscendingIcon() {
  return <IconLoader iconName={'ArrowTailDown'} iconType="arrow"/>;
}

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      cursor: 'pointer',
    },
    '& .super-app.negative': {
      color: '#FA4C69',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      color: '#20C974',
      fontWeight: '600',
    },
    '& .super-app.loss': {
      color: '#FA4C69',
      fontWeight: '600',
    },
    '& .super-app.profit': {
      color: '#20C974',
      fontWeight: '600',
    },
  },
  gridStyle: {
    color: 'white',
  },
});

const PrettoRestrictSlider = withStyles({
  root: {
    width: '100%',
  },
  thumb: {
    height: 10,
    width: 10,
    border: '2px solid currentColor',
    color: '#FFA981',
    marginTop: -3.5,
    marginLeft: -3,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: ' calc(-100% - 8px)',
    top: -20,
    fontSize: 10,
  },
  marked: {
    color: 'red',
  },
  markLabel: {},
  track: {
    height: 3,
    borderRadius: 3,
    color: '#FFA981',
  },
  rail: {
    height: 3,
    borderRadius: 3,
    color: '#D74D26',
  },
  markLabelActive: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '130%',
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.88)',
  },
  mark: {
    color: 'transparent',
  },
})(Slider);

const useSliderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '80%',
      marginLeft: '10%',
    },
    margin: {
      height: theme.spacing(3),
    },
  }),
);

export const minSlider = -100;

interface IRows extends IGaugeDynamicData {
  currentMaxApr: number;
  upcomingMaxApr: number;
}

interface IProps {
  gauges: IRows[];
  votingObj: IVoting[];
  aprLoading: boolean;
}

const VotingTable = (props: IProps) => {
  const classes = useStyles();
  const sliderClasses = useSliderStyles();

  const core = useCore();
  const chainId = useGetActiveChainId();

  const [sliderValue, setSliderValue] = useState<IVoting[]>([]);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const votingPower = useGetTotalMAHAX();
  const isMobile = useMediaQuery({maxWidth: '600px'});

  useEffect(() => {
    setSliderValue([...props.votingObj])
  }, [props.votingObj]);

  const totalVotes = useMemo(() => {
    return sliderValue.reduce((a, b) => a + Math.abs(b.vote), 0);
  }, [sliderValue])

  function valuetext(value: number) {
    return `${value.toFixed(2)}`;
  }

  const handleSliderChange = (event: any, value: any, address: string) => {
    const arr = Array.from(sliderValue, (v) => {
      if (v.address === address) return {address: address, vote: value}
      else return v
    })
    setSliderValue([...arr]);
  };

  const columns = [
    {
      field: 'displayName',
      headerName: 'Pool',
      flex: 0.3,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div className="single-line-center-start">
            <span>{params.value}</span>
          </div>
        );
      },
    },
    {
      field: 'currentMaxApr',
      headerName: 'APR',
      flex: 0.2,
      sortable: false,
      renderCell: (params: any) => {
        const currentMaxApr = params.value;
        const upcomingMaxApr = params.row.maxApr;
        console.log('apr', currentMaxApr, upcomingMaxApr);
        if (props.aprLoading) {
          return (
            <div className={'start-center'}>
              <Loader color={'#ffffff'} loading={true} size={2} margin={2}/>
            </div>
          )
        } else if (Number(currentMaxApr) && Number(upcomingMaxApr)) {
          return (
            <div className={'start-center'}>
              {
                Number(currentMaxApr).toLocaleString('en-Us', {maximumFractionDigits: 2}) +
                '%' +
                ' -> ' +
                (Number(upcomingMaxApr)).toLocaleString('en-Us', {maximumFractionDigits: 2}) +
                '%'
              }
            </div>
          );
        } else if (Number(currentMaxApr)) {
          return (
            <div className={'start-center'}>
              {
                Number(currentMaxApr).toLocaleString('en-Us', {maximumFractionDigits: 2}) +
                '%' +
                ' -> ' +
                '-' +
                '%'
              }
            </div>
          );
        } else {
          return (
            <div className={'start-center'}>
              -
            </div>
          )
        }
      },
    },
    {
      field: 'totalWeight',
      headerName: 'Overall votes',
      flex: 0.2,
      sortable: true,
      renderCell: (params: any) => {
        const percent: BigNumber = params.value.lte(0)
          ? BigNumber.from(0)
          : params.row.totalWeightForPool.mul(DECIMALS_18).mul(100).div(params.value);

        return (
          <div className={'start-center'}>
            <TextWrapper
              text={
                `${Number(getDisplayBalance(params.row.totalWeightForPool, 18, 3)).toLocaleString('en-US', {minimumFractionDigits: 2})} MAHAX`
              }
            />
            <TextWrapper
              text={
                Number(getDisplayBalance(percent, 18, 3)).toLocaleString('en-US', {minimumFractionDigits: 2}) + '%'
              }
              Fcolor={percent.lt(0) ? theme.color.red[300] : theme.color.green[300]}
            />
          </div>
        )
      },
    },
    {
      field: 'bribe',
      headerName: 'Pool Incentives',
      flex: 0.2,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div className={`p-t-12 p-b-12 ${params.row.bribeRewards.length <= 3 ? 'start-center' : ''}`}>
            {params.row.bribeRewards.length === 0
              ? <TextWrapper text={'-'}/>
              : params.row.bribeRewards.map((info: RewardTokenAndValue, i: number) => {
                return (
                  <div key={`${params.row.id}${i}`} className={'single-line-center-start m-b-2'}>
                    <TextWrapper
                      text={
                        Number(getDisplayBalance(info.earned, parseInt(info.decimals.toString()), 3)).toLocaleString('en-US', {minimumFractionDigits: 2})
                      }
                    />
                    <span className={'tags m-l-4'}>{info.symbol}</span>
                  </div>
                )
              })
            }
          </div>
        )
      },
    },
    {
      field: 'yourNFTWeightForPool',
      headerName: 'Your Vote',
      flex: 0.15,
      sortable: true,
      renderCell: (params: any) => {
        const percentage: number = sliderValue.filter(data => data.address === core._contracts[chainId][params.row.pool].address)[0]?.vote || 0;
        const value = formatToBN(percentage, 18).mul(params.row.votingPower).div(100).div(DECIMALS_18);
        return (
          <div className={'start-center'}>
            <TextWrapper
              text={
                `${Math.abs(Number(getDisplayBalance(value, 18, 3))).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })} MAHAX`
              }
            />
            <TextWrapper
              text={
                percentage.toFixed(2).toString() + '%'
              }
              Fcolor={theme.color.transparent[100]}
            />
          </div>
        )
      },
    },

    {
      field: 'vote',
      headerName: 'Vote',
      flex: 0.2,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div className={`${sliderClasses.root} start-center`}>
            <PrettoRestrictSlider
              defaultValue={minSlider}
              value={sliderValue.filter(data => data.address === core._contracts[chainId][params.row.pool].address)[0]?.vote || 0}
              getAriaValueText={valuetext}
              valueLabelFormat={valuetext}
              onChange={(data, value) => handleSliderChange(data, value, core._contracts[chainId][params.row.pool].address)}
              aria-label="slider"
              step={1}
              marks
              min={minSlider}
              max={100}
              valueLabelDisplay="on"
            />
          </div>
        )
      },
    },
  ];

  const noResultsComponent = () => {
    return (
      <div className="noResultContainer">
        <div className="internal">
          <TextWrapper
            text={'No Pools found.'}
            fontSize={24}
            fontWeight={'bold'}
            className="m-b-8"
            align="center"
          />
          <TextWrapper
            text={'There are no votable pools found'}
            fontSize={18}
            className="m-b-8"
            Fcolor={'#FFFFFF64'}
            align={'center'}
          />
        </div>
      </div>
    );
  };

  const LoadingOverlayComponent = () => {
    return (
      <div className="noResultContainer">
        <div className="internal">
          <Loader color={'#ffffff'} loading={true} size={10} margin={2}/>
        </div>
      </div>
    );
  };

  return (
    <div
      className={isMobile ? `${classes.root} m-b-80` : classes.root}
      style={{position: 'relative'}}
    >
      <ConfirmCastVote
        openModal={openConfirmModal}
        onModalClose={() => setOpenConfirmModal(false)}
        sliderValue={sliderValue}
        gauges={props.gauges}
      />
      <VotingPower>
        <div className={'single-line-center-between'}>
          <TextWrapper
            text={
              <div>
                Voting Power Used:
                <span
                  style={{color: totalVotes > 100 ? theme.color.red[300] : theme.color.green[300]}}> {totalVotes.toFixed(2)}%</span>
              </div>
            }
            fontSize={14}
            Fcolor={theme.color.transparent[100]}
            align={'right'}
          />
          <ButtonContainer>
            <Button
              onClick={() => setOpenConfirmModal(true)}
              text={'Cast Votes'}
              size={'lg'}
              loading={votingPower.isLoading}
              disabled={totalVotes > 100 || votingPower.value.lte(0)}
              tracking_id={'cast_votes'}
              tracking_params={{
                action: 'initial',
                amount: totalVotes,
              }}
            />
          </ButtonContainer>
        </div>
      </VotingPower>
      <DataGrid
        className={classes.gridStyle}
        getRowId={(rows) => rows.id}
        rows={props.gauges}
        columns={columns}
        pageSize={10}
        hideFooterPagination={false}
        // disableMultipleSelection={true}
        rowHeight={100}
        autoHeight={true}
        disableColumnMenu={true}
        onRowClick={() => {
          // window.open(`/#/loan/details/${account}/${newSelection.row.token}`, '_self');
        }}
        loading={false}
        components={{
          LoadingOverlay: LoadingOverlayComponent,
          NoRowsOverlay: noResultsComponent,
          ColumnSortedDescendingIcon: SortedDescendingIcon,
          ColumnSortedAscendingIcon: SortedAscendingIcon,
        }}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
      />
    </div>
  )
}

export default VotingTable;

const VotingPower = styled.div`
  padding: 16px;
  position: absolute;
  bottom: 5px;
  left: calc(50% - 200px);
  min-width: 400px;
  z-index: 2;
  @media (max-width: 600px) {
    width: 100vw;
    bottom: -80px;
  }
`

const ButtonContainer = styled.div`
  width: 150px;

`
