import { useWallet } from "use-wallet";
import { useCallback, useEffect, useState } from "react";
import useCore from "../../useCore";
import { BigNumber, Contract } from "ethers";
import { IGaugeDynamicData, IGaugeStaticData } from "../../../utils/interface";
import { BNZERO, DECIMALS_18 } from "../../../utils/constants";
import ABIS from "../../../protocol/deployments/abi";
import { useGetActiveChainId } from "../../../state/chains/hooks";
import useGetTotalMAHAX from "../Vesting/useGetTotalMAHAX";

export interface IPassingState {
  isLoading: boolean;
  value: IGaugeDynamicData[];
}

export const Initial_Loading = {
  isLoading: true,
  value: [],
};

export const Initial_Non_Loading = {
  isLoading: false,
  value: [],
};

const useGetGaugeData = () => {
  const [value, setValue] = useState<IPassingState>(Initial_Loading);

  const core = useCore();
  const { account } = useWallet();
  const chainId = useGetActiveChainId();

  const voter = core.getVotingContract(chainId);
  const gauges: IGaugeStaticData[] = core._config[chainId].gauges;
  const votingPower = useGetTotalMAHAX();

  const fetchData = useCallback(async () => {
    if (!account) {
      setValue(Initial_Non_Loading);
      return;
    }

    if (votingPower.isLoading) return setValue(Initial_Loading);

    const totalWeight: BigNumber = await voter.totalWeight();

    const allVotes = await Promise.all(
      gauges.map((gauge) =>
        voter.votes(account, core._contracts[chainId][gauge.pool].address)
      )
    );
    const yourTotalVotes = allVotes.reduce((curr, acc) => {
      const num = acc.gt(0) ? acc : acc.eq(0) ? 0 : acc.mul(-1);
      return curr.add(num);
    });

    const gaugesPromise = gauges.map(async (gauge, i) => {
      const totalWeightForPool: BigNumber = await voter.weights(
        core._contracts[chainId][gauge.pool].address
      );
      const yourNFTWeightForPool: BigNumber = await voter.votes(
        account,
        core._contracts[chainId][gauge.pool].address
      );
      const yourNFTWeightForPoolPercent: BigNumber =
        votingPower.value.lte(0) || yourTotalVotes.eq(0)
          ? BNZERO
          : yourNFTWeightForPool.mul(DECIMALS_18).mul(100).div(yourTotalVotes);

      const bribeContract = new Contract(
        core._contracts[chainId][gauge.bribe].address,
        ABIS.BaseV2Bribes,
        core.provider
      );
      const bribesRewardLength = await bribeContract.rewardsListLength();
      const bribesRewardIndexArr = Array.from(
        { length: parseInt(bribesRewardLength.toString()) },
        (v, i) => i
      );
      const bribeRewards = await Promise.all(
        bribesRewardIndexArr.map(async (index: any) => {
          const address = await bribeContract.rewards(index);
          const rewardERC20 = new Contract(address, ABIS.IERC20, core.provider);
          const balance = await rewardERC20.balanceOf(
            core._contracts[chainId][gauge.bribe].address
          );
          const symbol = await rewardERC20.symbol();
          const decimals = await rewardERC20.decimals();

          return {
            address,
            symbol,
            decimals,
            earned: balance,
          };
        })
      );

      const modifiedGauge: IGaugeDynamicData = {
        id: i,
        ...gauge,
        totalWeight,
        totalWeightForPool,
        yourNFTWeightForPool: votingPower.value.gt(0)
          ? yourNFTWeightForPoolPercent
              .mul(votingPower.value)
              .div(100)
              .div(DECIMALS_18)
          : BNZERO,
        yourNFTWeightForPoolPercent,
        bribeRewards,
        votingPower: votingPower.value,
      };

      return modifiedGauge;
    });

    const gaugesWithDynamicData: IGaugeDynamicData[] = await Promise.all(
      gaugesPromise
    );

    setValue({
      isLoading: false,
      value: gaugesWithDynamicData,
    });
  }, [account, gauges, core, voter, chainId, votingPower]);

  useEffect(() => {
    if (core) {
      setValue(Initial_Loading);
      fetchData().catch((err) => {
        setValue(Initial_Non_Loading);
        console.error(`Failed to fetch gauge data: ${err.stack}`);
      });
    }
  }, [setValue, core, account, fetchData]);

  return value;
};

export default useGetGaugeData;
