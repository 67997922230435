import TextWrapper from "../../components/TextWrapper";
import useGetTotalMAHALocked from "../../hooks/state/useGetTotalMAHALocked";
import {getDisplayBalance} from "../../utils/formatBalance";
import Loader from "react-spinners/BeatLoader";
import styled from "styled-components";
import theme from "../../theme";
import useGetPercOfMAHALocked from "../../hooks/state/useGetPercOfMAHALocked";
import useGetTotalVPInGov from "../../hooks/state/useGetTotalVPInGov";
import {useMemo} from "react";
import {DECIMALS_18, LOADING_DEFAULT_BASIC_STATE} from "../../utils/constants";
import {useWallet} from "use-wallet";

const GlobalData = () => {
  const totalMAHALocked = useGetTotalMAHALocked();
  const percOfCirculatingSupply = useGetPercOfMAHALocked();
  const totalVotingPower = useGetTotalVPInGov();
  const {chainId} = useWallet();

  const avgLockPeriod = useMemo(() => {
    if (totalMAHALocked.isLoading || totalVotingPower.isLoading || totalMAHALocked.value.lte(0)) {
      return LOADING_DEFAULT_BASIC_STATE
    } else {
      return {
        isLoading: false,
        value: totalVotingPower.value.mul(DECIMALS_18).mul(4).div(totalMAHALocked.value)
      }
    }
  }, [totalVotingPower, totalMAHALocked]);

  if ((totalVotingPower.value.eq(0) && !totalVotingPower.isLoading) || chainId !== 1) return <div/>

  return (
    <Card className={'single-line-center-center'}>
      <div>
        <div className={'m-b-24'}>
          <TextWrapper
            text={'Total MAHA Locked'}
            fontFamily={"Syne"}
            align={'center'}
            fontSize={18}
            Fcolor={theme.color.transparent[100]}
          />
          <div className={'single-line-center-center'}>
            {
              totalMAHALocked.isLoading
                ? <Loader color={'#ffffff'} loading={true} size={4} margin={2}/>
                : (
                  <TextWrapper
                    text={
                      Number(getDisplayBalance(totalMAHALocked.value, 18, 3)).toLocaleString('en-US', {minimumFractionDigits: 3}) +
                      ' MAHA'
                    }
                    fontSize={24}
                    fontFamily={'Syne'}
                    Fcolor={'#FFFFFF'}
                    align="center"
                  />
                )
            }
            {
              percOfCirculatingSupply.isLoading
                ? <Loader color={'#ffffff'} loading={true} size={4} margin={2}/>
                : (
                  <TextWrapper
                    text={
                      "(" + Number(getDisplayBalance(percOfCirculatingSupply.value, 18, 3)).toLocaleString('en-US', {minimumFractionDigits: 3}) +
                      ' %)'
                    }
                    fontSize={24}
                    fontFamily={'Syne'}
                    Fcolor={'#FFFFFF'}
                    align="center"
                    className={'m-l-4'}
                  />
                )
            }
          </div>
        </div>
        <div>
          <TextWrapper
            text={'Avg lock-in period'}
            fontFamily={"Syne"}
            align={'center'}
            fontSize={18}
            Fcolor={theme.color.transparent[100]}
          />
          <div className={'single-line-center-center'}>
            {
              avgLockPeriod.isLoading
                ? <Loader color={'#ffffff'} loading={true} size={4} margin={2}/>
                : (
                  <TextWrapper
                    text={
                      Number(getDisplayBalance(avgLockPeriod.value, 18, 3)).toLocaleString('en-US', {minimumFractionDigits: 3}) +
                      ' yrs'
                    }
                    fontSize={24}
                    fontFamily={'Syne'}
                    Fcolor={'#FFFFFF'}
                    align="center"
                  />
                )
            }
          </div>
        </div>
      </div>
    </Card>
  )
}

export default GlobalData;

const Card = styled.div`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  @media (max-width: 600px) {
    width: 100%;
    padding: 0 16px;
  }
`
