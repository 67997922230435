import Grid from "@material-ui/core/Grid";
import PositionsTable from "./components/PositionsTable";
import {useParams} from 'react-router-dom';
import styled from "styled-components";

import InfoBox from "./components/InfoBox";
import React, {useMemo} from "react";
import {useGetActiveChainId} from "../../state/chains/hooks";
import TextWrapper from "../../components/TextWrapper";
import useCore from "../../hooks/useCore";
import IconLoader from "../../components/IconLoader";
import TextButton from "../../components/TextButton";
import Loader from "react-spinners/BeatLoader";
import CountDownDiv from "./components/CountDownDiv";
import {useMediaQuery} from "react-responsive";
import useGetv3UniApr from "../../hooks/state/V3Staking/useGetv3UniApr";

export const GaugeRewardsStartTime = new Date("August 29, 2022 15:00:00 UTC").getTime();


const UniV3Staking = () => {
  const {poolAddress: pool} = useParams<{ poolAddress: string }>();

  const core = useCore();
  const chainId = useGetActiveChainId();
  const gauges = core.getUniswapV3Gauges(chainId);
  const aprTvlData = useGetv3UniApr();
  const isMobile = useMediaQuery({maxWidth: '600px'});

  const poolData = useMemo(() => {
    const staticPoolsData = gauges.filter(data => data.pool === pool);
    return staticPoolsData[0] ? staticPoolsData[0] : null;
  }, [gauges, pool])

  /*const calcAPR = useMemo(() => {
    if (poolData) return apr.data[core._contracts[chainId][poolData.gauge].address];
    else return null
  }, [apr.data, chainId, core._contracts, poolData])*/

  const dataWithApr = useMemo(() => {
    if (poolData) {
      var minApr = 0;
      var maxApr = 0;
      if (!aprTvlData.isLoading && aprTvlData.data[poolData.apiKey]) {
        minApr = aprTvlData.data[poolData.apiKey].current.min;
        maxApr = aprTvlData.data[poolData.apiKey].current.max;
      }
      return {minApr: minApr, maxApr: maxApr}
    } else {
      return {minApr: 0, maxApr: 0}
    }
  }, [aprTvlData, poolData])

  const AprComponent = () => {
    const minApr = dataWithApr.minApr;
    const maxApr = dataWithApr.maxApr;
    if (aprTvlData.isLoading) {
      return (
        <div className={'start-center'}>
          <Loader color={'#ffffff'} loading={true} size={2} margin={2}/>
        </div>
      )
    }
    if (Number(minApr) && Number(maxApr)) {
      return (
        <div className={'start-center links'}>
          {
            Number(minApr).toLocaleString('en-Us', {maximumFractionDigits: 2}) +
            '%' +
            ' - ' +
            (Number(maxApr)).toLocaleString('en-Us', {maximumFractionDigits: 2}) +
            '%'
          }
        </div>
      );
    } else if (Number(minApr)) {
      return (
        <div className={'start-center links'}>
          {
            Number(minApr).toLocaleString('en-Us', {maximumFractionDigits: 2}) + '%'
          }
        </div>
      );
    } else if (Number(maxApr)) {
      return (
        <div className={'start-center links'}>
          {
            Number(maxApr).toLocaleString('en-Us', {maximumFractionDigits: 2}) + '%'
          }
        </div>
      );
    } else {
      return (
        <div className={'start-center links'}>
          -
        </div>
      )
    }
  }

  return (
    <Table className={'custom-container'}>
      <Header className="pointer single-line-center-start mo-custom-container m-b-40"
              onClick={() => window.location.href = "/#/staking"}>
        <IconLoader iconName={"ArrowTailLeft"} iconType={"arrow"} className="m-r-8"/>
        <TextWrapper text={"Back to v3 gauges"}/>
      </Header>
      <Grid container spacing={2}>
        <Grid item lg={7} md={6} sm={12} xs={12}>
          {poolData !== null && <div className={'m-b-32  mo-custom-container'}>
            <TextWrapper text={`Uniswap Pool: ${poolData.displayName}`} fontFamily={'Syne'} fontSize={24}/>
            <div className={'single-line-center-start m-b-12'}>
              <TextButton
                onClick={() => window.open(`${poolData.liquidityLink}`)}
                text={`Add Liquidity`}
                fontSize={12}
                tracking_id={'add_v3liquidity'}
                tracking_params={{
                  collateral: `${poolData.displayName}`
                }}
              />
              <IconLoader iconName={'ArrowLinkColored'} iconType={'arrow'} width={16} height={16}/>
            </div>
            <div className={'single-line-center-start'}>
              <TextWrapper text={`APR:`} fontFamily={'Syne'} fontSize={14} className={'m-r-8'}/>
              {
                aprTvlData.isLoading
                  ? <Loader color={'#ffffff'} loading={true} size={2} margin={2}/>
                  : <AprComponent/>
              }
            </div>
          </div>}
          {GaugeRewardsStartTime > new Date().getTime() && isMobile && <div className={'m-b-24'}>
            <CountDownDiv/>
          </div>}
          {poolData !== null
            ? <PositionsTable pool={pool} apr={dataWithApr}/>
            : <TextWrapper text={"Oops! No such pool found in our system"} align={'center'}/>}
        </Grid>
        <Grid item lg={5} md={6} sm={12} xs={12}>
          {GaugeRewardsStartTime > new Date().getTime() && !isMobile && <div className={'m-b-24'}>
            <CountDownDiv/>
          </div>}
          <InfoBox/>
        </Grid>
      </Grid>
    </Table>
  )
}

export default UniV3Staking;

const Table = styled.div`
`

const Header = styled.div`
  opacity: 80%;
`;
