import {useCallback, useEffect, useState} from "react";
import {useWallet} from "use-wallet";
import useCore from "../../useCore";
import {useGetActiveChainId} from "../../../state/chains/hooks";
import {BigNumber, Contract} from "ethers";
import ABIS from "../../../protocol/deployments/abi";
import {IGaugeStaticData} from "../../../utils/interface";

export interface IV3PositionData extends IGaugeStaticData {
  nftId: BigNumber;
  poolName: string;
  liquidity: BigNumber;
  fee: BigNumber;
  token0: {
    address: string;
    symbol: string;
    decimal: number;
  };
  token1: {
    address: string;
    symbol: string;
    decimal: number;
  };
  tickLower: number;
  tickUpper: number;
  tokenUri: string;
  isStaked: boolean;
}

export interface IState {
  isLoading: boolean;
  data: IV3PositionData[];
}

export const LOADING_STATE: IState = {
  isLoading: true,
  data: [],
};

export const NON_LOADING_STATE: IState = {
  isLoading: false,
  data: [],
};

const useGetV3PositionData = (pool: string) => {
  const [value, setValue] = useState<IState>(LOADING_STATE);

  const core = useCore();
  const {account} = useWallet();
  const chainId = useGetActiveChainId();

  const fetchData = useCallback(async () => {
    const gauges = core.getUniswapV3Gauges(chainId);
    const managerContract = core.getUniswapNFTManager(chainId);
    const staticPoolsData = gauges.filter((data) => data.pool === pool);
    const staticPool = staticPoolsData[0];

    const poolAddress = core._contracts[chainId][pool].address;
    const noOfPosition = await managerContract.balanceOf(account);
    const factory = await managerContract.factory();
    const v3FactoryContract = new Contract(
      factory,
      ABIS.IUniswapV3Factory,
      core.provider
    );

    console.log('fetch V3 position Data', parseInt(noOfPosition.toString()));

    const arr = Array.from(
      {length: parseInt(noOfPosition.toString())},
      (v, i) => i
    );

    const nftsData = await Promise.all(
      arr.map(async (idx) => {
        const nftId = await managerContract.tokenOfOwnerByIndex(account, idx);
        const position = await managerContract.positions(nftId);
        const tokenUri = await managerContract.tokenURI(nftId);

        const fetchedPoolAddress = await v3FactoryContract.getPool(
          position.token0,
          position.token1,
          position.fee
        );

        if (fetchedPoolAddress.toLowerCase() !== poolAddress.toLowerCase())
          return null;

        if (position.liquidity.eq(0))
          return null;

        const token0ERC20 = new Contract(
          position.token0,
          ABIS.IERC20,
          core.provider
        );
        const token1ERC20 = new Contract(
          position.token1,
          ABIS.IERC20,
          core.provider
        );

        const [token0Symbol, token1Symbol, token0Decimals, token1Decimals] =
          await Promise.all([
            token0ERC20.symbol(),
            token1ERC20.symbol(),
            token0ERC20.decimals(),
            token1ERC20.decimals(),
          ]);

        return {
          ...staticPool,
          nftId: nftId,
          poolName: `${token0Symbol}/${token1Symbol}`,
          tokenUri: tokenUri,
          isStaked: false,
          liquidity: position.liquidity,
          fee: position.fee,
          token0: {
            address: position.token0,
            symbol: token0Symbol,
            decimal: token0Decimals,
          },
          token1: {
            address: position.token1,
            symbol: token1Symbol,
            decimal: token1Decimals,
          },
          tickLower: position.tickLower,
          tickUpper: position.tickUpper,
        };
      })
    );

    // @ts-ignore
    const filteredData: IV3PositionData[] = nftsData.filter(
      (data) => data !== null
    );


    setValue({
      isLoading: false,
      data: filteredData,
    });
  }, [account, chainId, core, pool]);

  useEffect(() => {
    if (core && account) {
      fetchData().catch((err) => {
        setValue(NON_LOADING_STATE);
        console.error(`Failed to fetch V3 position Datas: ${err.stack}`);
      });
    }
  }, [setValue, core, account, fetchData]);

  return value;
};

export default useGetV3PositionData;
