import React, {useMemo, useState} from "react";
import DataField from "../../../components/DataField";
import {getDisplayBalance} from "../../../utils/formatBalance";
import TextButton from "../../../components/TextButton";
import TextWrapper from "../../../components/TextWrapper";
import theme from "../../../theme";
import {BigNumber} from "ethers";
import useClaimFeesRewards from "../../../hooks/callbacks/Fees/useClaimFeesRewards";
import useGetFeesRewards from "../../../hooks/state/Fees/useGetFeesRewards";
import Loader from "react-spinners/BeatLoader";
import {State} from "../../../hooks/state/useGetMaxMAHAXStakingAPR";
import {
  BNZERO,
  LOADING_DEFAULT_NUMBER_BASIC_STATE,
  NON_LOADING_DEFAULT_NUMBER_BASIC_STATE
} from "../../../utils/constants";
import useGetNFTDetails from "../../../hooks/state/Vesting/useGetNFTDetails";

interface IProps {
  token: string;
  nftId: BigNumber;
  nftLoading: boolean;
  maxAPR: State;
}

const TokenRewards = (props: IProps) => {
  const {token, nftId, maxAPR, nftLoading} = props;
  const [claiming, setClaiming] = useState<boolean>(false);

  const claim = useClaimFeesRewards(token, nftId);
  const reward = useGetFeesRewards(token, nftId, nftLoading);
  const nftDetails = useGetNFTDetails(nftId);

  const apr = useMemo(() => {
    if (maxAPR.isLoading || nftDetails.isLoading) return LOADING_DEFAULT_NUMBER_BASIC_STATE;
    else {
      // @ts-ignore
      const mAPR = maxAPR[token.toLowerCase()];
      if (mAPR && nftDetails.value) {
        const ratio = Number(getDisplayBalance(nftDetails.value.lockValue)) / Number(getDisplayBalance(nftDetails.value.lockAmount));
        if (ratio) {
          return {
            isLoading: false,
            value: ratio * mAPR
          }
        } else {
          return NON_LOADING_DEFAULT_NUMBER_BASIC_STATE
        }
      } else {
        return NON_LOADING_DEFAULT_NUMBER_BASIC_STATE;
      }
    }
  }, [maxAPR, nftDetails.isLoading, nftDetails.value, token])

  const handleClaim = () => {
    setClaiming(true);
    claim(
      () => {
        setClaiming(false);
        window.location.reload();
      },
      () => {
        setClaiming(false);
      }
    )
  }

  return (
    <div className={'m-b-16'}>
      <DataField
        label={`${token}`}
        isValueLoading={reward.isLoading || nftLoading}
        value={`${Number(getDisplayBalance(nftId.eq(0) ? BNZERO : reward.value, 18, 18)).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 6
        })} ${token}`}
        labelFontSize={16}
        labelFontColor={'white'}
        valueFontSize={16}
        valueFontColor={"#FFF"}
        className={'m-b-2'}
      />
      <div className={'single-line-center-between'}>
        <div className={'single-line-center-start'}>
          {nftId.eq(0) || (!apr.isLoading && apr.value === 0)
            ? <div/>
            : <TextWrapper
              text={<div>
                Earning {token} at an APR of
                {
                  apr.isLoading
                    ? <Loader color={'#ffffff'} loading={true} size={2} margin={2}/>
                    : <span className={'links'}> {Number(apr.value).toLocaleString('en-US', {maximumFractionDigits: 2})}%
                  </span>
                }
              </div>}
              Fcolor={theme.color.transparent[100]}
            />}
        </div>
        {!reward.isLoading && !claiming
          ? <TextButton
            onClick={() => {
              handleClaim()
            }}
            text={'Claim'}
            tracking_id={'claim_protocol_fees'}
            tracking_params={{
              collateral: token,
              other: {nftId: nftId.toString()}
            }}
            disabled={reward.value.eq(0) || nftId.eq(0)}
            fontWeight={600}
          />
          : claiming
            ? <TextWrapper text={'Claiming'} Fcolor={theme.color.transparent[100]}/>
            : <Loader color={'#ffffff'} loading={true} size={2} margin={2}/>
        }
      </div>
    </div>
  )
}

export default TokenRewards;
