import {useCallback, useEffect, useMemo, useState} from "react";
import useCore from "../../useCore";
import useGetlpTokenApr from "../Staking/useGetlpTokenApr";
import useGetv3UniApr from "../V3Staking/useGetv3UniApr";

interface IAprInternal {
  min: number;
  max: number;
  boostEffectiveness: number;
}

interface IApr {
  tvlUSD: number;
  current: IAprInternal;
  upcoming: IAprInternal;
}

export interface IFarmingAPR {
  isLoading: boolean;
  data: { [key: string]: IApr };
}

const useGetStakingApr = () => {
  const v3Apr = useGetv3UniApr();
  const lpApr = useGetlpTokenApr();

  const data = useMemo(() => {
    if (v3Apr.isLoading || lpApr.isLoading) return {isLoading: true, data: {}}
    else {
      return {
        isLoading: false,
        data: {
          ...lpApr.data,
          ...v3Apr.data,
        }
      }
    }
  }, [v3Apr, lpApr])

  return data;
}

export default useGetStakingApr;
