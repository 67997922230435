import React, {useState} from "react";
import DataField from "../../../components/DataField";
import {IGaugeStaticData} from "../../../utils/interface";
import useGetStakingData from "../../../hooks/state/Staking/useGetStakingData";
import {formatToBN, getDisplayBalance} from "../../../utils/formatBalance";
import TextWrapper from "../../../components/TextWrapper";
import theme from "../../../theme";
import Button from "../../../components/Button";
import useWithdraw from "../../../hooks/callbacks/Staking/useWithdraw";
import useClaim from "../../../hooks/callbacks/Staking/useClaim";
import useCore from "../../../hooks/useCore";
import {useGetActiveChainId} from "../../../state/chains/hooks";

interface IProps {
  poolDetails: IGaugeStaticData;
}

const PositionInfo = (props: IProps) => {
  const {poolDetails} = props;
  const core = useCore();
  const chainId = useGetActiveChainId();
  const info = useGetStakingData(poolDetails);
  const [claiming, setClaiming] = useState<boolean>(false);

  const gaugeContract = core._contracts[chainId][poolDetails.gauge];

  const ClaimAction = useClaim(
    gaugeContract,
    info?.value?.gaugeRewards.map(data => data.address) || [],
  );

  const handleClaim = () => {
    setClaiming(true)
    ClaimAction(
      () => {
        setClaiming(false)
        window.location.reload();
      },
      () => {
      },
    ).then(r => {
    });
  };

  console.log('data',);

  return (
    <div className={'material-primary m-b-24'}>
      {
        info.isLoading
          ? <TextWrapper text={'Fetching your staking info...'} align={'center'}/>
          : info.value === null
            ? <TextWrapper text={'You do not have lpTokens staked in this pool'} align={'center'}/>
            : <div>
              <DataField
                label={'Your locked state'}
                value={Number(getDisplayBalance(info.value.stakingAmount, 18, 6))
                  .toLocaleString('en-US', {minimumFractionDigits: 3}) + ' ' + poolDetails.depositToken}
                isValueLoading={info.isLoading}
                valueFontSize={16}
                valueFontWeight={600}
                labelFontSize={16}
                className={'m-b-16'}
              />
              <div className={'single-line-center-between'}>
                <TextWrapper text={'Unclaimed rewards'} fontSize={16} Fcolor={theme.color.transparent[100]}/>
                <div>
                  {
                    info.value.gaugeRewards.length <= 0
                      ? <TextWrapper
                        text={'-'}
                        fontWeight={600}
                        fontSize={16}
                      />
                      : info.value.gaugeRewards.map((data, index) => {
                        return (
                          <TextWrapper
                            text={<span>
                            {Number(getDisplayBalance(data.earned, data.decimals, 6))
                              .toLocaleString('en-US', {minimumFractionDigits: 3})} &nbsp;
                              {data.symbol.toUpperCase()}
                          </span>}
                            fontWeight={600}
                            fontSize={16}
                          />
                        )
                      })
                  }
                </div>
              </div>
              <div className={'m-t-32'}>
                <Button
                  text={'Claim rewards'}
                  disabled={info?.value?.gaugeRewards.map(data => data.address).length === 0}
                  loading={claiming}
                  onClick={handleClaim}
                />
              </div>
            </div>
      }
    </div>
  )
}

export default PositionInfo;
