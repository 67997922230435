import { useWallet } from "use-wallet";
import { useCallback, useEffect, useState } from "react";
import {
  LOADING_DEFAULT_BASIC_STATE,
  NON_LOADING_DEFAULT_BASIC_STATE,
} from "../../utils/constants";
import { BasicState } from "../../utils/interface";
import useCore from "../useCore";
import { useGetActiveChainId } from "../../state/chains/hooks";
import useTokenBalanceOf from "../useTokenBalanceOf";
import { BigNumber } from "ethers";
import { formatToBN } from "../../utils/formatBalance";

const useGetTotalMAHALocked = () => {
  const [value, setValue] = useState<BasicState>(LOADING_DEFAULT_BASIC_STATE);

  const core = useCore();
  const chainId = useGetActiveChainId();
  const contract = core.getVestingContract(chainId);

  const fetchData = useCallback(async () => {
    const value: BigNumber = await contract.totalSupplyWithoutDecay();
    const passingValue = value;
    setValue({
      isLoading: false,
      value: passingValue,
    });
  }, [contract]);

  useEffect(() => {
    if (core) {
      fetchData().catch((err) => {
        setValue(NON_LOADING_DEFAULT_BASIC_STATE);
        console.error(`Failed to fetch total maha locked status: ${err.stack}`);
      });
    }
  }, [setValue, core, fetchData]);

  return value;
};

export default useGetTotalMAHALocked;
