import {useCallback} from "react";
import {useAddPopup} from "../../../state/application/hooks";
import {useTransactionAdder} from "../../../state/transactions/hooks";

import useCore from "../../useCore";
import formatErrorMessage from "../../../utils/formatErrorMessage";
import {BigNumber} from "ethers";
import {useGetActiveChainId} from "../../../state/chains/hooks";

const useWithdrawMAHA = (nftId: BigNumber | null) => {
  const core = useCore();
  const addPopup = useAddPopup();
  const addTransaction = useTransactionAdder();
  const chainId = useGetActiveChainId();

  return useCallback(
    async (onSuccess: () => void, onFailure: () => void): Promise<void> => {
      if (nftId) {
        try {
          const contract = core.getVestingContract(chainId);

          const response = await contract.withdraw(nftId);

          addTransaction(response, {
            summary: `Withdraw MAHA.`,
          });

          const tx = await response.wait();

          if (tx?.status === 1) onSuccess();
          if (tx?.status !== 1) onFailure();
        } catch (e: any) {
          addPopup({
            error: {
              message: formatErrorMessage(e?.data?.message || e?.message),
              stack: e?.stack,
            },
          });
        }
      }
    },
    [core, nftId, addTransaction, addPopup]
  );
};

export default useWithdrawMAHA;
