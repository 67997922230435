import TextWrapper from "../../../components/TextWrapper";
import theme from "../../../theme";
import React, { useMemo, useState } from "react";
import { IGaugeStaticData } from "../../../utils/interface";
import useGetStakingData from "../../../hooks/state/Staking/useGetStakingData";
import { formatToBN, getDisplayBalance } from "../../../utils/formatBalance";
import States from "../../../components/States";
import Input from "../../../components/Input";
import CollateralDropDown from "../../../components/CollateralDropDown";
import InputContainer from "../../../components/InputContainer";
import useCore from "../../../hooks/useCore";
import { useGetActiveChainId } from "../../../state/chains/hooks";
import Button from "../../../components/Button";
import useGaugeWithdraw from "../../../hooks/callbacks/Farming/useGaugeWithdraw";
import useWithdraw from "../../../hooks/callbacks/Staking/useWithdraw";

interface IProps {
  poolDetails: IGaugeStaticData;
}

const Withdraw = (props: IProps) => {
  const { poolDetails } = props;
  const info = useGetStakingData(poolDetails);
  const [val, setValue] = useState<string>('0');
  const core = useCore();
  const chainId = useGetActiveChainId();
  const [withdrawing, setWithdrawing] = useState<boolean>(false);

  const gaugeContract = core._contracts[chainId][poolDetails.gauge];

  const WithdrawAction = useWithdraw(
    gaugeContract,
    formatToBN(val, 18),
    poolDetails.displayName
  );

  const isAmountGreaterThanBalance = useMemo(() => {
    const bn = formatToBN(val, 18);
    if (info.value === null) return true;
    return bn.gt(info.value?.stakingAmount);
  }, [val, info.value]);

  // if (info.isLoading) return <div/>;
  // if (!info.isLoading && info.value === null) return <div/>
  // if (info.value === null) return <div/>;

  const handleWithdraw = () => {
    setWithdrawing(true)
    WithdrawAction(
      () => {
        setWithdrawing(false)
        window.location.reload();
      },
      () => {
      },
    ).then(r => {
    });
  };

  return (
    <div className={'m-b-24'}>
      <div className={'m-b-12'}>
        <TextWrapper
          text={'Withdraw'}
          fontFamily={'Syne'}
          fontSize={18}
          fontWeight={600}
        />
        <TextWrapper
          text={'Withdraw your LP tokens and claim trading fees.'}
          fontSize={14}
          Fcolor={theme.color.transparent[100]}
        />
      </div>
      {
        info.isLoading
          ? <TextWrapper text={'Fetching staking amount avaibale for withdrawal...'} align={'center'} />
          : info.value === null
            ? <TextWrapper text={<p>Oops! Something went wrong while fetching your data &#128533;</p>} align={'center'} />
            : <div>
              <InputContainer
                label={'Amount available for withdrawal'}
                dataLabel={''}
                dataValue={`${Number(getDisplayBalance(info.value.stakingAmount, 18, 3)).toLocaleString()} ${poolDetails.depositToken}`}
                className={'m-b-12'}
              >
                <States
                  state={isAmountGreaterThanBalance ? 'error' : 'default'}
                  msg={'Amount cannot be greater than your staked mount'}
                >
                  <div className={'single-line-center-between'}>
                    <Input
                      value={val}
                      setValue={setValue}
                      maxTag={true}
                      onMaxClick={() => {
                        if (info.value !== null) setValue(Number(getDisplayBalance(info.value.stakingAmount, 18, 3)).toString())
                      }}
                    />
                    <CollateralDropDown selectedSymbol={poolDetails.depositToken} />
                  </div>
                </States>
              </InputContainer>
              <Button
                disabled={!Number(val) || withdrawing}
                text={'Withdraw'}
                loading={withdrawing}
                size={'lg'}
                onClick={handleWithdraw}
                tracking_id={'stake_withdraw'}
                tracking_params={{
                  action: 'confirm',
                  collateral: '',
                  amount: Number(val),
                }}
              />
            </div>
      }
    </div>
  )
}

export default Withdraw;
