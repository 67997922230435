import SearchBar from "../../../components/SearchBar";
import TextWrapper from "../../../components/TextWrapper";
import theme from "../../../theme";
import VotingTable from "./VotingTable";
import React, {useMemo, useState} from "react";
import styled from "styled-components";
import LoadingPage from "../../../components/LoadingPage";


import useGetGaugeData from "../../../hooks/state/GaugeWeightVote/useGetGaugeData";
import {getDisplayBalance} from "../../../utils/formatBalance";
import useGetGaugeVotePerc from "../../../hooks/state/GaugeWeightVote/useGetGaugeVotePerc";
import useGetTotalMAHAX from "../../../hooks/state/Vesting/useGetTotalMAHAX";
import Timer from "./Timer";
import {addDays} from "date-fns";
import useGetEpochTime from "../../../hooks/state/GaugeWeightVote/useGetEpochTime";
import {useMediaQuery} from "react-responsive";
import useGetStakingApr from "../../../hooks/state/Farming/useGetStakingApr";

const VotingTableWithFilters = () => {
  const gauges = useGetGaugeData();
  const votingPower = useGetTotalMAHAX();
  const aprTvlData = useGetStakingApr();

  const votingArrayObj = useGetGaugeVotePerc();
  const time = useGetEpochTime();

  const isMobile = useMediaQuery({maxWidth: '600px'});

  const NoResultsComponent = () => {
    return (
      <div>
        <div className="internal">
          <TextWrapper
            text={'No Pools Found'}
            fontSize={24}
            fontWeight={'bold'}
            className="m-b-2"
            align="center"
            fontFamily={'Syne'}
          />
          <TextWrapper
            text={'There are no votable pools found'}
            fontSize={18}
            className="m-b-24"
            Fcolor={'#FFFFFF64'}
            align={'center'}
          />
        </div>
      </div>
    );
  };

  const dataWithApr = useMemo(() => {
    return gauges.value.map(data => {
      var currentMaxApr = 0;
      var upcomingMaxApr = 0;
      if (!aprTvlData.isLoading && aprTvlData.data[data.apiKey]) {
        currentMaxApr = aprTvlData.data[data.apiKey].current.max;
        upcomingMaxApr = aprTvlData.data[data.apiKey].upcoming.max;
      }
      return {...data, currentMaxApr: currentMaxApr, upcomingMaxApr: upcomingMaxApr}
    })

  }, [aprTvlData, gauges])

  return (
    <div>
      <section className={'m-b-20'}>
        <StickyContain>
          <div className={'single-line-center-between m-b-8 mo-single-line-column'}>
            <div>
              <TextWrapper
                text={'Time for Next Distribution'}
                fontSize={14}
                align={'left'}
                className={'m-b-8'}
              />
              {
                time.isLoading
                  ? <TextWrapper text={'loading..'}/>
                  :
                  <Timer
                    date={new Date(Number(time.value) * 1000).getTime()}
                  />
              }

            </div>
            <div
              className={isMobile ? 'm-t-12' : ''}
            >
              <TextWrapper
                text={'Your Voting Power is'}
                fontSize={14}
                Fcolor={theme.color.transparent[100]}
                align={isMobile ? "left" : 'right'}
              />
              <TextWrapper
                text={
                  votingPower.isLoading
                    ? 'loading...'
                    : `${Number(getDisplayBalance(votingPower.value, 18, 3),).toLocaleString() + ' MAHAX'}`
                }
                fontSize={14}
                Fcolor={theme.color.primary[300]}
                align={isMobile ? "left" : 'right'}
              />
            </div>
          </div>
        </StickyContain>
      </section>
      <section>
        {
          gauges.isLoading
            ? <TableSection><LoadingPage/></TableSection>
            : !gauges.isLoading && gauges.value.length > 0
              ? <VotingTable
                gauges={dataWithApr}
                votingObj={votingArrayObj.value}
                aprLoading={aprTvlData.isLoading}
              />
              : <NoResultsComponent/>
        }
      </section>
    </div>
  )
}

export default VotingTableWithFilters;

const StickyContain = styled.div`
  @media (max-width: 600px) {
    padding: 0 16px;
  }
`

const TableSection = styled.div`
  min-height: 200px;
`

const Search = styled.div`
  flex: 0.7
`

const NFTSelect = styled.div`
  flex: 0.2
`
