import {BigNumber} from "ethers";
import {useCallback} from "react";
import {useTransactionAdder} from "../../../state/transactions/hooks";

import useCore from "../../useCore";
import {useAddPopup} from "../../../state/application/hooks";
import formatErrorMessage from "../../../utils/formatErrorMessage";
import {useGetActiveChainId} from "../../../state/chains/hooks";

const useTransferNFT = (from: string, to: string, tokenId: BigNumber) => {
  const core = useCore();
  const addPopup = useAddPopup();
  const addTransaction = useTransactionAdder();
  const chainId = useGetActiveChainId();

  return useCallback(
    async (onSuccess: () => void, onFailure: () => void): Promise<void> => {
      try {
        const contract = core.getVestingContract(chainId);
        const response = await contract.transferFrom(
          from,
          to,
          tokenId
        );

        const tx = await response.wait();

        addTransaction(response, {
          summary: `Transferred #${tokenId.toString()} from ${from} to ${to}`,
        });

        if (tx?.status === 1) onSuccess();
        if (tx?.status !== 1) onFailure();
      } catch (e: any) {
        console.log("useTransfer error", e);
        addPopup({
          error: {
            message: formatErrorMessage(e?.data?.message || e?.message),
            stack: e?.stack,
          },
        });
        onFailure()
      }
    },
    [core, chainId, tokenId, from, to, addTransaction, addPopup]
  );
};

export default useTransferNFT;
