import React, {useState} from "react";
import {useLocation} from "react-router-dom";

import PageHeader from "../../components/PageHeader";
import TextWrapper from "../../components/TextWrapper";
import VotingPowerModal from './modal/VotingPowerModal';
import LockingDetailsCard from "./component/LockingDetailsCard";
import Button from "../../components/Button";
import styled from "styled-components";
import useGetAllNFTs, {IState} from "../../hooks/state/Vesting/useGetAllNFTs";
import LockMoreMAHA from "./modal/LockMoreMAHA";
import ExtendLockInPeriod from "./modal/ExtendLockInPeriod";
import LoadingPage from "../../components/LoadingPage";
import TransferNFT from "./modal/TransferNFT";
import useWithdrawMAHA from "../../hooks/callbacks/Vesting/useWithdrawMAHA";
import MergeNFT from "./modal/MergeNFT";
import TextButton from "../../components/TextButton";
import theme from "../../theme";
import {BigNumber, utils} from "ethers";
import config from "../../config";
import {useWallet} from "use-wallet";
import MigratingLockingDetailsCard from "./component/MigratingLockingDetailsCard";
import useGetMigratedNFTsData from "../../hooks/state/Vesting/useGetMigratedNFTsData";
import InfoTip from "../../components/InfoTip";


const Locker = () => {
  const [powerModal, setPowerModal] = useState<boolean>(false);
  const allNFts = useGetAllNFTs();
  const {chainId} = useWallet();
  const migratedData = useGetMigratedNFTsData();
  const [selectedNft, setSelectedNft] = useState<IState | null>(null);
  const [LockMoreMAHAModal, setLockMoreMAHAModal] = useState<boolean>(false)
  const [extendModalOpen, setExtendModalOpen] = useState<boolean>(false)
  const [transferModalOpen, setTransferModalOpen] = useState<boolean>(false);
  const [mergeModalOpen, setMergeModalOpen] = useState<boolean>(false);

  const withdrawMAHAAction = useWithdrawMAHA(selectedNft?.id || null);

  const handleWithdraw = () => {
    withdrawMAHAAction(
      () => {
        window.location.reload();
      },
      () => {
      }
    ).then(() => {
    })
  }

  const switchMetamaskChain = (chainId: number) => {
    console.log(chainId);
    if (window.ethereum) {
      window.ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: utils.hexStripZeros(
                BigNumber.from(config[chainId].chainId).toHexString(),
              ),
            },
          ],
        })
        .then(() => {
          window.location.reload();
        })
        .catch((error: any) => {
          console.log('switchMetamaskChain error', error);
        });
    }
  };

  return (
    <div className="custom-container">
      <VotingPowerModal
        modalOpen={powerModal}
        onModalClose={() => setPowerModal(false)}
      />
      {
        selectedNft !== null
        && <LockMoreMAHA
          nftId={selectedNft.id}
          alreadyLockedMAHA={selectedNft.lockAmount}
          currentVotingPower={selectedNft.lockValue}
          dateSelected={Number(`${selectedNft.lockEnds.toString()}000`)}
          modalOpen={LockMoreMAHAModal}
          onModalClose={() => setLockMoreMAHAModal(false)}
        />
      }
      {
        selectedNft !== null
        && <ExtendLockInPeriod
          nftId={selectedNft.id}
          modalOpen={extendModalOpen}
          mahaLocked={selectedNft.lockAmount}
          onModalClose={() => setExtendModalOpen(false)}
          prevDateSelected={Number(selectedNft.lockEnds.toString()) * 1000}
          startDate={Number(selectedNft.lockStarts.toString()) * 1000}
        />
      }
      {
        selectedNft !== null
        && <TransferNFT
          nftId={selectedNft.id}
          openModal={transferModalOpen}
          onModalClose={() => setTransferModalOpen(false)}
        />
      }
      {
        <MergeNFT
          openModal={mergeModalOpen}
          onModalClose={() => setMergeModalOpen(false)}
        />
      }
      <PageHeader
        title={'Your MAHA Locks'}
        subTitle={
          <div>
            Lock your MAHA for MAHAX NFTs to <a href={'/#/pools-vote'}
                                                className={'links'}>vote</a> on pools and
            earn <a href={'/#/rewards'} className={'links'}>rewards</a> from pool incentives.
          </div>}
        showLearnMore={true}
        learnMoreLink="https://docs.mahadao.com/governance/locking-mechanism"
        className={'m-b-40'}
      />
      {
        <YourLocks className={'single-line-start-between mo-single-line-column m-b-32 mo-custom-container'}>
          <div/>
          <ButtonContainer className={'single-line-center-end'}>
            <TextButton
              text={'Merge'}
              onClick={() => setMergeModalOpen(true)}
              tracking_id={'merge'}
              className={'m-r-24'}
            />
            <Button
              text={'Create Lock'}
              size={'lg'}
              onClick={() => setPowerModal(true)}
              tracking_id={'create_lock'}
              tracking_params={{
                position: 'title'
              }}
            />

          </ButtonContainer>
        </YourLocks>}
      <div>
        <div className={'m-b-24'}>
          <InfoTip
            type={'Info'}
            msg={
              <div>
                <b>Lock MAHA and get special access to our Discord </b> <br/>
                Users who lock MAHA for 4 years will receive special roles in our Discord, granting them access to
                exclusive channels. Visit
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://guild.xyz/mahadao"
                  className={'links bold'}
                >
                  &nbsp; Guild.xyz
                </a>{' '}
                to learn more.
              </div>
            }
          />
        </div>
      </div>
      <div>
        {allNFts.isLoading
          ? <LoadingPage/>
          : !allNFts.isLoading && allNFts.value.length === 0
            ? <TextWrapper
              text={<div>
                You have no NFT locks. <a onClick={() => setPowerModal(true)} className={'links pointer'}>Create a new
                lock.</a>
              </div>}
              align={'center'}
            />
            : allNFts.value.map((data) => {
              /*return (
                <MigratingLockingDetailsCard
                  {...data}
                  key={data.id.toString()}
                />
              )*/
              return (
                <LockingDetailsCard
                  {...data}
                  selectedId={selectedNft?.id}
                  key={data.id.toString()}
                  setSelectedNFT={() => {
                    setSelectedNft(data)
                  }}
                  onLockMoreMAHA={() => {
                    setLockMoreMAHAModal(true)
                  }}
                  onExtendLock={() => {
                    setExtendModalOpen(true)
                  }}
                  tranferNFT={() => {
                    setTransferModalOpen(true)
                  }}
                  onWithdraw={() => {
                    handleWithdraw();
                  }}
                />
              )
            })
        }
      </div>
    </div>
  )
}

export default Locker;

const YourLocks = styled.div`

`;

const ButtonContainer = styled.div`
  margin-top: 24px;
`
