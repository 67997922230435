import React, {useState} from 'react'
import {Grid} from '@material-ui/core'

import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import DataField from '../../../components/DataField'

import {formatToBN} from '../../../utils/formatBalance'
import {SingleDateInput} from '../../../utils/interface'
import useLockMoreMAHA from '../../../hooks/callbacks/Vesting/useLockMoreMAHA'
import {DEFAULT_SINGLE_DATEINPUT_STATE} from '../../../utils/constants'
import ActionButton from "../../../components/ActionButton";
import {BigNumber} from "ethers";

interface Iprops {
  nftId: BigNumber;
  votingShare: string | number
  text: string | number
  modalOpen: boolean
  onModalClose: () => void
  dateSelected: SingleDateInput
}

const ConfirmLockMoreMAHA = (props: Iprops) => {
  const {
    nftId,
    modalOpen,
    onModalClose,
    text = 0,
    votingShare = 0,
    dateSelected = DEFAULT_SINGLE_DATEINPUT_STATE,
  } = props

  const lockMoreMAHAAction = useLockMoreMAHA(
    formatToBN(text),
    nftId,
  )

  const [locking, setLocking] = useState<boolean>(false);

  const handleLockMoreMAHA = () => {
    setLocking(true)
    lockMoreMAHAAction(
      () => {
        window.location.reload();
        onModalClose();
      },
      () => {
        onModalClose();
        setLocking(false)
      }
    ).then(() => {
    })
  }

  return (
    <Modal
      title={'Confirm Lock More MAHA'}
      open={modalOpen}
      handleClose={() => onModalClose()}
      closeButton
    >
      <div className="bottom-divider m-b-24">
        <DataField
          label={'NFT Id'}
          labelFontWeight={600}
          value={`#${nftId.toString()}`}
          className={'m-b-12'}
        />
        <DataField
          label={'New locked MAHA amount'}
          labelFontWeight={600}
          value={Number(text).toLocaleString()}
          valueTag={'MAHA'}
          className={'m-b-12'}
        />
        <DataField
          label={'Lock period'}
          labelFontWeight={600}
          value={`${new Date().toDateString()} - ${new Date(
            dateSelected,
          ).toDateString()}`}
          className={'m-b-24'}
        />
      </div>
      <DataField
        label={'Your new voting power will be'}
        labelToolTip={
          'The amount of voting power you will recieve for locking MAHA.'
        }
        labelFontWeight={600}
        value={`${votingShare}`}
        valueTag={'MAHAX'}
        className={'m-b-32'}
      />
      <div className="m-t-16">
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Button
              variant={'transparent'}
              onClick={() => onModalClose()}
              tracking_id={'lock_more_MAHA'}
              tracking_params={{
                action: 'cancel',
                collateral: 'MAHA',
                amount: Number(text),
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ActionButton
              onClick={handleLockMoreMAHA}
              tracking_id={'lock_more_MAHA'}
              tracking_params={{
                action: 'confirm',
                collateral: 'MAHA',
                amount: Number(text),
              }}
              text={'Submit'}
              loading={locking}
            />
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}

export default ConfirmLockMoreMAHA
