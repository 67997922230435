import { useWallet } from "use-wallet";
import { useCallback, useEffect, useState } from "react";
import useCore from "../../useCore";
import { BigNumber, Contract } from "ethers";
import {
  IGaugeRewardsDynamicData,
  IGaugeStaticData,
} from "../../../utils/interface";
import { getDisplayBalance } from "../../../utils/formatBalance";
import ABIS from "../../../protocol/deployments/abi";
import { useGetActiveChainId } from "../../../state/chains/hooks";

export interface IPassingState {
  isLoading: boolean;
  value: IGaugeRewardsDynamicData[];
}

export const Initial_Loading = {
  isLoading: true,
  value: [],
};

export const Initial_Non_Loading = {
  isLoading: false,
  value: [],
};

const useGetRewardsData = () => {
  const [value, setValue] = useState<IPassingState>(Initial_Loading);

  const core = useCore();
  const { account } = useWallet();
  const chainId = useGetActiveChainId();

  const voter = core.getVotingContract(chainId);
  const gauges: IGaugeStaticData[] = core._config[chainId].gauges;

  const fetchData = useCallback(async () => {
    if (!account) {
      setValue(Initial_Non_Loading);
      return;
    }

    const gaugesPromise = gauges.map(async (gauge, i) => {
      const yourNFTWeightForPool: BigNumber = await voter.votes(
        account,
        core._contracts[chainId][gauge.pool].address
      );

      const bribeContract = core._contracts[chainId][gauge.bribe];
      const bribesRewardLength = await bribeContract.rewardsListLength();

      const bribesRewardIndexArr = Array.from(
        { length: parseInt(bribesRewardLength.toString()) },
        (v, i) => i
      );

      const bribeRewards = await Promise.all(
        bribesRewardIndexArr.map(async (index: any) => {
          const address = await bribeContract.rewards(index);
          const rewardERC20 = new Contract(address, ABIS.IERC20, core.provider);
          const symbol = await rewardERC20.symbol();
          const decimals = await rewardERC20.decimals();
          const earned = await bribeContract.earned(address, account);

          return {
            address,
            symbol,
            decimals,
            earned,
          };
        })
      );

      const positionInfo: { [token: string]: string } = {};

      const modifiedGauge: IGaugeRewardsDynamicData = {
        id: i,
        ...gauge,
        bribeRewards: bribeRewards.filter((data) => !data.earned.eq(0)),
        gaugeRewards: [],
        positionsInfo: positionInfo,
        yourNFTWeightForPool,
      };

      return modifiedGauge;
    });

    const gaugesWithDynamicData: IGaugeRewardsDynamicData[] = await Promise.all(
      gaugesPromise
    );

    setValue({
      isLoading: false,
      value: gaugesWithDynamicData.filter((data) =>
        data.bribeRewards.some((p) => p.earned.gt(0))
      ),
    });
  }, [account, gauges, core, chainId, voter]);

  useEffect(() => {
    if (core) {
      setValue(Initial_Loading);
      fetchData().catch((err) => {
        setValue(Initial_Non_Loading);
        console.error(`Failed to fetch gauge rewards: ${err.stack}`);
      });
    }
  }, [setValue, core, account, fetchData]);

  return value;
};

export default useGetRewardsData;
