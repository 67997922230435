import { Configuration } from "../utils/interface";

const configurations: { [env: string]: Configuration } = {
  137: {
    networkName: "Matic Mainnet",
    networkDisplayName: "Polygon",
    networkIconName: "Polygon",
    chainId: 137,
    etherscanUrl: "https://polygonscan.com",
    defaultProvider:
      "https://solitary-crimson-wind.matic.quiknode.pro/d9d5c0846efe6098a99c0a8a2c7238692ca33ce0/",
    deployments: require("../protocol/deployments/matic.json"),
    refreshInterval: 10000,
    gasLimitMultiplier: 1.1,
    blockchainToken: "MATIC",
    blockchainTokenName: "MATIC",
    blockchainTokenDecimals: 18,
    networkSetupDocLink:
      "https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/",
    supportedTokens: ["MAHA"],
    decimalOverrides: {
      USDC: 6,
    },
    feesRewardsTokens: ["MAHA"],
    gauges: [],
  },
};

export default configurations;
