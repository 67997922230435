import {BigNumber} from 'ethers'
import styled from 'styled-components'
import {Grid} from '@material-ui/core'
import React, {useEffect, useMemo, useState} from 'react'

import theme from '../../../theme'
import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import DataField from '../../../components/DataField'
import DatePicker from '../../../components/DatePicker'
import InputContainer from '../../../components/InputContainer'
import {BackgroundAbsolute} from '../../../components/Selector'

import {getDisplayBalance} from '../../../utils/formatBalance'
import useIncreaseLockTime from '../../../hooks/callbacks/Vesting/useIncreaseLockTime'
import {addDays, addMonths, addWeeks, addYears, YEAR_IN_MS,} from '../../../utils/constants'
import PredefinedDates from '../component/PredefinedDates'
import InfoTip from "../../../components/InfoTip";
import ActionButton from "../../../components/ActionButton";

interface Iprops {
  nftId: BigNumber;
  modalOpen: boolean
  mahaLocked: BigNumber
  onModalClose: () => void
  prevDateSelected: any
  startDate: any;
}

const ExtendLockInPeriod = (props: Iprops) => {
  const {mahaLocked, modalOpen, prevDateSelected, onModalClose, nftId, startDate} = props

  const [increasing, setIncreasing] = useState<boolean>(false);
  const defaultDateSelected = useMemo(() => new Date(prevDateSelected || 0), [prevDateSelected]);
  const initialDate = useMemo(() => new Date(startDate || 0), [startDate]);
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const [dateSelected, setDateSelected] = useState<any>(
    addDays(new Date(defaultDateSelected), 1),
  )
  const [predefinedDate, setPredefinedDate] = useState<string>('1W');

  const formatDate = (data: Date) => {
    return `${data.getDate()} ${data.toDateString().split(' ')[1]
    }, ${data.getFullYear()}`
  }

  // Filters the predefined Values based on MAX_TIME allowed
  const predefinedDateValuesWithDate = useMemo(() => {
    const MAX_DATE = new Date(initialDate.getTime() + 4 * YEAR_IN_MS).getTime();
    const dates = [
      addWeeks(defaultDateSelected, 1),
      addMonths(defaultDateSelected, 1),
      addYears(defaultDateSelected, 1),
      addYears(defaultDateSelected, 2),
      addYears(defaultDateSelected, 3),
      addYears(defaultDateSelected, 4),
    ]
    if (addWeeks(defaultDateSelected, 1).getTime() > MAX_DATE) {
      return dates.slice(0, 0)
    } else if (addMonths(defaultDateSelected, 1).getTime() > MAX_DATE) {
      return dates.slice(0, 1)
    } else if (addMonths(defaultDateSelected, 3).getTime() > MAX_DATE) {
      return dates.slice(0, 2)
    } else if (addMonths(defaultDateSelected, 6).getTime() > MAX_DATE) {
      return dates.slice(0, 3)
    } else if (addYears(defaultDateSelected, 1).getTime() > MAX_DATE) {
      return dates.slice(0, 4)
    } else if (addYears(defaultDateSelected, 4).getTime() > MAX_DATE) {
      return dates.slice(0, 5)
    } else {
      return dates
    }
  }, [defaultDateSelected, initialDate])

  // Updates the predefined Values
  const predefinedDateValues = useMemo(() => {
    const dates = ['1W', '1M', '1Y', '2Y', '3Y', '4Y']
    const slicedDate = dates.slice(0, predefinedDateValuesWithDate.length)
    slicedDate.push('Custom')
    return slicedDate
  }, [predefinedDateValuesWithDate])

  // To update the date with the Selected date
  useEffect(() => {
    setDateSelected(addDays(new Date(defaultDateSelected), 1))
  }, [defaultDateSelected])

  // To reset date when closing the modal
  useEffect(() => {
    if (modalOpen) setDateSelected(addDays(new Date(defaultDateSelected), 1))
  }, [defaultDateSelected, modalOpen])

  const lockInPeriodAction = useIncreaseLockTime(
    nftId,
    Math.round((Number(dateSelected) - Number(new Date().setHours(0, 0, 0, 0,))) / 1000)
  )

  const handleChangeLockinPeriod = () => {
    setIncreasing(true)
    lockInPeriodAction(
      () => {
        onModalClose()
        window.location.reload()
      },
      () => {
        setIncreasing(false)
        onModalClose()
      }
    )
  }

  const votingShare: string = useMemo(() => {
    if (dateSelected === null)
      return BigNumber.from(0).toString()

    const bn: BigNumber = mahaLocked
    if (bn.lte(0)) return BigNumber.from(0).toString()

    const timestamp = Date.parse(dateSelected.toString())
    const timeDiff = timestamp - Date.now()
    if (timeDiff <= 0 || !timeDiff) return BigNumber.from(0).toString()

    const value = bn.mul(timeDiff).div(YEAR_IN_MS).div(4)

    return Number(getDisplayBalance(value, 18, 3)).toLocaleString('en-US', {
      minimumFractionDigits: 3,
    })
  }, [mahaLocked, dateSelected]);

  useEffect(() => {
    if (predefinedDateValues.length > 1) {
      if (predefinedDate === '1W') setDateSelected(addWeeks(defaultDateSelected, 1))
      else if (predefinedDate === '1M') setDateSelected(addMonths(defaultDateSelected, 1))
      else if (predefinedDate === '1Y') setDateSelected(addYears(defaultDateSelected, 1))
      else if (predefinedDate === '2Y') setDateSelected(addYears(defaultDateSelected, 2))
      else if (predefinedDate === '3Y') setDateSelected(addYears(defaultDateSelected, 3))
      else if (predefinedDate === '4Y') setDateSelected(addYears(defaultDateSelected, 4))
    } else setDateSelected(addDays(defaultDateSelected, 1))
  }, [defaultDateSelected, predefinedDate, predefinedDateValues.length])

  useEffect(() => {
    if (new Date(dateSelected).getTime() === addWeeks(defaultDateSelected, 1).getTime()) setPredefinedDate('1W')
    else if (new Date(dateSelected).getTime() === addMonths(defaultDateSelected, 1).getTime()) setPredefinedDate('1M')
    else if (new Date(dateSelected).getTime() === addYears(defaultDateSelected, 1).getTime()) setPredefinedDate('1Y')
    else if (new Date(dateSelected).getTime() === addYears(defaultDateSelected, 2).getTime()) setPredefinedDate('2Y')
    else if (new Date(dateSelected).getTime() === addYears(defaultDateSelected, 3).getTime()) setPredefinedDate('3Y')
    else if (new Date(dateSelected).getTime() === addYears(defaultDateSelected, 4).getTime()) setPredefinedDate('4Y')
    else setPredefinedDate('Custom')
  }, [dateSelected, initialDate])

  return (
    <>
      <Modal
        title={`Extend Lock Period #${nftId.toString()}`}
        open={modalOpen}
        handleClose={onModalClose}
        closeButton
      >
        <DisabledInputContainer>
          <DataField
            label={'Your Locked Amount'}
            value={`${Number(getDisplayBalance(mahaLocked, 18, 3)).toLocaleString()}`}
            valueTag={'MAHA'}
            labelFontSize={18}
            labelFontColor={'#ffffff'}
            valueFontWeight={600}
            valueFontSize={18}
            valueFontColor={'#ffffff'}
          />
        </DisabledInputContainer>
        <div>
          <InputContainer
            label={'Choose Lock Time'}
            className={'m-b-12'}
            dataValue={
              dateSelected !== null
                ? `${formatDate(initialDate)} - ${formatDate(
                  new Date(dateSelected),
                )}`
                : `Pick Date Range`
            }
          >
            <PredefinedDates
              selectedDate={predefinedDate}
              dateValue={predefinedDateValues}
              onDateChange={(data) => {
                setPredefinedDate(data)
              }}
              onCustomPressCallback={() => {
                setShowCalendar(true)
              }}
            />
            <div>
              {showCalendar && (
                <BackgroundAbsolute
                  style={{background: 'rgba(0, 0, 0, 0.2)'}}
                  onClick={() => {
                    setShowCalendar(false)
                  }}
                />
              )}
              <DatePicker
                selectedDates={addDays(new Date(Number(dateSelected)), 1)}
                openCalendar={showCalendar}
                onApplyPressCallback={(data) => {
                  setDateSelected(data)
                  setShowCalendar(false)
                }}
                onClearPressCallback={() => {
                  setDateSelected(addWeeks(defaultDateSelected, 1))
                  setShowCalendar(false)
                }}
                minDate={addDays(new Date(dateSelected), 1)}
                maxDate={addYears(initialDate, 4)}
              />
            </div>
          </InputContainer>
          <DataField
            label={'Your new voting power will be'}
            labelToolTip={
              'The amount of voting power you will receive for locking MAHA.'
            }
            value={`${votingShare}`}
            valueTag={'MAHAX'}
            className={'m-t-20 m-b-20'}
          />
          <div className="m-t-32">
            <InfoTip
              type={'Info'}
              msg={'Your extended lock time will be rounded down to week.'}
              className={'m-b-4'}
            />
            {predefinedDateValues.length === 1 && predefinedDate.includes('Custom') &&
              <InfoTip
                type={'Error'}
                msg={'You cannot extend more than 4years.'}
                className={'m-b-4'}
              />
            }
            <InfoTip
              type={'Warning'}
              msg={
                'Your votes for the pools will get reset when you extend your lock duration.'
              }
              className={'m-b-4'}
            />
            {/* <InfoTip
              type={'Info'}
              msg={'If you increase your lock for upto 4 years then your APR will increase by about 50% to 200%.'}
              className={'m-b-12'}
            /> */}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Button
                  variant={'transparent'}
                  onClick={onModalClose}
                  tracking_id={'Extend_Lock_in_period'}
                  tracking_params={{
                    action: 'cancel',
                    collateral: 'MAHA',
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <ActionButton
                  onClick={handleChangeLockinPeriod}
                  disabled={!dateSelected || (predefinedDateValues.length === 1 && predefinedDate.includes('Custom'))}
                  tracking_id={'Extend_Lock_in_period'}
                  tracking_params={{
                    action: 'confirm',
                    collateral: 'MAHA',
                  }}
                  text={'Extend Lock in period'}
                  loading={increasing}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ExtendLockInPeriod

const DisabledInputContainer = styled.div`
  padding: 14px 12px;
  background: ${theme.color.transparent[500]};
  border-radius: 6px;
  margin-bottom: 24px;
`
