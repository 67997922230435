import React, { useState } from 'react'
import { Grid } from '@material-ui/core'

import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import DataField from '../../../components/DataField'
import { SingleDateInput } from '../../../utils/interface'
import { formatToBN } from '../../../utils/formatBalance'
import useLockMAHA from '../../../hooks/callbacks/Vesting/useLockMAHA'
import { DEFAULT_SINGLE_DATEINPUT_STATE } from '../../../utils/constants'
import ActionButton from "../../../components/ActionButton";
import { truncateMiddle } from "../../../utils";
import useLockMAHAWithReferral from "../../../hooks/callbacks/Vesting/useLockMAHAWithReferral";
import { ethers } from "ethers";
import { useWallet } from "use-wallet";

interface Iprops {
  votingShare: string | number
  text: string | number
  modalOpen: boolean
  onModalClose: () => void
  dateSelected: SingleDateInput
  stake: boolean;
  referralId: string;
  bonusMAHA: string;
}

const LockerModal = (props: Iprops) => {
  const {
    modalOpen,
    onModalClose,
    text = 0,
    votingShare = 0,
    dateSelected = DEFAULT_SINGLE_DATEINPUT_STATE,
    referralId,
    stake,
    bonusMAHA,
  } = props

  const { account } = useWallet();
  const [locking, setLocking] = useState<boolean>(false);

  const lockMAHAAction = useLockMAHA(
    formatToBN(text),
    Math.round((Number(dateSelected) - Number(new Date().setHours(0, 0, 0, 0))) / 1000),
    stake,
  )

  const lockMAHAWithReferralAction = useLockMAHAWithReferral(
    formatToBN(text),
    Math.round((Number(dateSelected) - Number(new Date().setHours(0, 0, 0, 0))) / 1000),
    stake,
    referralId
  )

  const handleLockMAHA = () => {
    setLocking(true)
    lockMAHAAction(
      () => {
        onModalClose()
        window.location.reload();
      },
      () => {
        onModalClose()
        setLocking(false);
      }
    ).then(() => {
    })
  }

  const handleLockMAHAWithReferral = () => {
    setLocking(true)
    lockMAHAWithReferralAction(
      () => {
        onModalClose()
        window.location.reload();
      },
      () => {
        onModalClose()
        setLocking(false);
      }
    ).then(() => {
    })
  }

  return (
    <Modal
      title={'Confirm Creation of Lock'}
      open={modalOpen}
      handleClose={() => onModalClose()}
      closeButton
    >
      <div className="bottom-divider m-b-24">
        <DataField
          label={'Lock Amount'}
          labelFontWeight={600}
          value={Number(text).toLocaleString()}
          valueTag={'MAHA'}
          className={'m-b-12'}
        />
        <DataField
          label={'Lock Period'}
          labelFontWeight={600}
          value={`${new Date().toDateString()} - ${new Date(
            dateSelected,
          ).toDateString()}`}
          className={'m-b-12'}
        />
        <DataField
          label={'Stake NFT'}
          labelFontWeight={600}
          value={`${stake ? 'Yes' : 'No'}`}
          className={'m-b-12'}
        />
        {(referralId === '' || referralId === account)
          ? <div />
          : <DataField
            label={'Referred by'}
            labelFontWeight={600}
            value={`${truncateMiddle(referralId, 12)}`}
            className={'m-b-24'}
          />}
      </div>
      <DataField
        label={'Your starting voting power will be'}
        labelToolTip={
          'The amount of voting power you will receive for locking MAHA.'
        }
        labelFontWeight={600}
        value={`${votingShare}`}
        valueTag={'MAHAX'}
        className={'m-b-32'}
      />
      <div className="m-t-16">
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Button
              variant={'transparent'}
              onClick={() => onModalClose()}
              tracking_id={"lock_MAHA"}
              tracking_params={{
                collateral: 'MAHA',
                action: 'cancel',
                amount: Number(text),
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ActionButton
              onClick={handleLockMAHA}
              tracking_id={"lock_MAHA"}
              tracking_params={{
                collateral: 'MAHA',
                action: 'confirm',
                amount: Number(text),
              }}
              text={'Submit'}
              loading={locking}
            />
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}

export default LockerModal
