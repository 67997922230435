import {BigNumber, Contract} from "ethers";
import {useCallback} from "react";

import {useAddPopup} from "../../../state/application/hooks";
import {useTransactionAdder} from "../../../state/transactions/hooks";
import formatErrorMessage from "../../../utils/formatErrorMessage";
import useCore from "../../useCore";
import ABIS from "../../../protocol/deployments/abi";
import {getDisplayBalance} from "../../../utils/formatBalance";

export default function (contract: Contract, amount: BigNumber, displayName: string) {
  const core = useCore();
  const addPopup = useAddPopup();
  const addTransaction = useTransactionAdder();

  return useCallback(
    async (onSuccess: () => void, onFailure: () => void): Promise<void> => {

      try {
        const response = await contract.withdraw(amount)

        addTransaction(response, {
          summary: `Withdraw ${Number(getDisplayBalance(amount, 18, 3)).toLocaleString()} from 
            ${displayName}
          `,
        });

        const tx = await response.wait();

        if (tx?.status === 1) onSuccess();
        if (tx?.status !== 1) onFailure();

      } catch (e: any) {
        console.log('useGaugeWithdraw error', e);
        onFailure();
        addPopup({
          error: {
            message: formatErrorMessage(e?.data?.message || e?.message),
            stack: e.stack,
          },
        });
      }
    },
    [contract, amount, addTransaction, displayName, addPopup],
  );
}
