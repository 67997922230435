import {Grid} from "@material-ui/core";
import Deposit from "./components/Deposit";
import React, {useMemo} from "react";
import {useParams} from "react-router-dom";
import useCore from "../../hooks/useCore";
import {useGetActiveChainId} from "../../state/chains/hooks";
import TextWrapper from "../../components/TextWrapper";
import PositionInfo from "./components/PositionInfo";
import IconLoader from "../../components/IconLoader";
import styled from "styled-components";
import TextButton from "../../components/TextButton";
import Loader from "react-spinners/BeatLoader";
import Withdraw from "./components/Withdraw";
import useGetlpTokenApr from "../../hooks/state/Staking/useGetlpTokenApr";

const LpTokenStaking = () => {
  const {poolId: pool} = useParams<{ poolId: string }>();

  const core = useCore();
  const chainId = useGetActiveChainId();
  const aprTvlData = useGetlpTokenApr();
  const gauges = core._config[chainId]["gauges"];

  const poolData = useMemo(() => {
    const staticPoolsData = gauges.filter(data => data.pool === pool);
    return staticPoolsData[0] ? staticPoolsData[0] : null;
  }, [gauges, pool])

  const dataWithApr = useMemo(() => {
    if (poolData) {
      var minApr = '-';
      var maxApr = '-';
      if (!aprTvlData.isLoading && aprTvlData.data[poolData.apiKey]) {
        minApr = String(aprTvlData.data[poolData.apiKey].current.min);
        maxApr = String(aprTvlData.data[poolData.apiKey].current.max);
      }
      return {minApr: minApr, maxApr: maxApr}
    } else {
      return {minApr: 0, maxApr: 0}
    }
  }, [aprTvlData, poolData])

  const AprComponent = () => {
    const minApr = dataWithApr.minApr;
    const maxApr = dataWithApr.maxApr;
    if (aprTvlData.isLoading) {
      return (
        <div className={'start-center'}>
          <Loader color={'#ffffff'} loading={true} size={2} margin={2}/>
        </div>
      )
    }
    if (Number(minApr) && Number(maxApr)) {
      return (
        <div className={'start-center links'}>
          {
            Number(minApr).toLocaleString('en-Us', {maximumFractionDigits: 2}) +
            '%' +
            ' - ' +
            (Number(maxApr)).toLocaleString('en-Us', {maximumFractionDigits: 2}) +
            '%'
          }
        </div>
      );
    } else if (Number(minApr)) {
      return (
        <div className={'start-center links'}>
          {
            Number(minApr).toLocaleString('en-Us', {maximumFractionDigits: 2}) + '%'
          }
        </div>
      );
    } else if (Number(maxApr)) {
      return (
        <div className={'start-center links'}>
          {
            Number(maxApr).toLocaleString('en-Us', {maximumFractionDigits: 2}) + '%'
          }
        </div>
      );
    } else {
      return (
        <div className={'start-center links'}>
          -
        </div>
      )
    }
  }

  return (
    <div className={'custom-container'}>
      <Header className="pointer single-line-center-start mo-custom-container m-b-8"
              onClick={() => window.location.href = "/#/staking"}>
        <IconLoader iconName={"ArrowTailLeft"} iconType={"arrow"} className="m-r-8"/>
        <TextWrapper text={"Back to gauges"}/>
      </Header>
      {poolData !== null && <div className={'m-b-32  mo-custom-container'}>
        <TextWrapper text={`Staking Pool: ${poolData.displayName}`} fontFamily={'Syne'} fontSize={24}/>
        <div className={'single-line-center-start m-b-12'}>
          <TextButton
            onClick={() => window.open(`${poolData.liquidityLink}`)}
            text={`Add Liquidity`}
            fontSize={12}
            tracking_id={'add_v3liquidity'}
            tracking_params={{
              collateral: `${poolData.displayName}`
            }}
          />
          <IconLoader iconName={'ArrowLinkColored'} iconType={'arrow'} width={16} height={16}/>
        </div>
        <div className={'single-line-center-start'}>
          <TextWrapper text={`APR:`} fontFamily={'Syne'} fontSize={14} className={'m-r-8'}/>
          {
            aprTvlData.isLoading
              ? <Loader color={'#ffffff'} loading={true} size={2} margin={2}/>
              : <AprComponent/>
          }
        </div>
      </div>}
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          {poolData !== null
            ? <div className={'mo-custom-container'}>
              <Deposit poolDetails={poolData}/>
              <PositionInfo poolDetails={poolData}/>
            </div>
            : <TextWrapper text={"Oops! No such pool found in our system"} align={'center'}/>}
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          {poolData !== null && <div className={'mo-custom-container'}><Withdraw poolDetails={poolData}/></div>}
        </Grid>
      </Grid>
    </div>
  )
}

export default LpTokenStaking;

const Header = styled.div`
  opacity: 80%;
`;
