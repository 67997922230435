import TextWrapper from "../../../components/TextWrapper";

const InfoBox = () => {
  return (
    <div className={'material-primary'}>
      <TextWrapper
        text={'About V3 Staking'}
        fontSize={16}
        fontWeight={'bold'}
        className={'m-b-24'}
      />
      <TextWrapper
        text={'In Uniswap v3, liquidity providers (LPs) can concentrate their capital within custom price ranges, providing greater amounts of \
        liquidity at desired prices. In doing so, LPs construct individualized price curves that reflect their own preferences.'}
        className={'m-b-12'}
      />
      <TextWrapper
        text={'LP positions will be represented by non-fungible tokens (NFTs) which can be staked for MAHA via this portal. If you \
        also have a MAHA NFT locked in governance then you can boost your staking rewards by 5x.'}
      />
      <br />
      <TextWrapper
        text={'Note that when you deposit your LP positions, the DAO claims trading fees in exchange for MAHA rewards.'}
      />
      <br />
      <TextWrapper
        text={
          <div>
            To learn more about Uniswap V3 staking, refer to the <a
              href={'https://docs.mahadao.com/governance/pool-incentives/uniswap-v3-staking'} target="_blank"
              className={'links'} rel="noreferrer">documentation</a>
          </div>
        }
      />
    </div>
  )
}

export default InfoBox;
