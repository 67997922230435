import React, {useEffect, useState} from "react";
import styled from "styled-components";
import TextWrapper from "../../../components/TextWrapper";

interface IProps {
  selectedDate: any;
  dateValue: string[];
  onDateChange: (data: any) => void;
  onCustomPressCallback: () => void;
  className?: string;
}

const PredefinedDates = (props: IProps) => {
  const {
    selectedDate,
    dateValue,
    className = "",
    onDateChange,
    onCustomPressCallback,
  } = props;

  const [date, setDate] = useState<string>(selectedDate);
  useEffect(() => setDate(selectedDate), [date, selectedDate])

  return (
    <Container className={className}>
      {dateValue?.map((item, index) => {
        return (
          <IndiviualValue
            onClick={() => {
              if (item !== "Custom") onDateChange(item)
              else onCustomPressCallback()
            }}
            key={index}
            style={item === date ? {border: '1px solid white'} : {}}
          >
            <TextWrapper
              text={item}
              fontWeight={item === date ? 600 : 300}
            />
          </IndiviualValue>
        );
      })}
    </Container>
  )

}

export default PredefinedDates;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 600px) {
    justify-content: space-between;
  }
`

const IndiviualValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 4px 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 4px;
  margin-right: 6px;
  margin-bottom: 4px;
  @media (max-width: 600px) {
    padding: 2px 6px;
    margin-right: 4px;
  }

`;
