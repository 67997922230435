import {useWallet} from "use-wallet";
import {useCallback, useEffect, useState} from "react";
import {DECIMALS_18, LOADING_DEFAULT_BASIC_STATE, NON_LOADING_DEFAULT_BASIC_STATE,} from "../../utils/constants";
import {BasicState} from "../../utils/interface";
import useCore from "../useCore";
import {useGetActiveChainId} from "../../state/chains/hooks";
import useTokenBalanceOf from "../useTokenBalanceOf";
import {BigNumber} from "ethers";
import useGetTotalMAHALocked from "./useGetTotalMAHALocked";

const useGetPercOfMAHALocked = () => {
  const [value, setValue] = useState<BasicState>(
    LOADING_DEFAULT_BASIC_STATE
  );

  const core = useCore();
  const {account} = useWallet();
  const totalMAHALocked = useGetTotalMAHALocked();

  const fetchCirculatingSupply = async () => {
    return await fetch('https://api.coingecko.com/api/v3/coins/mahadao?tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false')
      .then(res => res.json())
      .then(res => {
        return Math.round(res.market_data.circulating_supply)
      })
      .catch(err => {
        console.error(`Failed to fetch ciculating supply of maha: ${err.stack}`)
        return 0;
      })
  }

  const fetchData = useCallback(async () => {
    const mahaCirculatingSupply = await fetchCirculatingSupply();
    if (totalMAHALocked.isLoading) return {
      isLoading: true,
      value: BigNumber.from(0)
    };

    if (mahaCirculatingSupply <= 0 || !mahaCirculatingSupply) return {
      isLoading: false,
      value: BigNumber.from(0)
    }

    setValue({
      isLoading: false,
      value: (totalMAHALocked.value.mul(DECIMALS_18).div(DECIMALS_18.mul(BigNumber.from(`${mahaCirculatingSupply}`)))).mul(100)
    })

  }, [totalMAHALocked.isLoading, totalMAHALocked.value]);

  useEffect(() => {
    if (core) {
      fetchData().catch((err) => {
        setValue(NON_LOADING_DEFAULT_BASIC_STATE);
        console.error(`Failed to fetch total maha locked status: ${err.stack}`);
      });
    }
  }, [setValue, core, account, fetchData]);

  return value;
};

export default useGetPercOfMAHALocked;
