import {useCallback} from "react";
import useCore from "../../useCore";
import {useAddPopup} from "../../../state/application/hooks";
import {useTransactionAdder} from "../../../state/transactions/hooks";
import formatErrorMessage from "../../../utils/formatErrorMessage";
import {BigNumber} from "ethers";
import {useGetActiveChainId} from "../../../state/chains/hooks";

const useUnStakeNFT = (id: BigNumber) => {
  const core = useCore();
  const addPopup = useAddPopup();
  const addTransaction = useTransactionAdder();
  const chainId = useGetActiveChainId();

  return useCallback(
    async (onSuccess: () => void, onFailure: () => void): Promise<void> => {
      const contract = core.getStakingContract(chainId);

      try {
        const response = await contract.unstake(id);

        addTransaction(response, {
          summary: `UnStake NFT ${id.toString()}`,
        });

        const tx = await response.wait();

        if (tx.status === 1) onSuccess();
        if (tx.status !== 1) onFailure();
      } catch (e: any) {
        onFailure();
        addPopup({
          error: {
            message: formatErrorMessage(e?.data?.message || e?.message),
            stack: e.stack,
          },
        });
      }
    },
    [core, chainId, id, addTransaction, addPopup]
  );
};

export default useUnStakeNFT;
