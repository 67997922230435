import {useWallet} from "use-wallet";
import {useCallback, useEffect, useState} from "react";
import useCore from "../../useCore";
import {BigNumber} from "ethers";
import {useGetActiveChainId} from "../../../state/chains/hooks";

export interface IState {
  id: BigNumber;
  lockEnds: BigNumber;
  lockAmount: BigNumber;
  lockStarts: BigNumber;
  lockValue: BigNumber;
}

export interface IPassingState {
  isLoading: boolean;
  value: IState | null;
}

export const Initial_Loading = {
  isLoading: true,
  value: null,
}

export const Initial_Non_Loading = {
  isLoading: false,
  value: null,
}

const useGetNFTDetails = (nftId: BigNumber) => {
  const [value, setValue] = useState<IPassingState>(
    Initial_Loading
  );

  const core = useCore();
  const {account} = useWallet();
  const chainId = useGetActiveChainId();
  const contract = core.getVestingContract(chainId);

  const fetchData = useCallback(async () => {
    if (!account) {
      setValue(Initial_Non_Loading);
      return;
    }

    const locked = await contract.locked(nftId)
    const lockValue = await contract.balanceOfNFT(nftId)

    const data = {
      id: nftId,
      lockEnds: locked[1],
      lockAmount: locked[0],
      lockStarts: locked[2],
      lockValue: lockValue,
    }

    setValue({
      isLoading: false,
      value: data,
    })
  }, [account, contract, nftId]);

  useEffect(() => {
    if (core) {
      setValue(Initial_Loading);
      fetchData().catch((err) => {
        setValue(Initial_Non_Loading);
        console.error(`Failed to fetch nft details: ${err.stack}`);
      });
    }
  }, [setValue, core, account, fetchData]);

  return value;
};

export default useGetNFTDetails;
