import {Configuration} from "../utils/interface";

const configurations: { [env: string]: Configuration } = {
  1: {
    networkName: "Ethereum",
    networkDisplayName: "Ethereum",
    networkIconName: "Ethereum",
    chainId: 1,
    etherscanUrl: "https://etherscan.io",
    defaultProvider:
      "https://eth-mainnet.g.alchemy.com/v2/HY3urTDGBnhgqGXmCsjPEzyiVSY3NLh8",
    merkleProof: require("../protocol/deployments/merkleProofs/ethereum.json"),
    deployments: require("../protocol/deployments/ethereum.json"),
    refreshInterval: 10000,
    gasLimitMultiplier: 1.1,
    blockchainToken: "ETH",
    blockchainTokenName: "ETH",
    blockchainTokenDecimals: 18,
    networkSetupDocLink:
      "https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/",
    supportedTokens: ["MAHA", "WETH", "SCLP", "ARTH", "ARTHUSDC-LPToken"],
    decimalOverrides: {
      USDC: 6,
    },
    oldFeeMerkleProof: require("../protocol/deployments/merkleProofs/ethereum-rewards.json"),
    feesRewardsTokens: ["MAHA", "SCLP"],
    gauges: [
      {
        isUniswapV3: false,
        displayName: "ARTH/USDC Curve",
        gauge: "ARTHUSDC-GaugeLP",
        bribe: "ARTHUSDC-Bribe",
        pool: "ARTHUSDC-LPToken",
        liquidityLink:
          "https://curve.fi/#/ethereum/pools/factory-crypto-185/deposit",
        depositToken: "ARTHUSDC-LPToken",
        apiKey: "arth-usdc-curve-crypto-185",
        poolGoToLink: `/staking/ARTHUSDC-LPToken`,
        poolFee: 1,
        status: "Live",
      },
      {
        isUniswapV3: true,
        displayName: "ARTH/ETH 1% Uniswap V3",
        gauge: "ARTHWETH-GaugeUniswapV3",
        bribe: "ARTHWETH-Bribe",
        pool: "ARTHWETH-UniV3-Pool",
        liquidityLink:
          "https://app.uniswap.org/#/add/0x8cc0f052fff7ead7f2edcccac895502e884a8a71/ETH/10000",
        depositToken: "",
        poolFee: 1,
        apiKey: "arth-eth-1000",
        poolGoToLink: `/v3Staking/ARTHWETH-UniV3-Pool`,
        status: "Live",
      },
      {
        isUniswapV3: true,
        displayName: "ARTH/MAHA 1% Uniswap V3",
        gauge: "ARTHMAHA-GaugeUniswapV3",
        bribe: "ARTHMAHA-Bribe",
        pool: "ARTHMAHA-UniV3-Pool",
        liquidityLink:
          "https://app.uniswap.org/#/add/0x8cc0f052fff7ead7f2edcccac895502e884a8a71/0x745407c86df8db893011912d3ab28e68b62e49b0/10000?chain=mainnet",
        depositToken: "",
        poolFee: 1,
        apiKey: "arth-maha-1000",
        poolGoToLink: `/v3Staking/ARTHMAHA-UniV3-Pool`,
        status: "Live",
      },
    ],
  },
};

export default configurations;
