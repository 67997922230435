import PageHeader from "../../components/PageHeader";
import DataTable from "./component/DataTable";
import useCore from "../../hooks/useCore";
import {useGetActiveChainId} from "../../state/chains/hooks";
import TextWrapper from "../../components/TextWrapper";
import React, {useMemo} from "react";
import CountDownDiv from "../UniV3Staking/components/CountDownDiv";
import {GaugeRewardsStartTime} from "../UniV3Staking";
import useGetV3StakingAPR from "../../hooks/state/V3Staking/useGetV3StakingAPR";
import useGetStakingApr from "../../hooks/state/Farming/useGetStakingApr";

const Staking = () => {
  const core = useCore();
  const chainId = useGetActiveChainId();
  const gauges = core.getGauges(chainId);
  const aprTvlData = useGetStakingApr();

  const dataWithApr = useMemo(() => {
    return gauges.map(data => {
      var minApr = '-';
      var maxApr = '-';
      if (!aprTvlData.isLoading && aprTvlData.data[data.apiKey]) {
        minApr = String(aprTvlData.data[data.apiKey].current.min);
        maxApr = String(aprTvlData.data[data.apiKey].current.max);
      }
      return {...data, minApr: minApr, maxApr: maxApr}
    })

  }, [aprTvlData, gauges])

  return (
    <div className={'custom-container'}>
      <PageHeader
        title={'Farming/Gauges'} subTitle={'Earn MAHA rewards from gauges by staking your LP tokens'}
        className={'m-b-40'} showLearnMore
        learnMoreLink="https://docs.mahadao.com/governance/pool-voting"/>
      <div>
        {GaugeRewardsStartTime > new Date().getTime() && <div className={'m-b-24'}>
          <CountDownDiv/>
        </div>}
        <DataTable data={dataWithApr} aprLoading={aprTvlData.isLoading}/>
        <div className={'m-t-24'}>
          <TextWrapper
            text={<div>
              To register your own pool for gauges and pool incentives, create a governance proposal <a
              href={'https://discuss.mahadao.com/c/governance/gauge-proposals/54'} target={'_blank'}
              className={'links'} rel="noreferrer">here</a>
            </div>}
            align={'center'}
          />
        </div>
      </div>
    </div>
  )
}

export default Staking;
