import { BigNumber } from "ethers";
import { useCallback } from "react";

import { useAddPopup } from "../../../state/application/hooks";
import { useTransactionAdder } from "../../../state/transactions/hooks";
import formatErrorMessage from "../../../utils/formatErrorMessage";
import useCore from "../../useCore";
import { useGetActiveChainId } from "../../../state/chains/hooks";
import { useWallet } from "use-wallet";

const useV3Claim = (nftID: BigNumber, gauge: string) => {
  const core = useCore();
  const addPopup = useAddPopup();
  const addTransaction = useTransactionAdder();
  const chainId = useGetActiveChainId();
  const { account } = useWallet();

  return useCallback(
    async (onSuccess: () => void, onFailure: () => void): Promise<void> => {
      const contract = core._contracts[chainId][gauge];

      try {
        console.log(contract, nftID);
        const response = await contract["getReward(uint256)"](nftID);

        addTransaction(response, {
          summary: `Claim rewards from ${nftID.toString()} staking`,
        });

        const tx = await response.wait();

        if (tx?.status === 1) onSuccess();
        if (tx?.status !== 1) onFailure();
      } catch (e: any) {
        console.log("useV3Claim error", e);
        onFailure();
        addPopup({
          error: {
            message: formatErrorMessage(e?.data?.message || e?.message),
            stack: e.stack,
          },
        });
      }
    },
    [core._contracts, gauge, chainId, nftID, account, addTransaction, addPopup]
  );
};

export default useV3Claim;
