import { useCallback } from "react";
import useCore from "../../useCore";
import { useAddPopup } from "../../../state/application/hooks";
import { useTransactionAdder } from "../../../state/transactions/hooks";
import formatErrorMessage from "../../../utils/formatErrorMessage";
import { BigNumber } from "ethers";
import { useGetActiveChainId } from "../../../state/chains/hooks";

const useClaimFeesRewards = (token: string, nftId: BigNumber) => {
  const core = useCore();
  const addPopup = useAddPopup();
  const addTransaction = useTransactionAdder();
  const chainId = useGetActiveChainId();

  return useCallback(
    async (onSuccess: () => void, onFailure: () => void): Promise<void> => {
      try {
        const contract = await core.getFeesRewards(token, chainId);

        const proof = (core._config[chainId].oldFeeMerkleProof || {})[token];
        const leaf = proof.leaves.find((f) => f.data.id === nftId.toString());

        const response = leaf
          ? await contract.claimWithPendingRewards(
              nftId.toString(),
              leaf?.data.amount,
              leaf?.proof
            )
          : await contract.claim(nftId);

        addTransaction(response, {
          summary: `Claim ${token} rewards for ${nftId.toString()}`,
        });

        const tx = await response.wait();

        if (tx.status === 1) onSuccess();
        if (tx.status !== 1) onFailure();
      } catch (e: any) {
        onFailure();
        addPopup({
          error: {
            message: formatErrorMessage(e?.data?.message || e?.message),
            stack: e.stack,
          },
        });
      }
    },
    [nftId, token, core, chainId, addTransaction, addPopup]
  );
};

export default useClaimFeesRewards;
