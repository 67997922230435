import { useCallback } from "react";
import useCore from "../../useCore";
import { useAddPopup } from "../../../state/application/hooks";
import { useTransactionAdder } from "../../../state/transactions/hooks";
import formatErrorMessage from "../../../utils/formatErrorMessage";
import { BigNumber } from "ethers";
import { useGetActiveChainId } from "../../../state/chains/hooks";
import config from "../../../config";

const useClaimAllFeesRewards = (nftIds: BigNumber[]) => {
  const core = useCore();
  const addPopup = useAddPopup();
  const addTransaction = useTransactionAdder();
  const chainId = useGetActiveChainId();
  const tokens = config[chainId].feesRewardsTokens;
  const feeDistributors = tokens.map((data) =>
    core.getFeesRewards(data, chainId)
  );

  return useCallback(
    async (onSuccess: () => void, onFailure: () => void): Promise<void> => {
      try {
        const contract = await core._contracts[chainId]["MultiFeeDistributor"];

        const params: [BigNumber, string][] = [];
        for (let index = 0; index < feeDistributors.length; index++) {
          const contract = feeDistributors[index];

          for (let index = 0; index < nftIds.length; index++) {
            const nftId = nftIds[index];
            const reward: BigNumber = await contract.callStatic.claim(nftId);

            if (reward.gt(0)) params.push([nftId, contract.address]);
          }
        }

        const response = await contract.claimMany(
          params.map((p) => p[0]),
          params.map((p) => p[1])
        );

        addTransaction(response, {
          summary: `Claim all fees rewards`,
        });

        const tx = await response.wait();

        if (tx.status === 1) onSuccess();
        if (tx.status !== 1) onFailure();
      } catch (e: any) {
        onFailure();
        addPopup({
          error: {
            message: formatErrorMessage(e?.data?.message || e?.message),
            stack: e.stack,
          },
        });
      }
    },
    [
      core._contracts,
      chainId,
      nftIds,
      feeDistributors,
      addTransaction,
      addPopup,
    ]
  );
};

export default useClaimAllFeesRewards;
