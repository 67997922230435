import { Redirect, Route, Switch } from "react-router-dom";

import Page from "./components/Page/Page";
import Home from "./views/Home";
import Locker from "./views/Locker";
import ComingSoon from "./components/ComingSoon";
import NoPageFound from "./components/NoPageFound";
import Faucet from "./views/Faucet";
import GaugeWeightVote from "./views/GaugeWeightVote";
import Rewards from "./views/Rewards";
import Bribes from "./views/Bribes";
import Staking from "./views/Staking";
import Referral from "./views/Referral";
import MAHAXRewards from "./views/MAHAXRewards";
import LpTokenStaking from "./views/LpTokenStaking";
import UniV3Staking from "./views/UniV3Staking";
import JoiningDAO from "./views/ReferralOnboarding/JoiningDAO";
import ReferralOnboarding from "./views/ReferralOnboarding";

const Navigation = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/rewards">
        <Page>
          {/* <ReDepolying/> */}
          <Rewards />
        </Page>
      </Route>
      <Route exact path="/referral">
        <Page>
          <Referral />
        </Page>
      </Route>
      <Route exact path="/referral/onboarding">
        <Page>
          <ReferralOnboarding />
        </Page>
      </Route>
      <Route exact path="/referral/onboarding/join-dao">
        <Page>
          <JoiningDAO />
        </Page>
      </Route>
      <Route exact path="/staking/:poolId">
        <Page availableNetworks={[137, 1337]}>
          <LpTokenStaking />
        </Page>
      </Route>
      <Route exact path="/v3Staking/:poolAddress">
        <Page>
          <UniV3Staking />
        </Page>
      </Route>
      <Route exact path="/staking">
        <Page>
          <Staking />
        </Page>
      </Route>
      <Route exact path="/pool-incentives">
        <Page>
          <Bribes />
        </Page>
      </Route>
      <Route exact path="/delegation">
        <Page>
          <ComingSoon />
        </Page>
      </Route>
      <Route exact path="/pools-vote">
        <Page>
          <GaugeWeightVote />
        </Page>
      </Route>
      <Route exact path="/locker">
        <Page>
          <Locker />
        </Page>
      </Route>
      <Route exact path="/gauge-boost">
        <Page>
          <ComingSoon />
        </Page>
      </Route>
      <Route exact path="/faucet">
        <Page>
          <Faucet />
        </Page>
      </Route>
      <Route exact path="*">
        <NoPageFound />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
}

export default Navigation;
