import { useCallback, useEffect, useState } from "react";
import { useWallet } from "use-wallet";
import useCore from "../../useCore";
import { BigNumber } from "ethers";
import { BasicNumberState, BasicState } from "../../../utils/interface";
import {
  LOADING_DEFAULT_BASIC_STATE,
  LOADING_DEFAULT_NUMBER_BASIC_STATE,
  NON_LOADING_DEFAULT_BASIC_STATE,
  NON_LOADING_DEFAULT_NUMBER_BASIC_STATE,
} from "../../../utils/constants";
import { useGetActiveChainId } from "../../../state/chains/hooks";
import { getDisplayBalance } from "../../../utils/formatBalance";

const useGetNFTBoost = (gauge: string, nftId: BigNumber) => {
  const [value, setValue] = useState<BasicNumberState>(
    LOADING_DEFAULT_NUMBER_BASIC_STATE
  );

  const core = useCore();
  const chainId = useGetActiveChainId();
  const { account } = useWallet();

  const fetchData = useCallback(async () => {
    if (!account) {
      setValue(NON_LOADING_DEFAULT_NUMBER_BASIC_STATE);
      return;
    } else {
      setValue(LOADING_DEFAULT_NUMBER_BASIC_STATE);
    }

    const gaugeContract = core._contracts[chainId][gauge];
    const value = await gaugeContract.boostedFactor(nftId, account);
    const factor = Number(getDisplayBalance(await value[2]));
    // const factor = factorInverse.eq(0) ? 0 : 1 / Number(getDisplayBalance(factorInverse))

    setValue({
      isLoading: false,
      value: factor,
    });
  }, [account, core, gauge, chainId, nftId]);

  useEffect(() => {
    if (core) {
      fetchData().catch((err) => {
        setValue(NON_LOADING_DEFAULT_NUMBER_BASIC_STATE);
        console.error(`Failed to fetch nft boost status: ${err.stack}`);
      });
    }
  }, [setValue, core, account, fetchData]);

  return value;
};

export default useGetNFTBoost;
