import { useWallet } from "use-wallet";
import { useCallback, useEffect, useState } from "react";
import { BigNumber } from "ethers";

import useCore from "../../useCore";
import { BasicState } from "../../../utils/interface";
import {
  LOADING_DEFAULT_BASIC_STATE,
  NON_LOADING_DEFAULT_BASIC_STATE,
} from "../../../utils/constants";
import { useGetActiveChainId } from "../../../state/chains/hooks";

const useGetFeesRewards = (
  token: string,
  nftId: BigNumber,
  nftLoading: boolean
) => {
  const [value, setValue] = useState<BasicState>(LOADING_DEFAULT_BASIC_STATE);

  const core = useCore();
  const chainId = useGetActiveChainId();
  const { account } = useWallet();

  const fetchCashPrice = useCallback(async () => {
    if (!account) {
      setValue(NON_LOADING_DEFAULT_BASIC_STATE);
      return;
    }
    if (nftLoading) {
      setValue(LOADING_DEFAULT_BASIC_STATE);
      return;
    }
    if (!nftLoading && nftId.eq(0)) {
      setValue(NON_LOADING_DEFAULT_BASIC_STATE);
      return;
    }

    const proof = (core._config[chainId].oldFeeMerkleProof || {})[token];
    const leaf = proof.leaves.find((f) => f.data.id === nftId.toString());

    const contract = core.getFeesRewards(token, chainId);

    const earnedReward = leaf
      ? await contract.callStatic.claimWithPendingRewards(
          nftId.toString(),
          leaf?.data.amount,
          leaf?.proof
        )
      : await contract.callStatic.claim(nftId.toString());

    setValue({
      isLoading: false,
      value: earnedReward,
    });
  }, [account, chainId, core, nftId, nftLoading, token]);

  useEffect(() => {
    if (core) {
      fetchCashPrice().catch((err) => {
        console.error(
          `Failed to fetch Fees rewards for ${nftId.toString()} - ${token}: ${
            err.stack
          }`
        );
      });
    } else {
      setValue(NON_LOADING_DEFAULT_BASIC_STATE);
    }
  }, [setValue, core, account, fetchCashPrice, nftId, token]);

  return value;
};

export default useGetFeesRewards;
