import React from "react";

import './styles/index.sass';

import {Mixpanel} from "../../analytics/Mixpanel";
import useCore from "../../hooks/useCore";
import {useGetActiveChainId} from "../../state/chains/hooks";
import useGetTotalMAHALocked from "../../hooks/state/useGetTotalMAHALocked";
import GlobalData from "./GlobalData";
import styled from "styled-components";

const Home = () => {
  const trackingButtons = (id: string) => {
    Mixpanel.track(`buttonClick:${id}`)
  }

  return (
    <div id="governance-landing">
      <section className="header">
        <div className="main-head-line">
          Introducing MAHAX Governance
        </div>
        <div className="header-explain">
          Become a part of a mission to create financial freedom. The MahaDAO governance portal allows you to take
          control of the DAO and participate in various aspects of the governance.
        </div>
        <GlobalData/>
        <HideOnDesktop>
          <a className="call-to-action max-content" href={"/#/locker"} id={"Hero_Section_OpenGovernance"}
             onClick={() => trackingButtons("Hero_Section_OpenGovernance")}>
            Open Governance
          </a>
        </HideOnDesktop>
      </section>
      <section className="background-1">
      </section>
      {/* <section className="future-of-mahadao" id="#future-of-mahadao">
        <div className="header">
          Be incharge of MahaDao
        </div>
        <div className="cards-row">
          <div className="card">
            <img alt='Voting' className="feature-img" src={voting}/>
            <div className="card-title">
              Vote
            </div>
            <div className="explain">
              With MAHAX, you reserve higher voting powers than MAHA token holders.
            </div>
            <div
              className="call-to-action"
              onClick={() => {
                trackingButtons("Participate_in_Governance")
                window.open('https://vote.mahadao.com/#/ ')
              }}
              id={"Participate_in_Governance"}
            >
              Participate in Governance
            </div>
          </div>
          <div className="card">
            <img alt='Boost staking' className="feature-img" src={boostStakingReward}/>
            <div className="card-title">
              Boost Staking Reward
            </div>
            <div className="explain">
              Every staking/farming pool yields higher rewards for MAHAX holders against MAHA holders.
            </div>
              className="call-to-action"
              onClick={() => {
                trackingButtons("Boost_Reward")
                window.location.href = '/#/minter'
              }}
              id={"Boost_Reward"}
            >
              Boost Reward
            </div>
          </div>
          <div className="card">
            <img alt='Discuss your ideas' className="feature-img" src={discussYourIdeas}/>
            <div className="card-title">
              Discuss your Ideas
            </div>
            <div className="explain">
              Discuss innovative ideas and propose crucial changes to MahaDAO’s ecosystem.
            </div>
            <div
              className="call-to-action"
              onClick={() => {
                trackingButtons("Go_to_Forum")
                window.open('https://discuss.mahadao.com/')
              }}
              id={"Go_to_Forum"}
            >
              Go to Forum
            </div>
          </div>
        </div>
      </section> */}
      {/* <section id={"FAQ"}>
        <div className="header">
          FAQ
        </div>
        {FAQs()}
      </section> */}
      {/* {Footer()} */}
    </div>
  );
}

export default Home;

const HideOnDesktop = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
`
