import { BigNumber, Contract } from "ethers";
import { useCallback } from "react";

import { useAddPopup } from "../../../state/application/hooks";
import { useTransactionAdder } from "../../../state/transactions/hooks";
import formatErrorMessage from "../../../utils/formatErrorMessage";
import useCore from "../../useCore";
import { useGetActiveChainId } from "../../../state/chains/hooks";

const useV3Withdraw = (nftID: BigNumber, gauge: string) => {
  const core = useCore();
  const chainId = useGetActiveChainId();
  const addPopup = useAddPopup();
  const addTransaction = useTransactionAdder();

  return useCallback(
    async (onSuccess: () => void, onFailure: () => void): Promise<void> => {
      const gaugeContract = core._contracts[chainId][gauge];

      try {
        console.log(gaugeContract.address, nftID.toString());
        const response = await gaugeContract.withdraw(nftID);

        addTransaction(response, {
          summary: `Withdraw ${nftID.toString()} from staking`,
        });

        const tx = await response.wait();

        if (tx?.status === 1) onSuccess();
        if (tx?.status !== 1) onFailure();
      } catch (e: any) {
        console.log("useV3Withdraw error", e);
        onFailure();
        addPopup({
          error: {
            message: formatErrorMessage(e?.data?.message || e?.message),
            stack: e.stack,
          },
        });
      }
    },
    [gauge, core._contracts, chainId, nftID, addTransaction, addPopup]
  );
};

export default useV3Withdraw;
