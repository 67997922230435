import React from "react";

import PageHeader from "../../components/PageHeader";
import VotingTableWithFilters from "./components/VotingTableWithFilters";

const GaugeWeightVote = () => {

  return (
    <div className="custom-container">
      <PageHeader
        title={'Vote for a Pool'}
        subTitle={'Use your NFTs to vote on farming pools that will receive MAHA rewards.'}
        showLearnMore={true}
        learnMoreLink="https://docs.mahadao.com/governance/pool-incentives"
        className="m-b-40"
      />
      <VotingTableWithFilters/>
    </div>
  );
}

export default GaugeWeightVote;
