import { useCallback, useEffect, useState } from "react";
import useCore from "../../useCore";
import { useWallet } from "use-wallet";
import { BigNumber } from "ethers";
import { useGetActiveChainId } from "../../../state/chains/hooks";

export interface IuseGetMigratedNFTsData {
  data: {
    owner: string;
    id: string;
    endDate: number;
    amount: BigNumber;
    mahaReward: BigNumber;
    sclpReward: BigNumber;
  };
  migrated: boolean;
  proof: string[];
}

interface IState {
  isLoading: boolean;
  data: IuseGetMigratedNFTsData[];
}

const useGetMigratedNFTsData = () => {
  const [value, setValue] = useState<IState>({
    isLoading: true,
    data: [],
  });

  const core = useCore();
  const chainId = useGetActiveChainId();
  const { account } = useWallet();

  const fetchValues = useCallback(async () => {
    const proof = core._config[chainId].merkleProof;
    const contract = core.getMigrationContract(chainId);

    if (!proof) {
      return setValue({
        isLoading: false,
        data: [],
      });
    }

    const leaves = proof.leaves.filter(
      (d) => d.data.owner.toLowerCase() === account?.toLowerCase()
    );

    const formattedData = await Promise.all(
      leaves.map(async (data) => {
        const migrated = await contract.isTokenIdMigrated(data.data.id);

        return {
          data: {
            amount: BigNumber.from(data.data.amount),
            endDate: data.data.endDate,
            mahaReward: BigNumber.from(data.data.mahaReward),
            sclpReward: BigNumber.from(data.data.sclpReward),
            owner: data.data.owner,
            id: data.data.id,
          },
          migrated,
          proof: data.proof,
        };
      })
    );

    setValue({
      isLoading: false,
      data: formattedData.filter((d) => !d.migrated),
    });
  }, [account, core, chainId]);

  useEffect(() => {
    if (core && account) {
      fetchValues().catch((err) => {
        setValue({ isLoading: false, data: [] });
        console.error(`Failed to fetch migarted nft data: ${err.stack}`);
      });
    }
  }, [core, account, fetchValues]);

  return value;
};

export default useGetMigratedNFTsData;
