import IconLoader from "../../../components/IconLoader";
import {makeStyles,} from "@material-ui/core/styles";
import Button from "../../../components/Button";
import TextWrapper from "../../../components/TextWrapper";
import Loader from "react-spinners/BeatLoader";
import {DataGrid, GridColDef} from "@material-ui/data-grid";
import {IPassingState} from "../../../hooks/state/rewards/useGetRewardsData";
import TextButton from "../../../components/TextButton";
import useClaimBribes from "../../../hooks/callbacks/Rewards/useClaimBribes";
import {RewardTokenAndValue} from "../../../utils/interface";
import {getDisplayBalance} from "../../../utils/formatBalance";
import theme from "../../../theme";

import '../../../customCss/Custom-Mahadao-Data-Table.css';

export function SortedDescendingIcon() {
  return <IconLoader iconName={'ArrowTailUp'} iconType="arrow"/>;
}

export function SortedAscendingIcon() {
  return <IconLoader iconName={'ArrowTailDown'} iconType="arrow"/>;
}

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      cursor: 'pointer',
    },
    '& .super-app.negative': {
      color: '#FA4C69',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      color: '#20C974',
      fontWeight: '600',
    },
    '& .super-app.loss': {
      color: '#FA4C69',
      fontWeight: '600',
    },
    '& .super-app.profit': {
      color: '#20C974',
      fontWeight: '600',
    },
  },
  gridStyle: {
    color: 'white',
  },
});

interface IProps {
  rewardsData: IPassingState;
}

const RewardsTable = (props: IProps) => {
  const classes = useStyles();

  const claimBribesAction = useClaimBribes();
  // const claimRewardsAction = useClaimRewards();

  const columns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Pool',
      flex: 0.3,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div className="single-line-center-start">
            <span>{params.value}</span>
          </div>
        );
      },
    },
    {
      field: 'bribe',
      headerName: 'Earned (Pool Incentives)',
      flex: 0.2,
      sortable: true,
      renderHeader: () => {
        return (
          <div>
            <TextWrapper
              text={'Earned Pool Incentives'}
              align={'center'}
              Fcolor={theme.color.transparent[200]}
              fontSize={12}
              fontWeight={600}
            />
          </div>
        )
      },
      renderCell: (params: any) => {
        return (
          <div className={`p-t-12 p-b-12 ${params.row.bribeRewards.length <= 3 ? 'start-center' : ''}`}>
            {params.row.bribeRewards.length === 0
              ? <TextWrapper text={'-'}/>
              : params.row.bribeRewards.map((info: RewardTokenAndValue, i: number) => {
                return (
                  <div key={`${params.row.id}${i}`} className={'single-line-center-start m-b-2'}>
                    <TextWrapper
                      text={
                        Number(getDisplayBalance(info.earned, parseInt(info.decimals.toString()), 3)).toLocaleString('en-US', {minimumFractionDigits: 2})
                      }
                    />
                    <span className={'tags m-l-4'}>{info.symbol}</span>
                  </div>
                )
              })
            }
          </div>
        )
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.2,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div className={'start-center'}>
            {
              !params.row.bribeRewards.filter((info: RewardTokenAndValue) => info.earned.gt(0)).length
                ? <TextWrapper text={'Claim Pool Incentives'} Fcolor={theme.color.transparent[100]}/>
                : <TextButton
                  text={'Claim Pool Incentives'}
                  onClick={
                    () => {
                      const addresses = params.row.bribeRewards.map((info: RewardTokenAndValue) => info.address);
                      claimBribesAction(
                        params.row.displayName,
                        params.row.bribe,
                        addresses,
                        () => window.location.reload(),
                        () => {
                        },
                      );
                    }
                  }
                  tracking_id={'claim_bribes'}
                  tracking_params={{collateral: `${params.row.displayName}`}}
                />
            }
          </div>
        )
      },
    },
  ];

  const noResultsComponent = () => {
    return (
      <div className="noResultContainer">
        <div className="internal">
          <TextWrapper
            text={'You don\'t have any pool incentive rewards!'}
            fontSize={24}
            fontWeight={'bold'}
            className="m-b-8"
            align="center"
          />
          <TextWrapper
            text={'Vote for a pool to start accumulating pool incentive rewards.'}
            fontSize={18}
            className="m-b-8"
            Fcolor={'#FFFFFF64'}
            align={'center'}
          />
          <div style={{width: '130px', margin: 'auto'}} className="m-t-24">
            <Button
              to={'/gaugeWeightVote'}
              size={'sm'}
              tracking_id={'go_to_pools_vote'}
              tracking_params={{position: 'rewards_table_page'}}
              variant={'transparent'}
            >
              <IconLoader iconName={'Add'} className="m-r-4" width={16}/>
              Vote for a Pool
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const LoadingOverlayComponent = () => {
    return (
      <div className="noResultContainer">
        <div className="internal">
          <Loader color={'#ffffff'} loading={true} size={10} margin={2}/>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root} style={{position: 'relative'}}>
      <DataGrid
        className={classes.gridStyle}
        getRowId={(rows) => rows.id}
        rows={props.rewardsData.value}
        columns={columns}
        pageSize={10}
        hideFooterPagination={false}
        rowHeight={80}
        autoHeight={true}
        disableColumnMenu={true}
        loading={false}
        components={{
          LoadingOverlay: LoadingOverlayComponent,
          NoRowsOverlay: noResultsComponent,
          ColumnSortedDescendingIcon: SortedDescendingIcon,
          ColumnSortedAscendingIcon: SortedAscendingIcon,
        }}
        disableColumnSelector={true}
        disableSelectionOnClick={true}
      />
    </div>
  )
}

export default RewardsTable;
