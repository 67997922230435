import React, { useEffect, useState } from "react";
import heroImg from '../../assets/images/ReferAndEarn.png';
import TextWrapper from "../../components/TextWrapper";
import theme from "../../theme";
import copyText from 'copy-to-clipboard';
import { useWallet } from "use-wallet";
import styled from "styled-components";
import Tele from '../../assets/icons/socialMedia/Telegram-og.png';
import Twit from '../../assets/icons/socialMedia/Twitter-og.png';
import Whats from '../../assets/icons/socialMedia/Whatsapp-og.png';
import TextButton from "../../components/TextButton";

const msg = `Hi! Inviting you to join MahaDAO's Ambassadors Program.
Stake MAHA and shape the future of a decentralised economy.
oin us to become a part of the movement:`;

function telegramForwardButton(url: string, text: string = '') {
  return 'https://t.me/share/url?url=' + url + '&text=' + encodeURI(text);
}

const Referral = () => {
  const { account } = useWallet()

  const [isCopied, setIsCopied] = useState<boolean>(false);

  useEffect(() => {
    if (isCopied) {
      const hide = setTimeout(() => {
        setIsCopied(false)
      }, 500)

      return () => {
        clearTimeout(hide)
      }
    }
  }, [isCopied, setIsCopied]);

  let refLink = `https://${window.location.host}/#/referral/onboarding?referredBy=${account ? account.toString() : ''}`;

  return (
    <div className={'custom-container mo-custom-container'}>
      <div className={'single-line-center-center m-b-40'}>
        <img src={heroImg} alt={'Hero Image'} />
      </div>
      <TextWrapper text={'Refer & Earn MAHA'} fontSize={32} fontWeight={600} fontFamily={'Syne'} align={'center'}
        className={'m-b-4'} />
      <TextWrapper text={'Refer your friends to stake with MahaDAO and earn rewards from their referrals.'}
        fontSize={16} fontWeight={600} fontFamily={'Syne'} align={'center'}
        Fcolor={theme.color.transparent[100]} className={'m-b-10'} />
      <TextWrapper
        text={'For any referral that stakes their MAHA using this link for more than 1 year, you will receive 10% of their MAHAX as rewards.'}
        fontSize={16} fontWeight={600} fontFamily={'Syne'} align={'center'}
        Fcolor={theme.color.transparent[100]} className={'m-b-10'} />
      <TextWrapper text={'Rewards are instantaneous and are in MAHA.'}
        fontSize={16} fontWeight={600} fontFamily={'Syne'} align={'center'}
        Fcolor={theme.color.transparent[100]} className={'m-b-40'} />
      <div className={'single-line-center-center m-b-12'}>
        <Link>
          <TextWrapper
            text={account
              ? refLink
              : 'Connect Wallet to get your referral link'
            }
            align={"center"}
            className={'m-r-8'}
          />
          <div>
            <TextButton
              text={`${isCopied ? 'Copied' : 'Copy link'}`}
              tracking_id={'copy_referral_id'}
              fontWeight={600}
              tracking_params={{
                other: { walletAddress: account }
              }}
              onClick={() => {
                if (account) {
                  const didCopy = copyText(refLink);
                  setIsCopied(didCopy);
                }
              }}
            />
          </div>
        </Link>
      </div>
      <div className={'single-line-center-center'}>
        <SocialMedia>
          <TextWrapper text={'Share on:'} className={'m-b-4 m-r-2'} fontWeight={600} />
          <Icons>
            <a href={telegramForwardButton(refLink)}>
              <img src={Tele} alt={'Tele'} />
            </a>
          </Icons>
          <Icons>
            <a href={`https://twitter.com/intent/tweet?text=${msg + '&url=' + refLink}`}>
              <img src={Twit} alt={'Twit'} />
            </a>
          </Icons>
        </SocialMedia>
      </div>
      s
    </div>
  )
}

export default Referral;

const Link = styled.div`
  border-radius: 6px;
  padding: 8px 24px;
  background: #3c3c3c;
  width: max-content;
  max-width: calc(100vw - 32px);
  overflow-wrap: break-word;
  display: flex;
  justify-content: center;
`

const SocialMedia = styled.div`
  background: #FFFFFF20;
  padding: 10px 12px 8px 24px;
  margin-bottom: 12px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icons = styled.div`
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin: 0 12px;

  &:hover {
    transform: scale(0.9);
  }

`
