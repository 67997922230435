import {useWallet} from "use-wallet";
import {useCallback, useEffect, useState} from "react";
import useCore from "../../useCore";
import {BigNumber} from "ethers";
import useGetNumberOfNFTs from "../Vesting/useGetNumberOfNFTs"
import {useGetActiveChainId} from "../../../state/chains/hooks";

export interface IPassingState {
  isLoading: boolean;
  value: BigNumber[];
}

export const Initial_Loading = {
  isLoading: true,
  value: [],
}

export const Initial_Non_Loading = {
  isLoading: false,
  value: [],
}

const useGetAllStakedNFTs = () => {
  const [value, setValue] = useState<IPassingState>(
    Initial_Loading
  );

  const core = useCore();
  const {account} = useWallet();
  const chainId = useGetActiveChainId();
  const contract = core.getVestingContract(chainId);
  const stakingContract = core.getStakingContract(chainId);
  const noOfNFTs = useGetNumberOfNFTs();

  const fetchData = useCallback(async () => {
    if (!account) {
      setValue(Initial_Non_Loading);
      return;
    }

    if (noOfNFTs.isLoading) {
      setValue(Initial_Loading)
    }

    const arr = Array.from({length: parseInt(noOfNFTs.value.toString())}, (v, i) => i)

    const nftIds = await Promise.all(
      arr.map(async (idx) => {
        const id = await contract.tokenOfOwnerByIndex(account, idx)
        const hasStaked: BigNumber = await stakingContract.stakedBalancesNFT(id);
        return hasStaked.eq(0) ? null : id;
      })
    )

    setValue({
      isLoading: false,
      value: nftIds.filter(data => data !== null),
    })
  }, [account, contract, noOfNFTs.isLoading, noOfNFTs.value, stakingContract]);

  useEffect(() => {
    if (core) {
      setValue(Initial_Loading);
      fetchData().catch((err) => {
        setValue(Initial_Non_Loading);
        console.error(`Failed to fetch all NFTs: ${err.stack}`);
      });
    }
  }, [setValue, core, account, fetchData]);

  return value;
};

export default useGetAllStakedNFTs;
