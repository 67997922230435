import { BigNumber, Contract } from "ethers";
import { useCallback } from "react";

import ABIS from "../../../protocol/deployments/abi";
import { useAddPopup } from "../../../state/application/hooks";
import { useTransactionAdder } from "../../../state/transactions/hooks";

import useCore from "../../useCore";
import formatErrorMessage from "../../../utils/formatErrorMessage";

const useDepositBribe = (
  displayName: string,
  tokenAddress: string,
  bribesAddress: string,
  amount: BigNumber
) => {
  const core = useCore();
  const addPopup = useAddPopup();
  const addTransaction = useTransactionAdder();

  return useCallback(
    async (onSuccess: () => void, onFailure: () => void): Promise<void> => {
      try {
        const contract = new Contract(
          bribesAddress,
          ABIS.BaseV2Bribes,
          core._signer
        );
        const response = await contract.notifyRewardAmount(
          tokenAddress,
          amount
        );

        addTransaction(response, {
          summary: `Deposit pool incentives for ${displayName.toString()} pools voting.`,
        });

        const tx = await response.wait();

        if (tx?.status === 1) onSuccess();
        if (tx?.status !== 1) onFailure();
      } catch (e: any) {
        onFailure();
        addPopup({
          error: {
            message: formatErrorMessage(e?.data?.message || e?.message),
            stack: e?.stack,
          },
        });
      }
    },
    [
      core,
      addPopup,
      addTransaction,
      displayName,
      tokenAddress,
      bribesAddress,
      amount,
    ]
  );
};

export default useDepositBribe;
