import {useWallet} from "use-wallet";
import {useCallback, useEffect, useState} from "react";
import {LOADING_DEFAULT_BASIC_STATE, NON_LOADING_DEFAULT_BASIC_STATE,} from "../../../utils/constants";
import {BasicState} from "../../../utils/interface";
import useCore from "../../useCore";
import {useGetActiveChainId} from "../../../state/chains/hooks";

const useGetTotalMAHAX = () => {
  const [value, setValue] = useState<BasicState>(
    LOADING_DEFAULT_BASIC_STATE
  );

  const core = useCore();
  const {account} = useWallet();
  const chainId = useGetActiveChainId();
  const contract = core.getStakingContract(chainId);

  const fetchData = useCallback(async () => {
    if (!account) {
      setValue(NON_LOADING_DEFAULT_BASIC_STATE);
      return;
    }

    const value = await contract.getVotes(account);

    setValue({
      isLoading: false,
      value: value
    })

  }, [account, contract]);

  useEffect(() => {
    if (core) {
      fetchData().catch((err) => {
        setValue(NON_LOADING_DEFAULT_BASIC_STATE);
        console.error(`Failed to fetch total mahax: ${err.stack}`);
      });
    }
  }, [setValue, core, account, fetchData]);

  return value;
};

export default useGetTotalMAHAX;
