import {useWallet} from "use-wallet";
import {useCallback, useEffect, useState} from "react";
import useCore from "../../useCore";
import {BigNumber, Contract} from "ethers";
import {IGaugeStaticData, RewardTokenAndValue,} from "../../../utils/interface";
import {getDisplayBalance} from "../../../utils/formatBalance";
import ABIS from "../../../protocol/deployments/abi";
import {useGetActiveChainId} from "../../../state/chains/hooks";

export interface IStakingDynamicData extends IGaugeStaticData {
  gaugeRewards: RewardTokenAndValue[];
  // positionsInfo: { [token: string]: string };
  stakingAmount: BigNumber;
  // depositToken1: string;
  // depositToken2: string;
}


export interface IPassingState {
  isLoading: boolean;
  value: IStakingDynamicData | null;
}

export const Initial_Loading = {
  isLoading: true,
  value: null,
};

export const Initial_Non_Loading = {
  isLoading: false,
  value: null,
};

const useGetFarmingData = (poolData: IGaugeStaticData) => {
  const [value, setValue] = useState<IPassingState>(Initial_Loading);

  const core = useCore();
  const {account} = useWallet();
  const chainId = useGetActiveChainId();

  const fetchData = useCallback(async () => {
    if (!account) {
      setValue(Initial_Non_Loading);
      return;
    }

    const gaugeContract = core._contracts[chainId][poolData.gauge];
    const lpTokenBalance = await gaugeContract.balanceOf(account);

    const [gaugesRewardLength] = await Promise.all([
      gaugeContract.rewardsListLength(),
    ]);

    const gaugeRewardIndexArr = Array.from(
      {length: parseInt(gaugesRewardLength.toString())},
      (v, i) => i
    );

    const gaugeRewards = await Promise.all(
      gaugeRewardIndexArr.map(async (index: any) => {
        const address = await gaugeContract.rewards(index);
        const rewardERC20 = new Contract(address, ABIS.IERC20, core.provider);
        const symbol = await rewardERC20.symbol();
        const decimals: number = await rewardERC20.decimals();
        const earned = await gaugeContract.earned(address, account);

        return {
          address,
          symbol,
          decimals,
          earned,
        };
      })
    );

    /*const positionInfo: { [token: string]: string } = {};
    const contract = core._contracts[chainId][poolData.pool];
    const [balance, totalSupply, [reserve0, reserve1], token0, token1] =
      await Promise.all([
        contract.balanceOf(account),
        contract.totalSupply(),
        contract.getReserves(),
        contract.token0(),
        contract.token1(),
      ]);

    const token0ERC20 = new Contract(token0, ABIS.IERC20, core.provider);
    const token1ERC20 = new Contract(token1, ABIS.IERC20, core.provider);
    const [token0Symbol, token1Symbol, token0Decimals, token1Decimals] =
      await Promise.all([
        token0ERC20.symbol(),
        token1ERC20.symbol(),
        token0ERC20.decimals(),
        token1ERC20.decimals(),
      ]);

    if (totalSupply.eq(0)) {
      positionInfo[token0Symbol.toString()] = "0.00";
      positionInfo[token1Symbol.toString()] = "0.00";
    } else {
      positionInfo[token0Symbol.toString()] = Number(
        getDisplayBalance(
          balance.mul(reserve0).div(totalSupply),
          token0Decimals.decimals,
          8
        )
      ).toLocaleString("en-US", {minimumFractionDigits: 2});

      positionInfo[token1Symbol.toString()] = Number(
        getDisplayBalance(
          balance.mul(reserve1).div(totalSupply),
          token1Decimals.decimals,
          8
        )
      ).toLocaleString("en-US", {minimumFractionDigits: 2});
    }*/
    const modifiedGauge: IStakingDynamicData = {
      ...poolData,
      gaugeRewards,
      // positionsInfo: positionInfo,
      stakingAmount: lpTokenBalance,
      // depositToken1: token0,
      // depositToken2: token1,
    };

    setValue({
      isLoading: false,
      value: modifiedGauge,
    });
  }, [account, core._contracts, core.provider, chainId, poolData]);

  useEffect(() => {
    if (core) {
      setValue(Initial_Loading);
      fetchData().catch((err) => {
        setValue(Initial_Non_Loading);
        console.error(`Failed to fetch staking data: ${err.stack}`);
      });
    }
  }, [setValue, core, account, fetchData]);

  return value;
};

export default useGetFarmingData;
